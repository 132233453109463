export enum BooleanStatus {
  TRUE = '1',
  FALSE = '0',
}

export enum UserTokenType {
  USER = 1,
}

export enum PhoneStatus {
  NOT_VERIFIED = 0,
  VERIFIED = 1,
}

export enum OrderServiceStatus {
  CONFIRM = 'Confirm',
  PENDING = 'Pending',
  COMPLETE = 'Complete',
  DECLINED = 'Decline',
  CANCELED = 'Cancel',
}

export enum StripePayloadType {
  USE_STRIPE_SDK = 'use_stripe_sdk',
  REDIRECT_TO_URL = 'redirect_to_url',
}

export enum NextActionType {
  ORDER_SERVICE_PAYMENT_AUTHENTICATION = 'order_service_payment_authentication',
}

export enum DeviceType {
  WEB_APP = 'web_app',
  IOS_APP = 'ios_app',
  ANDROID_APP = 'android_app',
}

export enum StripePayloadEnum {
  DRAFT_ORDER = 'draft_order',
  NEXT_ACTION_NEEDED = 'next_action_needed',
}

export enum ErrorMessage {
  INVALID_SELECTED_VEHICLE = 'No Vehicle is selected',
  INVALID_SELECTED_LOCATION = 'No Location is selected',
  INVALID_SELECTED_SERVICE = 'No Service is selected',
  INVALID_SERVICE_DATE_SELECTION = 'Invalid service date',
  CHECKOUT_INVALID_PAYMENT_INFO = 'Invalid Payment Info',
  APPLE_PAY_NOT_AVAILABLE = 'Apple Pay is not available',
  APPLE_PAY_FAILED_TO_CREATE_ORDER = 'Unable to create an order with Apple Pay',
  GOOGLE_PAY_NOT_AVAILABLE = 'Google Pay is not available',
  GOOGLE_PAY_FAILED_TO_CREATE_ORDER = 'Unable to create an order with Google Pay',

  STRIPE_NOT_TOUCHED_CARD_ELEMENT = 'Card information is incomplete',
  CARD_NOT_ADDED_OR_SELECTED = 'Please select or add a valid credit card',
  PAYMENT_REDIRECT_MISSING_PAYMENT_INTENT = 'Redirect url is missing payment intent',
  PAYMENT_REDIRECT_MISSING_PAYMENT_INTENT_CLIENT_SECRET = 'Redirect url is missing payment intent client secret',
}

export enum NotificationType {
  ORDER_REVIEW = 'order_review',
  REQUEST_VIN_NUMBER = 'request_vin_number',
}

export enum OrderPaymentOption {
  REGULAR_PAYMENT_BY_CARD = 'regular_payment_by_card',
  PAY_WITH_CASH_ON_SERVICE = 'pay_with_cash_on_service',
}

export enum AppCashPaymentOptions {
  USE_DEFAULT = '0',
  USE_CASH_PAYMENT = '1',
  DO_NOT_USE_CASH_PAYMENT = '-1',
}

export enum FieldsType {
  TERMS_AND_CONDITIONS = 'terms_and_conditions',
  PRIVACY_POLICY = 'privacy_policy',
  LANDING_PAGE_MOBILE_CAR_DETAILING = 'landing_page_mobile_car_detailing',
  LANDING_PAGE_CAR_BREAK_CONTENT = 'landing_page_car_break_content',
  HOME_SCREEN = 'home_screen',
  CHECKOUT_PAYMENT_INFO = 'checkout_payment_note',
  ACCOUNT_DELETE_TEXTS = 'account_deletion_request',
  APP_VERSION = 'app_version', // deprecated
  APP_VERSION_DETAILS = 'app_version_details',
  MOBILE_STORE_BANNER_PLAY_STORE = 'mobile_store_banner_play_store',
  MOBILE_STORE_BANNER_APP_STORE = 'mobile_store_banner_app_store',
  APP_UPDATE_BANNER_PLAY_STORE = 'app_update_banner_play_store',
  APP_UPDATE_BANNER_APP_STORE = 'app_update_banner_app_store',
  APP_STORE_URL = 'app_store_url',
  PLAY_STORE_URL = 'play_store_url',
  FEATURE_FLAG_NATIVE_APPLE_PAY = 'feature_flag_native_apple_pay',
  FEATURE_FLAG_NATIVE_GOOGLE_PAY = 'feature_flag_native_google_pay',
  FEATURE_FLAG_NOTIFICATIONS = 'feature_flag_notifications',
  FEATURE_FLAG_GOOGLE_ANALYTICS = 'feature_flag_google_analytics',
  FEATURE_FLAG_ALLOW_APP_CASH_PAYMENT = 'allow_app_cash_payment',
  FEATURE_FLAG_SERVICE_ADDON = 'feature_flag_service_addon',
  FEATURE_FLAG_PAY_WITH_CARD = 'feature_flag_pay_with_card_switch',
}

export enum LandingPageType {
  MOBILE_CAR_DETAILING = FieldsType.LANDING_PAGE_MOBILE_CAR_DETAILING,
  CAR_BREAK_DETAILING = FieldsType.LANDING_PAGE_CAR_BREAK_CONTENT,
}

export enum LandingPageActions {
  LANDING_PAGE_HEADER_PRESENT_MODAL = 'landing-page-header-present-modal',
  HEADER_WATCH_VIDEO_OPEN = 'header-watch-video-open',
}

export enum AllRotorsCustomEvent {
  OPEN_TERMS_AND_CONDITION = 'OPEN_TERMS_AND_CONDITION',
  OPEN_PRIVACY_POLICY = 'OPEN_PRIVACY_POLICY',
  SIGNUP_CLOSE_MODAL = 'SIGNUP_CLOSE_MODAL',
  SIGNUP_NAVIGATE_HOME = 'SIGNUP_NAVIGATE_HOME',
  COMPONENT_LOADED = 'COMPONENT_LOADED',
}

export enum ServiceAddonActive {
  IS_ACTIVE = '1',
  NOT_ACTIVE = '0',
}

export enum ServiceAddonUseDefaultPricing {
  DEFAULT_PRICING = '1',
  NOT_DEFAULT_PRICING = '0',
}
