import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import camelcaseKeys from 'camelcase-keys';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { BaseResponse, ServiceAddonActive, ServiceAddonUseDefaultPricing } from '../../shared';

export interface VehicleSubServices {
  price: string;
  priceTax: number;
  priceTotal: string;
  servicesId: number;
  title: string;
  description?: string;
  extraChargePerKm?: number;
  extraCharges?: number;
  extraDistance?: number;
  subTotal?: number;
  partsTypes?: ServicePartType[];
  stripePriceTotal: number;
  isServiceAddonAvailable?: boolean;
  serviceAddons?: SubServiceAddon[];
}

export interface SubServiceAddon {
  servicesAddonsId: string;
  servicesAddonsActive: string;
  servicesAddonsPrice: string;
  servicesAddonsTitle: string;
  useDefaultPrice: string;
}

export interface ServicePartType {
  code: string;
  img: string;
  name: string;
  price: string;
  priceTax: number;
  priceTotal: string;
  servicePrice?: string;
  subTotal?: string;
  extraChargePerKm?: number;
  extraCharges?: number;
  extraDistance?: number;
  stripePriceTotal: number;
  serviceAndPartsPrice: string;
}

export interface VehicleServices {
  servicesId: number;
  title: string;
  subServices: VehicleSubServices[];
}

interface GetVehicleService {
  records_filtered: number;
  data: {
    services_id: number;
    title: string;
    sub_services: {
      price: string;
      price_tax: number;
      price_total: string;
      services_id: number;
      title: string;
      stripe_price_total: number;
      parts_types?: {
        code: string;
        img: string;
        name: string;
        price: string;
        price_tax: number;
        price_total: string;
        service_and_parts_price: string;
        stripe_price_total: number;
      }[];
      is_service_addon_available?: boolean;
      service_addons?: {
        services_addons_id: string;
        services_addons_active: string;
        use_default_price: string;
        services_addons_title: string;
        services_addons_price: string;
      }[];
    }[];
  }[];
}

interface GetCheckoutInfo extends BaseResponse {
  dates_list: string[];
  checkout_info: string;
  checkout_info_pay_wit_cash: string;
  service: {
    service_details: {
      discount_value?: string;
      service_title: string;
      parent_service_title: string;
      extra_charge_per_km?: string;
      extra_distance?: string;
      extra_charges?: string;
      service_cost: string;
      service_markup: string;
      service_cost_with_markup: string;
      primary_service_cost_total: string;
      service_markup_percentage: string;
      parts_cost?: string;
      parts_and_service_cost?: string;
      part_type?: {
        code: string;
        img: string;
        name: string;
        price: string;
      };
      service_total: string;
      service_total_no_discounts: string;
      tax_amount: string;
      is_free: boolean;
      service_total_with_tax: string;
      service_addon_total: string;
      services_addons: {
        services_addons_id: string;
        services_addons_active: ServiceAddonActive;
        use_default_price: ServiceAddonUseDefaultPricing;
        services_addons_title: string;
        service_addon_markup: string;
        services_addons_price_with_no_markup: string;
        services_addons_price: string;
      }[];
    };
  };
}

@Injectable({
  providedIn: 'root',
})
export class ScheduleServicesService {
  constructor(private http: HttpClient) {}

  getSelectedVehicleServices(userId: number, userVehicleId: string, userLocationId: string) {
    return this.http
      .get<GetVehicleService>(
        `${environment.api_url}/users/${userId}/get-services?users_vehicles_id=${userVehicleId}&user_location_id=${userLocationId}`
      )
      .pipe(map((res) => camelcaseKeys(res, { deep: true })));
  }

  getCheckoutInfo(
    usersVehicleId: string,
    usersLocationId: string,
    serviceId: string,
    partsCode: string | undefined,
    currentTime: string,
    timezone: string,
    couponCode: string | undefined,
    servicesAddonsIds: string[]
  ) {
    let queryParam = '';
    queryParam += `users_vehicles_id=${usersVehicleId}`;
    queryParam += `&users_locations_id=${usersLocationId}`;
    queryParam += `&services_id=${serviceId}`;
    queryParam += `&users_current_time=${currentTime}`;
    queryParam += `&users_timezone=${timezone}`;
    queryParam += `&coupon_code=${couponCode ?? ''}`;
    if (partsCode) {
      queryParam += `&parts_code=${partsCode}`;
    }
    for (const serviceAddonId of servicesAddonsIds) {
      queryParam += `&services_addons_id[]=${serviceAddonId}`;
    }

    return this.http
      .get<GetCheckoutInfo>(`${environment.api_url}/orders-services/checkout-info?${queryParam}`)
      .pipe(map((res) => camelcaseKeys(res, { deep: true })));
  }
}
