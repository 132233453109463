<ion-header class="ion-no-border container">
  <ion-toolbar color="secondary" class="header-toolbar">
    <div>
      <ion-grid>
        <ion-row class="ion-justify-content-center">
          <ion-col size-md="12" size-xl="9" class="ion-hide-sm-down">
            <ion-title class="header">
              <h1>Welcome To AllRotors.com APP</h1>
              <p>You can browse on the web or download the app</p>
            </ion-title>
            <span class="description" *ngIf="appFeaturesState$ | async as appFeaturesState">
              <ion-button target="_" [href]="appFeaturesState.appStoreUrl" color="light" fill="outline">iOS</ion-button>
              <ion-button target="_" [href]="appFeaturesState.playStoreUrl" color="light" fill="outline">Android</ion-button>
            </span>
          </ion-col>
          <ion-col size-md="12" size-xl="3" class="ion-align-self-center" mode="md">
            <div class="logo">
              <img src="../../../assets/images/logo.png" />
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </ion-toolbar>
</ion-header>
