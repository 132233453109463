import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../portal/auth.service';
import { AppFeaturesState } from '../portal/app-feature.state';
import { Store } from '@ngxs/store';
import { AuthState } from '../portal/auth.state';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private store: Store, private injector: Injector) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const deviceInfo = this.store.selectSnapshot(AppFeaturesState.state)?.deviceInfo;
    const authState = this.store.selectSnapshot(AuthState.state);
    const token = authState.token || this.authService.getToken();
    const header: any = {};

    if (token) {
      header.Token = token;
    }
    if (deviceInfo && !authState.adminMockingUser) {
      header['Device-Info'] = encodeURI(
        JSON.stringify({
          name: deviceInfo.name,
          model: deviceInfo.model,
          platform: deviceInfo.platform,
          operating_system: deviceInfo.operatingSystem,
          os_version: deviceInfo.osVersion,
          manufacturer: deviceInfo.manufacturer,
          is_virtual: deviceInfo.isVirtual,
          mem_used: deviceInfo.memUsed,
          disk_free: deviceInfo.realDiskFree,
          disk_total: deviceInfo.realDiskTotal,
          web_view_version: deviceInfo.webViewVersion,
          app_build: deviceInfo.build,
          app_id: deviceInfo.id,
          app_version: deviceInfo.version,
        })
      );
    }

    request = request.clone({
      setHeaders: header,
    });

    return next.handle(request);
  }
}
