import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { ResetState } from '../../../shared';

export interface VehicleDetailsComponentOptions {
  backAction?: unknown;
  pageTitle?: string;
  loadVehicles?: boolean;
}

interface VehicleViewStateModel {
  vehicleDetailsComponentOptions: VehicleDetailsComponentOptions;
}

export class VehicleViewUpdateVehicleDetailsOptions {
  static readonly type = '@vehicleViewState.updateVehicleDetailsOptions';
  constructor(public option: VehicleDetailsComponentOptions) {}
}

@State<VehicleViewStateModel>({
  name: 'VehicleViewState',
  defaults: {
    vehicleDetailsComponentOptions: {},
  },
})
@Injectable()
export class VehicleViewState {
  @Selector()
  static vehicleDetailsComponentOptions(state: VehicleViewStateModel) {
    return state.vehicleDetailsComponentOptions;
  }

  @Action(VehicleViewUpdateVehicleDetailsOptions)
  updateVehicleDetailsComponentOptions(ctx: StateContext<VehicleViewStateModel>, action: VehicleViewUpdateVehicleDetailsOptions) {
    ctx.setState(
      patch({
        vehicleDetailsComponentOptions: patch(action.option),
      })
    );
  }

  @Action(ResetState)
  resetState(ctx: StateContext<VehicleViewStateModel>, action: ResetState) {
    ctx.setState({
      vehicleDetailsComponentOptions: {},
    });
  }
}
