import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { FmErrorHandlerService } from './fm-error-handler.service';

interface HttpResponse {
  type: number;
  body?: { status: number; message?: string };
  headers?: object;
  ok?: boolean;
  status?: number;
  url?: string;
}
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private errorHandlerService: FmErrorHandlerService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((response: HttpResponse) => {
        if (req.url.endsWith('/log/error')) {
          return;
        }
        if (response.body) {
          const { status, message } = response.body;
          if (status !== 1) {
            throw new HttpErrorResponse({
              error: req,
              headers: req.headers,
              status: status,
              url: req.url,
              statusText: message,
            });
          }
        }
      }),
      // TODO: add retry with delay when we get 500
      catchError((error) => {
        if (req.url.endsWith('/log/error')) {
          console.error('error logging');
          console.error(error);
          return EMPTY;
        }
        this.errorHandlerService.handleHttpInterceptorError(error);
        return throwError(() => error);
      })
    );
  }
}
