import { Component, OnInit, ChangeDetectionStrategy, Input, HostListener, HostBinding } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { IonInput, ModalController, NavController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { tap } from 'rxjs';
import { LoadingState } from '../../../../core/loading.state';
import { AppFeaturesState } from '../../../../portal/app-feature.state';
import { AuthRequestPhoneVerification, AuthState, AuthUpdateUser, AuthVerifyPhone } from '../../../../portal/auth.state';
import { PhoneStatus } from '../../../../shared';
import { GoogleAnalyticsService, LogEventType } from '../../../../core/google-analytics.service';

export enum VerificationView {
  VERIFY_MOBILE = 'verifyMobile',
  UPDATE_MOBILE = 'updateMobile',
  ADD_MOBILE = 'addMobile',
}

@Component({
  selector: 'app-phone-verification',
  templateUrl: './phone-verification.component.html',
  styleUrls: ['./phone-verification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneVerificationComponent implements OnInit {
  verificationForm = this.buildVerificationCodeFormGroup();
  mobileNumberChangeForm = this.buildMobileNumberChangeGroup();
  @Input() selectedView: VerificationView;
  @Input() moveToUrl?: string;

  @HostBinding('class.is-ios') isIos = this.appFeaturesState.isIos;

  verificationView = VerificationView;

  constructor(
    public appFeaturesState: AppFeaturesState,
    private modalController: ModalController,
    private navController: NavController,
    public loadingState: LoadingState,
    private fb: UntypedFormBuilder,
    public authState: AuthState,
    private store: Store,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}

  async handleIonScrollStart() {
    await this.googleAnalyticsService.logEvent({ name: LogEventType.SCROLL });
  }

  ngOnInit(): void {
    const phoneNumber = this.authState.snapshot.phone;
    if (!phoneNumber) {
      this.toAddPhoneStep();
    }
  }

  close() {
    this.modalController.dismiss();
  }

  buildVerificationCodeFormGroup() {
    const form = this.fb.group({
      input1: [undefined, { validators: [Validators.required] }],
    });
    return form;
  }

  private buildMobileNumberChangeGroup() {
    const form = this.fb.group({
      phone: [undefined, { validators: [Validators.required, Validators.minLength(14), Validators.maxLength(14)] }],
    });

    return form;
  }

  toVerificationStep() {
    this.selectedView = VerificationView.VERIFY_MOBILE;
  }

  toUpdatePhoneStep() {
    this.selectedView = VerificationView.UPDATE_MOBILE;
  }

  toAddPhoneStep() {
    this.selectedView = VerificationView.ADD_MOBILE;
  }

  resendCode() {
    this.store.dispatch(new AuthRequestPhoneVerification());
  }

  verifyPhone() {
    if (this.verificationForm.invalid) {
      return;
    }
    const formValue = this.verificationForm.value;
    const code = Object.values(formValue).join('');
    this.store.dispatch(new AuthVerifyPhone(parseFloat(code) || 0)).subscribe(async () => {
      const phoneStatus = this.authState.snapshot.phoneStatus;
      if (phoneStatus !== PhoneStatus.VERIFIED) {
        return;
      }
      if (this.moveToUrl) {
        await this.navController.navigateForward(this.moveToUrl);
      }
      await this.close();
    });
  }

  updatePhone() {
    if (this.mobileNumberChangeForm.invalid) {
      return;
    }
    const formValue = this.mobileNumberChangeForm.value;
    this.store
      .dispatch(
        new AuthUpdateUser({
          phone: formValue.phone?.toString()?.replace(/[^0-9]*/g, ''),
          fieldsToUpdate: ['phone'],
        })
      )
      .pipe(tap(() => this.toVerificationStep()))
      .subscribe();
  }

  public keyDownFunction(event: KeyboardEvent) {
    if (event.key !== 'Enter') {
      return;
    }
    if (this.selectedView === VerificationView.ADD_MOBILE || this.selectedView === VerificationView.UPDATE_MOBILE) {
      this.updatePhone();
      return;
    }
    if (this.selectedView === VerificationView.VERIFY_MOBILE) {
      this.verifyPhone();
      return;
    }
  }
}
