import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneVerificationComponent } from './phone-verification.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { InputMaskModule } from '../../input-mask/input-mask.module';
import { ErrorViewModule } from '../../../../shared-standalone/error-view/error-view.module';

@NgModule({
  declarations: [PhoneVerificationComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, IonicModule, InputMaskModule, ErrorViewModule],
})
export class PhoneVerificationModule {}
