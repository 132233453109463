import { Injectable } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { AlertController, ModalController } from '@ionic/angular';
import { PaymentRequestOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { ScheduleServiceStripePayload, StripePayloadType } from '../shared';
import { IframeModalComponent } from '../shared-components/iframe-modal/iframe-modal.component';

@Injectable({
  providedIn: 'root',
})
export class StripePaymentService {
  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  constructor(
    private modalController: ModalController,
    private alertController: AlertController
  ) {}

  async handleStripeAuthForNativeApp(stripePayloads: ScheduleServiceStripePayload[]) {
    for (const payload of stripePayloads) {
      if (payload && payload.stripe.type === StripePayloadType.USE_STRIPE_SDK && payload.stripe.useStripeSdk?.source) {
        const alert = await this.alertController.create({
          header: 'Authorization Required',
          message: payload.message,
          cssClass: ['frame-stack-100000'],
          backdropDismiss: false,
          buttons: [
            {
              role: 'ok',
              text: 'Ok',
            },
          ],
        });
        await alert.present();
        await alert.onDidDismiss();

        await Browser.open({
          url: payload.stripe.useStripeSdk?.stripeJs,
        });
        const promise = new Promise<void>((resolve) => {
          Browser.addListener('browserFinished', async () => {
            await Browser.removeAllListeners();
            setTimeout(() => resolve(), 700);
          });
        });
        await promise;
      } else if (payload && payload.stripe.type === StripePayloadType.REDIRECT_TO_URL && payload.stripe.redirectToUrl?.url) {
        const alert = await this.alertController.create({
          header: 'Authorization Required',
          message: payload.message,
          cssClass: ['frame-stack-100000'],
          backdropDismiss: false,
          buttons: [
            {
              role: 'ok',
              text: 'Ok',
            },
          ],
        });
        await alert.present();
        await alert.onDidDismiss();

        await Browser.open({
          url: payload.stripe.redirectToUrl.url,
        });
        const promise = new Promise<void>((resolve) => {
          Browser.addListener('browserFinished', async () => {
            await Browser.removeAllListeners();
            setTimeout(() => resolve(), 700);
          });
        });
        await promise;
      }
    }
  }

  async handleStripeAuthForWebApp(stripePayloads: ScheduleServiceStripePayload[]) {
    for (const payload of stripePayloads) {
      if (payload && payload.stripe.type === StripePayloadType.USE_STRIPE_SDK && payload.stripe.useStripeSdk?.source) {
        const modal = await this.modalController.create({
          component: IframeModalComponent,
          cssClass: 'frame-stack-100000',
          componentProps: {
            url: payload.stripe.useStripeSdk.stripeJs,
            title: payload.title,
            message: payload.message,
          },
        });
        await modal.present();
        await modal.onDidDismiss();
      } else if (payload && payload.stripe.type === StripePayloadType.REDIRECT_TO_URL && payload.stripe.redirectToUrl?.url) {
        const modal = await this.modalController.create({
          component: IframeModalComponent,
          cssClass: 'frame-stack-100000',
          componentProps: {
            url: payload.stripe.redirectToUrl.url,
            title: payload.title,
            message: payload.message,
          },
        });
        await modal.present();
        await modal.onDidDismiss();
      }
    }
  }

  getPaymentRequestOptions(stripeTotal: number): PaymentRequestOptions {
    let options = {
      country: 'CA',
      currency: 'cad',
      total: {
        label: 'AllRotors',
        amount: stripeTotal,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    };
    return options;
  }
}
