import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgModel, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-error-view',
  templateUrl: './error-view.component.html',
  styleUrls: ['./error-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorViewComponent {
  @Input() errors: ValidationErrors;
  @Input() name: string;
  @Input() patternErrorMessage = 'The value entered is invalid';
  @Input() minLengthErrorMessage: string;

  constructor() {}
}
