import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { finalize, tap } from 'rxjs';
import { SetLoading } from '../core/loading.state';
import { ToastService } from '../core/toast.service';
import { OrderDetailsState } from '../inside/orders/order-details/order-detail.state';
import { OrderDetailsService } from '../inside/orders/order-details/order-details.service';
import {
  NextActionType,
  OrderServicePaymentNextAction,
  OrderServiceStatus,
  ResetState,
  ScheduleServiceStripePayload,
  StripeNextAction,
  StripePayloadEnum,
} from '../shared';
import { AppFeaturesState } from './app-feature.state';
import { StripePaymentService } from './stripe-payment.service';
import { UserService } from './user.service';

interface OrderServiceValidation {
  payment1: { isValid: boolean };
  payment2: { isValid: boolean; stripe?: StripeNextAction };
}

interface NextActionStateModel {
  nextActions: OrderServicePaymentNextAction[];
  orderServiceValidationResult: undefined | OrderServiceValidation;
}

export class NextActionsPatch {
  static readonly type = '@nextActionState.patch';
  constructor(public state: Partial<NextActionStateModel>) {}
}

export class NextActionValidateOrder {
  static readonly type = '@nextActionState.validateOrder';
  constructor(public orderServiceId: number, public alert: HTMLIonAlertElement | undefined) {}
}

export class NextActionCompletePaymentAuthorization {
  static readonly type = '@nextActionState.completePaymentAuthorization';
  constructor(public nextAction: OrderServicePaymentNextAction) {}
}

@State<NextActionStateModel>({
  name: 'NextActionState',
  defaults: {
    nextActions: [],
    orderServiceValidationResult: undefined,
  },
})
@Injectable()
export class NextActionState implements NgxsOnInit {
  constructor(
    private store: Store,
    private stripePaymentService: StripePaymentService,
    private userServices: UserService,
    private alertController: AlertController,
    private orderDetailsService: OrderDetailsService,
    private appFeaturesState: AppFeaturesState,
    private toastService: ToastService
  ) {}

  @Selector()
  static nextActions(state: NextActionStateModel) {
    return state.nextActions;
  }

  @Selector()
  static orderServiceValidationResult(state: NextActionStateModel) {
    return state.orderServiceValidationResult;
  }

  ngxsOnInit(ctx?: StateContext<any> | undefined) {
    this.store.select(NextActionState.nextActions).subscribe(async (nextActions) => {
      if (!nextActions || !nextActions.length) {
        return;
      }
      await this.handleActions(nextActions);
    });
  }

  private async openOrderDetails(ordersServicesId: string, alert: HTMLIonAlertElement) {
    const modal = await this.orderDetailsService.showDetails(undefined, ordersServicesId);
    if (!modal) {
      return;
    }
    const subscription = this.store.select(OrderDetailsState.order).subscribe(async (order) => {
      if (order?.orderServiceStatus === OrderServiceStatus.CANCELED) {
        await modal.onDidDismiss();
        await alert.dismiss();
      }
    });
    await modal.onDidDismiss();
    subscription.unsubscribe();
  }

  private async completePaymentAuthorization(nextAction: OrderServicePaymentNextAction, alert: HTMLIonAlertElement | undefined) {
    const isNativeIos = this.appFeaturesState.isNativeIos;
    const nextActionStripe: StripeNextAction =
      this.store.selectSnapshot(NextActionState.orderServiceValidationResult)?.payment2.stripe ?? nextAction.details.stripePayload.stripe;
    const stripePayload: ScheduleServiceStripePayload = {
      title: nextAction.details.stripePayload.title,
      message: nextAction.details.stripePayload.message,
      stripe: nextActionStripe,
      type: StripePayloadEnum.NEXT_ACTION_NEEDED,
    };
    if (isNativeIos) {
      await this.stripePaymentService.handleStripeAuthForNativeApp([stripePayload]);
      this.store.dispatch(
        new NextActionValidateOrder(
          parseInt(nextAction.details.ordersServicesId), // parseInt because backend returns id from some api as string
          alert
        )
      );
    } else {
      await this.stripePaymentService.handleStripeAuthForWebApp([stripePayload]);
      this.store.dispatch(
        new NextActionValidateOrder(
          parseInt(nextAction.details.ordersServicesId), // parseInt because backend returns id from some api as string
          alert
        )
      );
    }
  }

  @Action(NextActionCompletePaymentAuthorization)
  private async completePaymentActionHandler(ctx: StateContext<NextActionStateModel>, action: NextActionCompletePaymentAuthorization) {
    await this.completePaymentAuthorization(action.nextAction, this.alert);
  }

  alert: HTMLIonAlertElement | undefined;
  private async handleActions(nextActions: OrderServicePaymentNextAction[]) {
    for (const nextAction of nextActions) {
      switch (nextAction.type) {
        case NextActionType.ORDER_SERVICE_PAYMENT_AUTHENTICATION:
          const alert = await this.alertController.create({
            header: nextAction.details.title,
            message: nextAction.details.message,
            backdropDismiss: false,
            translucent: true,
            cssClass: 'next-action-order-payment',
            buttons: [
              {
                role: 'viewOrder',
                text: 'View Order',
                cssClass: 'view-order',
                handler: () => {
                  this.openOrderDetails(nextAction.details.ordersServicesId, alert);
                  return false;
                },
              },
              {
                text: 'Complete Authorization',
                role: 'completeAuthorization',
                handler: () => {
                  this.completePaymentAuthorization(nextAction, alert);
                  return false;
                },
              },
              {
                role: 'close',
                text: 'Dismiss',
                cssClass: 'view-order-close-btn',
              },
            ],
          });
          this.alert = alert;
          await alert.present();
          await alert.onDidDismiss();
          break;

        default:
          break;
      }
    }
  }

  @Action(NextActionValidateOrder)
  validateOrder(ctx: StateContext<NextActionStateModel>, action: NextActionValidateOrder) {
    ctx.dispatch(new SetLoading(true, this, true));
    return this.userServices.validateOrder(action.orderServiceId).pipe(
      tap(async (res) => {
        if (res.data.payment1.isValid && res.data.payment2.isValid) {
          await action.alert?.dismiss();
        }
      }),
      tap((res) => {
        if (res.message) {
          this.toastService.showToast(this.appFeaturesState.isApp, { message: res.message });
        }
        ctx.patchState({ orderServiceValidationResult: res.data });
      }),
      finalize(() => ctx.dispatch(new SetLoading(false, this, true)))
    );
  }

  @Action(NextActionsPatch)
  patchState(ctx: StateContext<NextActionStateModel>, action: NextActionsPatch) {
    ctx.patchState(action.state);
  }

  @Action(ResetState)
  resetState(ctx: StateContext<NextActionStateModel>, action: ResetState) {
    ctx.setState({
      nextActions: [],
      orderServiceValidationResult: undefined,
    });
  }
}
