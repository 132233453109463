import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { Observable, lastValueFrom, switchMap, tap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { LoadingState } from '../../../core/loading.state';
import { AppFeaturesState } from '../../../portal/app-feature.state';
import { NextActionCompletePaymentAuthorization } from '../../../portal/next-action.state';
import { OrdersService, OrdersType } from '../../../portal/orders.service';
import {
  BooleanStatus,
  IncompleteOrderPayment,
  NextActionType,
  Order,
  OrderLocation,
  OrderPaymentOption,
  OrderServiceStatus,
} from '../../../shared';
import { DateTimePickerComponent } from '../../shared/date-time-picker/date-time-picker.component';
import { OrderServicePhotoViewComponent } from '../order-service-photo-view/order-service-photo-view.component';
import { OrderPictureUploaderComponent } from '../order-upload-picture/order-picture-uploader/order-picture-uploader.component';
import { OrderDetailGet, OrderDetailReset, OrderDetailsState, OrderDetailUpdate } from './order-detail.state';
import { GoogleAnalyticsService, LogEventType } from '../../../core/google-analytics.service';
import { InsideNavigateToOrderPayWithCard } from '../../../portal/inside.state';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderDetailsComponent implements OnInit, OnDestroy {
  @Input() orderServicesId: number;
  @Input() orderType: OrdersType;
  @Input() inModal: boolean = true;

  @Output() onDeclineOrder = new EventEmitter<void>();
  @Output() onDateChange = new EventEmitter<Date>();
  @Output() onCancelOrder = new EventEmitter<void>();
  @Output() confirmOrder = new EventEmitter<void>();
  @Output() completeOrder = new EventEmitter<void>();
  @Output() onUpdateOrderPickupStatus = new EventEmitter<boolean>();

  @ViewChild('picUploader') picUploader: OrderPictureUploaderComponent;
  @ViewChild('dateTimePicker') dateTimePicker: DateTimePickerComponent;

  ordersTypes = OrdersType;
  orderServiceStatuses = OrderServiceStatus;
  OrderPaymentOption = OrderPaymentOption;
  booleanStatus = BooleanStatus;

  payWithCardSwitchEnabled$ = this.store.select(AppFeaturesState.payWithCardSwitchEnabled);

  serverHost: String = environment.serverHost;

  @Select(OrderDetailsState.order)
  order$: Observable<Order>;

  @Select(LoadingState.loading) loading$: Observable<boolean>;

  constructor(
    public appFeaturesState: AppFeaturesState,
    private modalController: ModalController,
    private orderService: OrdersService,
    private store: Store,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}

  async handleIonScrollStart() {
    await this.googleAnalyticsService.logEvent({ name: LogEventType.SCROLL });
  }

  ngOnInit(): void {
    this.store.dispatch(new OrderDetailGet(this.orderServicesId, this.orderType));
  }

  ngOnDestroy(): void {
    this.store.dispatch(new OrderDetailReset());
  }

  async closeModal() {
    if (!this.inModal) {
      return;
    }
    await this.modalController.dismiss();
  }

  getColor(status: OrderServiceStatus | undefined) {
    return this.orderService.getColor(status);
  }

  declineOrder() {
    this.onDeclineOrder.next();
  }

  confirmOrderHandler() {
    this.confirmOrder.next();
  }

  completeOrderHandler() {
    this.completeOrder.next();
  }

  async viewImage(url: string) {
    if (!this.appFeaturesState.isApp) {
      window.open(url, '_blank');
      return;
    }
    const modal = await this.modalController.create({
      component: OrderServicePhotoViewComponent,
      componentProps: {
        imageUrl: url,
      },
    });
    await modal.present();
  }

  updateDate(date: Date) {
    this.onDateChange.next(date);
  }

  getAddressLink(location: OrderLocation | undefined, orderServiceStatus: OrderServiceStatus) {
    if (!location) {
      return undefined;
    }
    const address =
      orderServiceStatus === OrderServiceStatus.COMPLETE
        ? `${location.city}, ${location.state}`
        : `${location.address}, ${location.city} ${location.zip}, ${location.state}`;
    if (this.appFeaturesState.isIos) {
      return `http://maps.apple.com/?daddr=${encodeURI(address)}`;
    }
    return `https://www.google.com/maps/search/?api=1&query=${encodeURI(address)}`;
  }

  cancelOrder() {
    if (this.orderType !== OrdersType.USER) {
      return;
    }
    this.onCancelOrder.next();
  }

  updateLockerStatus(isPicked: boolean) {
    if (isPicked) {
      this.store.dispatch(new OrderDetailUpdate({ locker: {} }));
      this.onUpdateOrderPickupStatus.next(isPicked);
    }
  }

  async completePaymentAuthorization(incompleteOrderPayments: IncompleteOrderPayment[]) {
    for (const incompleteOrderPayment of incompleteOrderPayments) {
      await lastValueFrom(
        this.store
          .dispatch(
            new NextActionCompletePaymentAuthorization({
              type: NextActionType.ORDER_SERVICE_PAYMENT_AUTHENTICATION,
              details: incompleteOrderPayment,
              callbackAction: undefined,
            })
          )
          .pipe(switchMap(() => this.store.dispatch(new OrderDetailGet(this.orderServicesId, this.orderType))))
      );
    }
  }

  payWithCardHandler() {
    this.store.dispatch(new InsideNavigateToOrderPayWithCard(this.orderServicesId.toString()));
  }
}
