import { Injectable } from '@angular/core';
import { patch, updateItem } from '@ngxs/store/operators';
import { Action, Selector, State, StateContext, Store, createSelector } from '@ngxs/store';
import { from, Observable, throwError } from 'rxjs';
import { catchError, debounceTime, finalize, map, switchMap, tap } from 'rxjs/operators';
import { LoadingState, LoadingStateModel, SetLoading } from '../core/loading.state';
import {
  Vehicle,
  UserLocation,
  ScheduleServiceStripePayload,
  ResetState,
  StripePayloadEnum,
  ErrorMessage,
  UserNotification,
  OrderPaymentOption,
} from '../shared';
import { AuthState, AuthUpdateLastService } from './auth.state';
import { ScheduleServicePayload, UserService } from './user.service';
import { UpsertVehicle } from './user.service';
import { ToastService } from '../core/toast.service';
import { AppFeaturesState } from './app-feature.state';
import { ScheduleServicePatchState } from '../inside/schedule-services/schedule-services.state';
import { StripePaymentService } from './stripe-payment.service';
import { AlertController, LoadingController } from '@ionic/angular';
import { Device } from '@capacitor/device';
import { FirebaseState } from '../core/firebase.state';
import { ManagePaymentState } from '../inside/manage-payment/manage-payment.state';
import { Stripe } from '@capacitor-community/stripe';
import { OrdersService } from './orders.service';
import { InsideNavigateToNotifications, InsideNavigateToVehicleDetails } from './inside.state';
import { VehicleViewUpdateVehicleDetailsOptions } from '../inside/schedule-services/vehicle-v2/vehicles-view.state';

interface UserDetailsModel {
  userVehicles: Vehicle[];
  userVehiclesMessage: string | undefined;
  userVehiclesRecordsTotal: number | undefined;
  userLocations: UserLocation[];
  userLocationsMessage: string | undefined;
  userLocationsRecordsTotal: number | undefined;
  userOrder: { ordersId: number; ordersServicesId: number } | undefined; // new order that was placed
  userOrdersMessage: string | undefined;
  usersNotifications: UserNotification[];
  unreadNotificationsCount: number;
}

export class UserGetVehicles {
  static readonly type = '@userDetailsState.getVehicles';
  constructor() {}
}

export class UserAddVehicle {
  static readonly type = '@userDetailsState.addVehicle';
  constructor(
    public vehicle: UpsertVehicle,
    public options?: { updateLastService: boolean }
  ) {}
}

export class UserUpdateVehicle {
  static readonly type = '@userDetailsState.updateVehicle';
  constructor(public vehicle: UpsertVehicle) {}
}

export class UserDeleteVehicle {
  static readonly type = '@userDetailsState.deleteVehicle';
  constructor(public usersVehicleId: string) {}
}

export class UserGetLocations {
  static readonly type = '@userDetailsState.getLocations';
  constructor() {}
}

export class UserAddLocation {
  static readonly type = '@userDetailsState.addLocation';
  constructor(public userLocation: UserLocation) {}
}

export class UserUpdateLocation {
  static readonly type = '@userDetailsState.updateLocation';
  constructor(public userLocation: UserLocation) {}
}

export class UserDeleteLocation {
  static readonly type = '@userDetailsState.deleteLocation';
  constructor(public userLocationId: string) {}
}

export class UserStatePatch {
  static readonly type = '@userDetailsState.patch';
  constructor(public state: Partial<UserDetailsModel>) {}
}

export class UserScheduleService {
  static readonly type = '@userDetailsState.scheduleService';
  constructor(
    public selectedVehicleId: string,
    public selectedServiceId: number,
    public selectedServiceTypeCode: string | undefined,
    public selectedLocationId: string,
    public preferredTime: string,
    public userCreditCardId: number | undefined,
    public couponCode: string | undefined,
    public orderPaymentOption: OrderPaymentOption | undefined,
    public selectedServiceAddonIds: string[] | undefined
  ) {}
}

export class UserScheduleServiceApplePay {
  static readonly type = '@userDetailsState.scheduleServiceApplePay';
  constructor(
    public selectedVehicleId: string,
    public selectedServiceId: number,
    public selectedServiceTypeCode: string | undefined,
    public selectedLocationId: string,
    public preferredTime: string,
    public couponCode: string | undefined,
    public selectedServiceAddonIds: string[] | undefined
  ) {}
}

export class UserScheduleServiceGooglePay {
  static readonly type = '@userDetailsState.scheduleServiceGooglePay';
  constructor(
    public selectedVehicleId: string,
    public selectedServiceId: number,
    public selectedServiceTypeCode: string | undefined,
    public selectedLocationId: string,
    public preferredTime: string,
    public couponCode: string | undefined,
    public selectedServiceAddonIds: string[] | undefined
  ) {}
}

export class UserRegisterDevice {
  static readonly type = '@userDetailsState.registerDevice';
}

export class UserGetNotifications {
  static readonly type = '@userDetailsState.getNotifications';
}

export class UserUpdateNotification {
  static readonly type = '@userDetailsState.updateNotifications';
  constructor(public usersNotificationsId: string) {}
}

export class HandleRequestVinNumberNotification {
  static readonly type = '@userDetailsState.handleRequestVinNumberNotification';
  constructor(public ordersServicesId: string) {}
}

@State<UserDetailsModel>({
  name: 'UserDetailsState',
  defaults: {
    userVehicles: [],
    userVehiclesMessage: undefined,
    userVehiclesRecordsTotal: undefined,
    userLocations: [],
    userLocationsMessage: undefined,
    userLocationsRecordsTotal: undefined,
    userOrder: undefined,
    userOrdersMessage: undefined,
    unreadNotificationsCount: 0,
    usersNotifications: [],
  },
})
@Injectable()
export class UserDetailsState {
  constructor(
    private userService: UserService,
    private authState: AuthState,
    private store: Store,
    private toastService: ToastService,
    private stripePaymentService: StripePaymentService,
    private AppFeaturesState: AppFeaturesState,
    private loadingController: LoadingController,
    private alertController: AlertController,
    private ordersServices: OrdersService
  ) {}

  @Selector()
  private static state(state: UserDetailsModel) {
    return state;
  }
  state$ = this.store.select(UserDetailsState.state);

  private get snapshot() {
    return this.store.selectSnapshot(UserDetailsState.state);
  }

  @Selector()
  public static vehicles(state: UserDetailsModel) {
    return state.userVehicles;
  }
  public vehicles$: Observable<Vehicle[]> = this.store.select(UserDetailsState.vehicles);
  public get vehicles(): Vehicle[] {
    return this.snapshot.userVehicles;
  }

  @Selector()
  public static userVehiclesMessage(state: UserDetailsModel) {
    return state.userVehiclesMessage;
  }

  @Selector()
  public static userVehiclesRecordsTotal(state: UserDetailsModel) {
    return state.userVehiclesRecordsTotal;
  }

  @Selector()
  public static userOrderMessage(state: UserDetailsModel) {
    return state.userOrdersMessage;
  }

  @Selector()
  static userNewOrder(state: UserDetailsModel) {
    return state.userOrder;
  }

  @Selector()
  public static locations(state: UserDetailsModel) {
    return state.userLocations;
  }
  public userLocations$: Observable<UserLocation[]> = this.store.select(UserDetailsState.locations);
  public get userLocations(): UserLocation[] {
    return this.snapshot.userLocations;
  }

  @Selector()
  public static userLocationsMessage(state: UserDetailsModel) {
    return state.userLocationsMessage;
  }

  @Selector()
  public static userLocationsRecordsTotal(state: UserDetailsModel) {
    return state.userLocationsRecordsTotal;
  }

  @Selector()
  public static unreadNotificationsCount(state: UserDetailsModel) {
    return state.unreadNotificationsCount;
  }

  @Selector()
  public static usersNotifications(state: UserDetailsModel) {
    return state.usersNotifications;
  }

  @Selector([LoadingState])
  public static upsertLocationLoading(loadingState: Record<string, LoadingStateModel>) {
    const addLocationLoadingKey = UserDetailsState.name + '_' + UserAddLocation.name;
    const updateLocationLoadingKey = UserDetailsState.name + '_' + UserUpdateLocation.name;
    return loadingState[addLocationLoadingKey]?.loading || loadingState[updateLocationLoadingKey]?.loading;
  }

  @Selector([LoadingState])
  public static upsertVehicleLoading(loadingState: Record<string, LoadingStateModel>) {
    const key = UserDetailsState.name + '_' + UserUpdateVehicle.name;
    return loadingState[key]?.loading;
  }

  private setLoading(loading: boolean) {
    this.store.dispatch(new SetLoading(loading, this));
  }

  @Action(UserGetVehicles)
  getUserVehicles(ctx: StateContext<UserDetailsModel>, action: UserGetVehicles) {
    const userId = this.authState.userId;
    this.setLoading(true);
    return this.userService.getVehicles(userId).pipe(
      map((res) => {
        ctx.patchState({ userVehicles: res.arAutos, userVehiclesRecordsTotal: res.recordsTotal, userVehiclesMessage: res.message });
      }),
      finalize(() => this.setLoading(false))
    );
  }

  @Action(UserAddVehicle)
  createUserVehicles(ctx: StateContext<UserDetailsModel>, action: UserAddVehicle) {
    const userId = this.authState.userId;
    this.setLoading(true);
    return this.userService.createVehicles(userId, action.vehicle).pipe(
      switchMap((res) => {
        return ctx.dispatch(new UserGetVehicles()).pipe(
          tap(() => {
            const vehicle = ctx.getState().userVehicles.find((v) => v.usersVehiclesId == res.usersVehiclesId);
            if (!vehicle) {
              return;
            }
            ctx.dispatch(
              new ScheduleServicePatchState({
                selectedVehicle: vehicle,
              })
            );
          })
        );
      }),
      finalize(() => this.setLoading(false))
    );
  }

  @Action(UserUpdateVehicle)
  updateUserVehicle(ctx: StateContext<UserDetailsModel>, action: UserUpdateVehicle) {
    const userId = this.authState.userId;
    ctx.dispatch(new SetLoading(true, this, false, UserUpdateVehicle.name));
    return this.userService.updateVehicles(userId, action.vehicle).pipe(
      switchMap(() => ctx.dispatch(new UserGetVehicles())),
      finalize(() => ctx.dispatch(new SetLoading(false, this, false, UserUpdateVehicle.name)))
    );
  }

  @Action(UserDeleteVehicle)
  deleteUseVehicle(ctx: StateContext<UserDetailsModel>, action: UserDeleteVehicle): Observable<void> {
    const userId = this.authState.userId;
    this.setLoading(true);

    return this.userService.deleteVehicle(userId, action.usersVehicleId).pipe(
      tap(() => {
        const state = this.snapshot;
        ctx.patchState({
          userVehicles: state.userVehicles.filter((userVehicle) => userVehicle.usersVehiclesId !== action.usersVehicleId),
        });
      }),
      finalize(() => this.setLoading(false))
    );
  }

  @Action(UserGetLocations)
  getUserLocations(ctx: StateContext<UserDetailsModel>, action: UserGetLocations) {
    const userId = this.authState.userId;
    this.setLoading(true);
    return this.userService.getUserLocations(userId).pipe(
      tap((res) => {
        ctx.patchState({
          userLocations: res.data,
          userLocationsMessage: res.message,
          userLocationsRecordsTotal: res.recordsTotal,
        });
      }),
      finalize(() => this.setLoading(false))
    );
  }

  @Action(UserAddLocation)
  createUserLocation(ctx: StateContext<UserDetailsModel>, action: UserAddLocation) {
    ctx.dispatch(new SetLoading(true, this, false, UserAddLocation.name));
    const userId = this.authState.userId;
    return this.userService.createUserLocation(userId, action.userLocation).pipe(
      tap((newLocation) => {
        if (newLocation.isDefault) {
          const updatedLocations = ctx.getState().userLocations.map((l) => ({ ...l, isDefault: false }));
          ctx.patchState({
            userLocations: [{ ...newLocation, usersLocationsId: newLocation?.usersLocationsId?.toString() }, ...updatedLocations],
          });
        } else {
          ctx.patchState({
            userLocations: [
              { ...newLocation, usersLocationsId: newLocation?.usersLocationsId?.toString() },
              ...this.snapshot.userLocations,
            ],
          });
        }

        if (newLocation.locationServices?.some((ls) => ls.status === 1)) {
          ctx.dispatch(
            new ScheduleServicePatchState({
              selectedLocation: newLocation,
            })
          );
        }
      }),
      finalize(() => ctx.dispatch(new SetLoading(false, this, false, UserAddLocation.name)))
    );
  }

  @Action(UserUpdateLocation)
  updateUserLocation(ctx: StateContext<UserDetailsModel>, action: UserUpdateLocation) {
    ctx.dispatch(new SetLoading(true, this, false, UserUpdateLocation.name));
    const userId = this.authState.userId;
    return this.userService.updateUserLocation(userId, action.userLocation).pipe(
      tap((updatedLocation) => {
        if (updatedLocation.isDefault) {
          ctx.dispatch(
            new ScheduleServicePatchState({
              selectedLocation: updatedLocation,
            })
          );
          const patchedLocations = ctx.getState().userLocations.map((location) => {
            if (location.usersLocationsId == action.userLocation.usersLocationsId) {
              return updatedLocation;
            } else {
              return { ...location, isDefault: false };
            }
          });
          ctx.patchState({ userLocations: patchedLocations });
        } else {
          ctx.setState(
            patch({
              userLocations: updateItem<UserLocation>(
                (f) => f?.usersLocationsId === action.userLocation.usersLocationsId,
                patch(updatedLocation)
              ),
            })
          );
        }
      }),
      debounceTime(10000),
      finalize(() => ctx.dispatch(new SetLoading(false, this, false, UserUpdateLocation.name)))
    );
  }

  @Action(UserDeleteLocation)
  deleteUserLocation(ctx: StateContext<UserDetailsModel>, action: UserDeleteLocation) {
    this.setLoading(true);
    const userId = this.authState.userId;
    return this.userService.deleteUserLocation(userId, action.userLocationId).pipe(
      tap((updatedLocation) => {
        ctx.patchState({
          userLocations: this.snapshot.userLocations.filter((l) => l.usersLocationsId !== action.userLocationId),
        });
      }),
      finalize(() => this.setLoading(false))
    );
  }

  @Action(UserScheduleService)
  scheduleService(ctx: StateContext<UserDetailsModel>, action: UserScheduleService) {
    this.setLoading(true);
    const serviceOptions: ScheduleServicePayload = {
      preferredTime: action.preferredTime,
      servicesId: action.selectedServiceId,
      servicePartCode: action.selectedServiceTypeCode,
      usersLocationsId: action.selectedLocationId,
      usersVehiclesId: action.selectedVehicleId,
      couponCode: action.couponCode,
      orderPaymentOption: action.orderPaymentOption,
      selectedServiceAddonIds: action.selectedServiceAddonIds,
    };
    if (action.orderPaymentOption === OrderPaymentOption.REGULAR_PAYMENT_BY_CARD) {
      serviceOptions.usersCreditCardsId = action.userCreditCardId;
    }
    return this.userService.scheduleService(this.authState.userId, serviceOptions).pipe(
      tap((res) => {
        const isApp = this.store.selectSnapshot(AppFeaturesState.state).isApp;
        if (res.message) {
          this.toastService.showToast(isApp, { message: res.message });
        }
        ctx.patchState({ userOrdersMessage: res.message, userOrder: res.order });
        const vehicle = ctx.getState().userVehicles.find((v) => v.usersVehiclesId == action.selectedVehicleId);
        const location = ctx.getState().userLocations.find((l) => l.usersLocationsId == action.selectedLocationId);
        if (vehicle && location) {
          ctx.dispatch(new AuthUpdateLastService({ usersVehicle: vehicle, usersLocation: location }));
        }
      }),
      switchMap(async (res) => {
        if (action.orderPaymentOption !== OrderPaymentOption.REGULAR_PAYMENT_BY_CARD) {
          return res;
        }
        const stripePayloads: ScheduleServiceStripePayload[] = [];
        let validateOrder: boolean = false; // validate order after user completes authorization steps
        if (res.stripePayload1 && res.stripePayload1.type === StripePayloadEnum.NEXT_ACTION_NEEDED) {
          validateOrder = true;
          stripePayloads.push(res.stripePayload1);
        }
        if (res.stripePayload2 && res.stripePayload2.type === StripePayloadEnum.NEXT_ACTION_NEEDED) {
          validateOrder = true;
          stripePayloads.push(res.stripePayload2);
        }
        if (!validateOrder) {
          return res;
        }

        if (!this.AppFeaturesState.isNativeIos) {
          // modal and iframe approach for all systems
          await this.stripePaymentService.handleStripeAuthForWebApp(stripePayloads);
        } else {
          await this.stripePaymentService.handleStripeAuthForNativeApp(stripePayloads);
        }
        if (validateOrder) {
          await this.scheduleServiceFromCart(res.order.ordersServicesId);
        }

        return res;
      }),
      finalize(() => this.setLoading(false))
    );
  }

  private async scheduleServiceFromCart(ordersServicesId: number) {
    const loading = await this.loadingController.create({
      message: 'Processing Order',
      duration: 8000,
    });
    await loading.present();
    const promise = new Promise<void>(async (resolve, reject) => {
      this.userService
        .scheduleServiceFromCart(ordersServicesId)
        .pipe(
          tap({
            error: async () => {
              await loading.dismiss();
              reject();
            },
          })
        )
        .subscribe(async (res) => {
          await loading.dismiss();
          if (res.message) {
            const isApp = this.store.selectSnapshot(AppFeaturesState.state).isApp;
            this.toastService.showToast(isApp, {
              message: res.message,
            });
          }
          if (!res.data.payment1.isValid || !res.data.payment2.isValid) {
            reject();
          }
          resolve();
        });
    });
    return await promise;
  }

  @Action(UserScheduleServiceApplePay)
  scheduleServiceWithApplePay(ctx: StateContext<UserDetailsModel>, action: UserScheduleServiceApplePay) {
    this.setLoading(true);
    const stripeKey = this.store.selectSnapshot(ManagePaymentState.stripePublishableKey) ?? '';
    Stripe.initialize({
      publishableKey: stripeKey,
    });
    return from(Stripe.isApplePayAvailable())
      .pipe(catchError(() => throwError(() => new Error(ErrorMessage.APPLE_PAY_NOT_AVAILABLE))))
      .pipe(
        switchMap(() => {
          return this.userService
            .scheduleService(this.authState.userId, {
              preferredTime: action.preferredTime,
              servicesId: action.selectedServiceId,
              servicePartCode: action.selectedServiceTypeCode,
              isDraft: true,
              usersLocationsId: action.selectedLocationId,
              usersVehiclesId: action.selectedVehicleId,
              couponCode: action.couponCode,
              orderPaymentOption: OrderPaymentOption.REGULAR_PAYMENT_BY_CARD,
              selectedServiceAddonIds: action.selectedServiceAddonIds,
            })
            .pipe(
              tap((res) => {
                const isApp = this.store.selectSnapshot(AppFeaturesState.state).isApp;
                if (res.message) {
                  this.toastService.showToast(isApp, { message: res.message });
                }
                ctx.patchState({ userOrdersMessage: res.message, userOrder: res.order });
                const vehicle = ctx.getState().userVehicles.find((v) => v.usersVehiclesId == action.selectedVehicleId);
                const location = ctx.getState().userLocations.find((l) => l.usersLocationsId == action.selectedLocationId);
                if (vehicle && location) {
                  ctx.dispatch(new AuthUpdateLastService({ usersVehicle: vehicle, usersLocation: location }));
                }
              })
            );
        })
      )
      .pipe(
        switchMap((res) => {
          if (
            !res.stripePayload1 ||
            res.stripePayload1.type !== StripePayloadEnum.DRAFT_ORDER ||
            !res.stripePayload2 ||
            res.stripePayload2.type !== StripePayloadEnum.DRAFT_ORDER
          ) {
            throw Error(ErrorMessage.APPLE_PAY_FAILED_TO_CREATE_ORDER);
          }

          const paymentRequestOptions1st = this.stripePaymentService.getPaymentRequestOptions(res.stripePayload1.amount);
          const paymentRequestOptions2nd = this.stripePaymentService.getPaymentRequestOptions(res.stripePayload2.amount);
          return from(
            Stripe.createApplePay({
              countryCode: paymentRequestOptions1st.country,
              currency: paymentRequestOptions1st.currency,
              merchantIdentifier: 'merchant.com.allrotors',
              paymentSummaryItems: [
                {
                  label: res.stripePayload1.message,
                  amount: paymentRequestOptions1st.total.amount / 100,
                },
              ],
              paymentIntentClientSecret: res.stripePayload1.clientSecret,
            })
          )
            .pipe(switchMap(() => from(Stripe.presentApplePay())))
            .pipe(
              debounceTime(3000),
              switchMap(() => {
                if (!res.stripePayload2 || res.stripePayload2.type !== StripePayloadEnum.DRAFT_ORDER) {
                  throw Error(ErrorMessage.APPLE_PAY_FAILED_TO_CREATE_ORDER);
                }
                return from(
                  Stripe.createApplePay({
                    countryCode: paymentRequestOptions2nd.country,
                    currency: paymentRequestOptions2nd.currency,
                    merchantIdentifier: 'merchant.com.allrotors',
                    paymentSummaryItems: [
                      {
                        label: res.stripePayload2?.message ?? '',
                        amount: paymentRequestOptions2nd.total.amount / 100,
                      },
                    ],
                    paymentIntentClientSecret: res.stripePayload2.clientSecret,
                  })
                ).pipe(switchMap(() => from(Stripe.presentApplePay())));
              })
            )
            .pipe(map(() => res));
        }),
        switchMap((res) => {
          return from(this.scheduleServiceFromCart(res.order.ordersServicesId));
        })
      )
      .pipe(finalize(() => this.setLoading(false)));
  }

  @Action(UserScheduleServiceGooglePay)
  scheduleServiceWithGooglePay(ctx: StateContext<UserDetailsModel>, action: UserScheduleServiceGooglePay) {
    this.setLoading(true);
    const stripeKey = this.store.selectSnapshot(ManagePaymentState.stripePublishableKey) ?? '';
    Stripe.initialize({
      publishableKey: stripeKey,
    });
    return from(Stripe.isGooglePayAvailable())
      .pipe(catchError(() => throwError(() => new Error(ErrorMessage.GOOGLE_PAY_NOT_AVAILABLE))))
      .pipe(
        switchMap(() => {
          return this.userService
            .scheduleService(this.authState.userId, {
              preferredTime: action.preferredTime,
              servicesId: action.selectedServiceId,
              servicePartCode: action.selectedServiceTypeCode,
              isDraft: true,
              usersLocationsId: action.selectedLocationId,
              usersVehiclesId: action.selectedVehicleId,
              couponCode: action.couponCode,
              orderPaymentOption: OrderPaymentOption.REGULAR_PAYMENT_BY_CARD,
              selectedServiceAddonIds: action.selectedServiceAddonIds,
            })
            .pipe(
              tap((res) => {
                const isApp = this.store.selectSnapshot(AppFeaturesState.state).isApp;
                if (res.message) {
                  this.toastService.showToast(isApp, { message: res.message });
                }
                ctx.patchState({ userOrdersMessage: res.message, userOrder: res.order });
                const vehicle = ctx.getState().userVehicles.find((v) => v.usersVehiclesId == action.selectedVehicleId);
                const location = ctx.getState().userLocations.find((l) => l.usersLocationsId == action.selectedLocationId);
                if (vehicle && location) {
                  ctx.dispatch(new AuthUpdateLastService({ usersVehicle: vehicle, usersLocation: location }));
                }
              })
            );
        })
      )
      .pipe(
        switchMap((res) => {
          if (
            !res.stripePayload1 ||
            res.stripePayload1.type !== StripePayloadEnum.DRAFT_ORDER ||
            !res.stripePayload2 ||
            res.stripePayload2.type !== StripePayloadEnum.DRAFT_ORDER
          ) {
            throw Error(ErrorMessage.GOOGLE_PAY_FAILED_TO_CREATE_ORDER);
          }
          const { clientSecret: stripePayload1ClientSecret, message: stripePayload1Message } = res.stripePayload1;
          const { clientSecret: stripePayload2ClientSecret, message: stripePayload2Message } = res.stripePayload2;
          const paymentRequestOptions1st = this.stripePaymentService.getPaymentRequestOptions(res.stripePayload1.amount);
          const paymentRequestOptions2nd = this.stripePaymentService.getPaymentRequestOptions(res.stripePayload2.amount);
          const promisePayment1 = new Promise(async (resolve, reject) => {
            const alert = await this.alertController.create({
              message: res.stripePayload1?.message,
              buttons: [{ text: 'Ok', role: 'ok' }],
              backdropDismiss: false,
            });
            await alert.present();
            const alertResult = await alert.onDidDismiss();
            if (alertResult.role !== 'ok') {
              resolve(undefined);
            }
            await Stripe.createGooglePay({
              countryCode: paymentRequestOptions1st.country,
              currency: paymentRequestOptions1st.currency,
              merchantIdentifier: 'com.allrotors.app',
              paymentSummaryItems: [
                {
                  label: stripePayload1Message,
                  amount: paymentRequestOptions1st.total.amount / 100,
                },
              ],
              paymentIntentClientSecret: stripePayload1ClientSecret,
            });
            const result = await Stripe.presentGooglePay();
            resolve(result);
          });
          return from(promisePayment1)
            .pipe(
              switchMap(() => {
                const promisePayment2 = new Promise(async (resolve, reject) => {
                  const alert = await this.alertController.create({
                    message: stripePayload2Message,
                    buttons: [{ text: 'Ok', role: 'ok' }],
                    backdropDismiss: false,
                  });
                  await alert.present();
                  const alertResult = await alert.onDidDismiss();
                  if (alertResult.role !== 'ok') {
                    resolve(undefined);
                  }
                  await Stripe.createGooglePay({
                    countryCode: paymentRequestOptions2nd.country,
                    currency: paymentRequestOptions2nd.currency,
                    merchantIdentifier: 'com.allrotors.app',
                    paymentSummaryItems: [
                      {
                        label: stripePayload2Message,
                        amount: paymentRequestOptions2nd.total.amount / 100,
                      },
                    ],
                    paymentIntentClientSecret: stripePayload2ClientSecret,
                  });
                  const result = await Stripe.presentGooglePay();
                  resolve(result);
                });
                return from(promisePayment2);
              })
            )
            .pipe(map(() => res));
        }),
        switchMap((res) => {
          return from(this.scheduleServiceFromCart(res.order.ordersServicesId));
        })
      )
      .pipe(finalize(() => this.setLoading(false)));
  }

  @Action(UserStatePatch)
  patchState(ctx: StateContext<UserDetailsModel>, action: UserStatePatch) {
    ctx.patchState(action.state);
  }

  @Action(UserRegisterDevice)
  registerDevice(ctx: StateContext<UserDetailsModel>, action: UserRegisterDevice) {
    const deviceToken = this.store.selectSnapshot(FirebaseState.deviceToken);
    const isAdminMockingUser = this.store.selectSnapshot(AuthState.state).adminMockingUser;
    if (!deviceToken || isAdminMockingUser) {
      return;
    }
    ctx.dispatch(new SetLoading(true, this));
    const deviceId$ = from(Device.getId());
    return deviceId$
      .pipe(
        switchMap((deviceId) => {
          return this.userService.registerDevice(deviceToken, this.AppFeaturesState.deviceType, deviceId.uuid);
        })
      )
      .pipe(finalize(() => ctx.dispatch(new SetLoading(false, this))));
  }

  @Action(UserGetNotifications)
  getUserNotifications(ctx: StateContext<UserDetailsModel>) {
    ctx.dispatch(new SetLoading(true, this, false, UserGetNotifications.name));
    return this.userService.getUserNotifications(this.authState.userId).pipe(
      tap((res) => {
        ctx.patchState({
          unreadNotificationsCount: parseInt(res.unreadNotifications),
          usersNotifications: res.userNotifications,
        });
      }),
      finalize(() => ctx.dispatch(new SetLoading(false, this, false, UserGetNotifications.name)))
    );
  }

  @Action(UserUpdateNotification)
  updateUserNotification(ctx: StateContext<UserDetailsModel>, action: UserUpdateNotification) {
    ctx.dispatch(new SetLoading(true, this, false, UserUpdateNotification.name));
    return this.userService.updateUserNotification(this.authState.userId, action.usersNotificationsId, '1').pipe(
      tap((res) => {
        const state = ctx.getState();
        const updatedUnreadNotificationsCount = state.unreadNotificationsCount - 1;

        ctx.setState(
          patch({
            unreadNotificationsCount: updatedUnreadNotificationsCount <= 0 ? 0 : updatedUnreadNotificationsCount,
            usersNotifications: updateItem<UserNotification>(
              (n) => n?.usersNotificationsId === action.usersNotificationsId,
              patch(res.userNotification)
            ),
          })
        );
      }),
      finalize(() => ctx.dispatch(new SetLoading(false, this, false, UserUpdateNotification.name)))
    );
  }

  @Action(HandleRequestVinNumberNotification)
  handleRequestVinNumberNotification(ctx: StateContext<UserDetailsModel>, action: HandleRequestVinNumberNotification) {
    ctx.dispatch(new SetLoading(true, this, true, this.handleRequestVinNumberNotification.name));
    return this.ordersServices
      .getOrders({
        id: parseInt(action.ordersServicesId),
      })
      .pipe(
        tap((orderRes) => {
          if (!orderRes.data[0].vehicle?.id) {
            throw Error('invalid vehicle id');
          }
          ctx.dispatch(
            new VehicleViewUpdateVehicleDetailsOptions({
              backAction: new InsideNavigateToNotifications(),
              loadVehicles: true,
            })
          );
          ctx.dispatch(new InsideNavigateToVehicleDetails(orderRes.data[0].vehicle?.id));
        }),
        finalize(() => ctx.dispatch(new SetLoading(false, this, true, this.handleRequestVinNumberNotification.name)))
      );
  }

  @Action(ResetState)
  resetState(ctx: StateContext<UserDetailsModel>, action: ResetState) {
    ctx.setState({
      userVehicles: [],
      userVehiclesMessage: undefined,
      userVehiclesRecordsTotal: undefined,
      userLocations: [],
      userLocationsMessage: undefined,
      userLocationsRecordsTotal: undefined,
      userOrder: undefined,
      userOrdersMessage: undefined,
      unreadNotificationsCount: 0,
      usersNotifications: [],
    });
  }
}
