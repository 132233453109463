import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch, removeItem } from '@ngxs/store/operators';
import { finalize, tap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { SetLoading } from '../../../core/loading.state';
import { OrdersService, OrdersType } from '../../../portal/orders.service';
import { Order, OrderServiceImage, ResetState } from '../../../shared';

export class UploadPictureUpload {
  static readonly type = '@uploadPicture.upload';
  constructor(
    public orderServiceId: number,
    public files: Blob[]
  ) {}
}

export class UploadPictureLoadImages {
  static readonly type = '@uploadPicture.loadImages';
  constructor(
    public orderServiceId: number,
    public ordersType: OrdersType
  ) {}
}

export class UploadPictureSetImages {
  static readonly type = '@uploadPicture.setImages';
  constructor(public pictures: OrderServiceImage[]) {}
}

export class UploadPictureDeleteImage {
  static readonly type = '@uploadPicture.deleteImage';
  constructor(
    public orderServiceId: string,
    public imageId: string
  ) {}
}

export class UploadPictureReset {
  static readonly type = '@uploadPicture.reset';
  constructor() {}
}

interface UploadPictureStateModel {
  pictures: OrderServiceImage[] | undefined;
}

@State<UploadPictureStateModel>({
  name: 'UploadPictureState',
  defaults: {
    pictures: undefined,
  },
})
@Injectable()
export class UploadPictureState {
  constructor(private ordersService: OrdersService) {}

  @Selector()
  static pictures(state: UploadPictureStateModel): OrderServiceImage[] | undefined {
    if (!state.pictures) {
      return undefined;
    }
    return state.pictures.map((pic) => ({
      ...pic,
      filePath: `${environment.serverHost}${pic.filePath}`,
      filePathM: `${environment.serverHost}${pic.filePathM}`,
      filePathS: `${environment.serverHost}${pic.filePathS}`,
    }));
  }

  @Action(UploadPictureLoadImages)
  loadOrderImages(ctx: StateContext<UploadPictureStateModel>, action: UploadPictureLoadImages) {
    ctx.dispatch(new SetLoading(true, this));
    return this.ordersService.getOrders({ id: action.orderServiceId, ordersType: action.ordersType }).pipe(
      tap((res) => {
        const [order] = res.data ?? [];
        if (order && order.images) {
          ctx.patchState({
            pictures: order.images,
          });
        }
      }),
      finalize(() => ctx.dispatch(new SetLoading(false, this)))
    );
  }

  @Action(UploadPictureUpload)
  uploadImages(ctx: StateContext<UploadPictureStateModel>, action: UploadPictureUpload) {
    ctx.dispatch(new SetLoading(true, this));
    return this.ordersService.uploadPicture(action.orderServiceId, action.files).pipe(
      tap((res) => {
        const pictures = ctx.getState().pictures ?? [];
        ctx.setState(
          patch({
            pictures: [...pictures, ...res.pictures],
          })
        );
      }),
      finalize(() => ctx.dispatch(new SetLoading(false, this)))
    );
  }

  @Action(UploadPictureDeleteImage)
  deleteImage(ctx: StateContext<UploadPictureStateModel>, action: UploadPictureDeleteImage) {
    ctx.dispatch(new SetLoading(true, this));
    return this.ordersService.deletePicture(action.orderServiceId, action.imageId).pipe(
      tap(() => {
        const removeItemComparator = removeItem<OrderServiceImage>((pic) => pic?.id === action.imageId);
        ctx.setState(
          patch({
            pictures: removeItemComparator,
          })
        );
      }),
      finalize(() => ctx.dispatch(new SetLoading(false, this)))
    );
  }

  @Action(UploadPictureReset)
  resetState(ctx: StateContext<UploadPictureStateModel>, action: UploadPictureReset) {
    ctx.setState({
      pictures: undefined,
    });
  }

  @Action(ResetState)
  resetStateByParent(ctx: StateContext<UploadPictureStateModel>, action: ResetState) {
    ctx.dispatch(new UploadPictureReset());
  }

  @Action(UploadPictureSetImages)
  private setImages(ctx: StateContext<UploadPictureStateModel>, action: UploadPictureSetImages) {
    ctx.setState({
      pictures: action.pictures,
    });
  }
}
