<ion-header class="ion-no-border">
  <ion-toolbar [ngClass]="appFeaturesState.isIos ? 'ion-margin-top' : 'toolbar'">
    <ion-button slot="end" fill="clear" color="secondary" (click)="closeModal()">
      <ion-icon size="large" name="close"></ion-icon>
    </ion-button>

    <ion-title class="ion-text-center">
      <div class="ion-text-wrap">{{ isSignUp ? 'Sign up' : 'Forgot Password' }}</div>
    </ion-title>
    <ion-progress-bar *ngIf="loadingState.loading$ | async" type="indeterminate"></ion-progress-bar>
  </ion-toolbar>
  <div *ngIf="invitationToken" class="chip-container">
    <ion-chip color="success">
      <ion-icon name="checkmark-circle"></ion-icon>
      <ion-label> Invitation Link Applied </ion-label>
    </ion-chip>
  </div>
</ion-header>
<ion-content [scrollEvents]="true" (ionScrollStart)="handleIonScrollStart()" *ngIf="isSignUp && !underIframe">
  <div class="ion-margin-horizontal" [formGroup]="signUpFormGroup">
    <ion-list mode="md">
      <ion-item>
        <ion-label position="floating">First Name</ion-label>
        <ion-input type="text" formControlName="fname"></ion-input>
        <span slot="error">
          <app-error-view
            *ngIf="signUpFormGroup.controls.fname.touched"
            name="first name"
            [errors]="signUpFormGroup.controls.fname.errors"
          ></app-error-view>
        </span>
        <span slot="helper"></span>
      </ion-item>
      <ion-item>
        <ion-label position="floating">Last Name</ion-label>
        <ion-input type="text" formControlName="lname"></ion-input>
        <span slot="error">
          <app-error-view
            *ngIf="signUpFormGroup.controls.lname.touched"
            name="last name"
            [errors]="signUpFormGroup.controls.lname.errors"
          ></app-error-view>
        </span>
        <span slot="helper"></span>
      </ion-item>
      <i class="flag-icon flag-icon-it"> </i>
      <div class="d-flex">
        <ion-item class="country-code-item">
          <ion-label position="stacked">Country Code</ion-label>
          <ion-select [compareWith]="countryCodeCompareWith" interface="popover" formControlName="countryCode">
            <ion-select-option [value]="code" *ngFor="let code of supportedCountryCodes$ | async">
              {{ code.code }}&nbsp;{{ code.name }}
            </ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item class="phone-number-item ml-10">
          <ion-label position="floating">Phone</ion-label>
          <ion-input appPhoneMask type="tel" inputmode="tel" formControlName="phone"></ion-input>
          <span slot="error">
            <app-error-view
              *ngIf="signUpFormGroup.controls.phone.touched"
              name="phone number"
              [errors]="signUpFormGroup.controls.phone.errors"
              minLengthErrorMessage="must be a valid phone number"
              patternErrorMessage="must be a valid phone number"
            ></app-error-view>
          </span>
          <span slot="helper"></span>
        </ion-item>
      </div>
      <ion-item>
        <ion-label position="floating">Email address</ion-label>
        <ion-input type="email" formControlName="email"></ion-input>
        <span slot="error">
          <app-error-view
            *ngIf="signUpFormGroup.controls.email.touched"
            name="email"
            [errors]="signUpFormGroup.controls.email.errors"
          ></app-error-view>
        </span>
        <span slot="helper"></span>
      </ion-item>
      <ion-item>
        <ion-label position="floating">Password</ion-label>
        <ion-input [clearOnEdit]="false" type="password" formControlName="password"></ion-input>
        <span slot="error">
          <app-error-view
            *ngIf="signUpFormGroup.controls.password.touched"
            name="password"
            minLengthErrorMessage="Password must be of length 6 or more"
            [errors]="signUpFormGroup.controls.password.errors"
          ></app-error-view>
        </span>
        <span slot="helper"></span>
      </ion-item>

      <ion-item>
        <ion-label position="floating">Confirm Password</ion-label>
        <ion-input [clearOnEdit]="false" type="password" formControlName="password2"></ion-input>
        <span slot="error">
          <app-error-view
            *ngIf="signUpFormGroup.controls.password2.touched"
            name="confirm password"
            [errors]="signUpFormGroup.controls.password2.errors"
          ></app-error-view>
          <div *ngIf="!passwordMatched" class="error-message pl-0">passwords do not match</div>
        </span>
        <span slot="helper"></span>
      </ion-item>

      <div class="ion-margin-vertical">
        <div class="d-flex ion-align-items-center">
          <ion-checkbox
            class="terms-and-condition-check-box"
            [ngClass]="{ 'android-checkbox': appFeaturesState.isAndroid }"
            formControlName="acceptPolicy"
          ></ion-checkbox>
          <p class="ion-no-margin">
            Agree to <u (click)="navigateToTermsAndConditions()" class="link p3">Terms and Conditions</u> and
            <u class="link p3" (click)="navigateToPrivacyPolicy()"> Privacy Policy </u>
          </p>
        </div>
        <div class="error-message-container">
          <span
            class="error-message pl-0"
            *ngIf="signUpFormGroup.controls.acceptPolicy.touched && signUpFormGroup.controls.acceptPolicy.errors"
            >Please accepts our policy to continue</span
          >
        </div>
      </div>
    </ion-list>

    <div class="d-flex ion-justify-content-between mt-10">
      <ion-button [mode]="appFeaturesState.isIos ? 'ios' : 'md'" size="default" fill="outline" (click)="closeModal()" color="dark"
        >Close</ion-button
      >
      <ion-button [mode]="appFeaturesState.isIos ? 'ios' : 'md'" size="default" (click)="insertUser()" color="primary">
        Sign Up And Login
      </ion-button>
    </div>
  </div>
</ion-content>

<div
  *ngIf="isSignUp && underIframe"
  class="ion-margin-horizontal"
  [formGroup]="signUpFormGroup"
  [ngClass]="{
    'iframe-form-container-ios': appFeaturesState.isIos,
    'iframe-form-container-android': appFeaturesState.isAndroid
  }"
>
  <ion-list mode="md">
    <ion-item>
      <ion-label position="floating">First Name</ion-label>
      <ion-input type="text" formControlName="fname"></ion-input>
      <span slot="error">
        <app-error-view
          *ngIf="signUpFormGroup.controls.fname.touched"
          name="first name"
          [errors]="signUpFormGroup.controls.fname.errors"
        ></app-error-view>
      </span>
      <span slot="helper"></span>
    </ion-item>
    <ion-item>
      <ion-label position="floating">Last Name</ion-label>
      <ion-input type="text" formControlName="lname"></ion-input>
      <span slot="error">
        <app-error-view
          *ngIf="signUpFormGroup.controls.lname.touched"
          name="last name"
          [errors]="signUpFormGroup.controls.lname.errors"
        ></app-error-view>
      </span>
      <span slot="helper"></span>
    </ion-item>
    <i class="flag-icon flag-icon-it"> </i>
    <div class="d-flex">
      <ion-item class="country-code-item">
        <ion-label position="stacked">Country Code</ion-label>
        <ion-select [compareWith]="countryCodeCompareWith" interface="popover" formControlName="countryCode">
          <ion-select-option [value]="code" *ngFor="let code of supportedCountryCodes$ | async">
            {{ code.code }}&nbsp;{{ code.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item class="phone-number-item ml-10">
        <ion-label position="floating">Phone</ion-label>
        <ion-input appPhoneMask type="tel" inputmode="tel" formControlName="phone"></ion-input>
        <span slot="error">
          <app-error-view
            *ngIf="signUpFormGroup.controls.phone.touched"
            name="phone number"
            [errors]="signUpFormGroup.controls.phone.errors"
            minLengthErrorMessage="must be a valid phone number"
            patternErrorMessage="must be a valid phone number"
          ></app-error-view>
        </span>
        <span slot="helper"></span>
      </ion-item>
    </div>
    <ion-item>
      <ion-label position="floating">Email address</ion-label>
      <ion-input type="email" formControlName="email"></ion-input>
      <span slot="error">
        <app-error-view
          *ngIf="signUpFormGroup.controls.email.touched"
          name="email"
          [errors]="signUpFormGroup.controls.email.errors"
        ></app-error-view>
      </span>
      <span slot="helper"></span>
    </ion-item>
    <ion-item>
      <ion-label position="floating">Password</ion-label>
      <ion-input [clearOnEdit]="false" type="password" formControlName="password"></ion-input>
      <span slot="error">
        <app-error-view
          *ngIf="signUpFormGroup.controls.password.touched"
          name="password"
          minLengthErrorMessage="Password must be of length 6 or more"
          [errors]="signUpFormGroup.controls.password.errors"
        ></app-error-view>
      </span>
      <span slot="helper"></span>
    </ion-item>

    <ion-item>
      <ion-label position="floating">Confirm Password</ion-label>
      <ion-input [clearOnEdit]="false" type="password" formControlName="password2"></ion-input>
      <span slot="error">
        <app-error-view
          *ngIf="signUpFormGroup.controls.password2.touched"
          name="confirm password"
          [errors]="signUpFormGroup.controls.password2.errors"
        ></app-error-view>
        <div *ngIf="!passwordMatched" class="error-message pl-0">passwords do not match</div>
      </span>
      <span slot="helper"></span>
    </ion-item>

    <div class="ion-margin-vertical">
      <div class="d-flex ion-align-items-center">
        <ion-checkbox
          class="terms-and-condition-check-box"
          [ngClass]="{ 'android-checkbox': appFeaturesState.isAndroid }"
          formControlName="acceptPolicy"
        ></ion-checkbox>
        <p class="ion-no-margin">
          Agree to <u (click)="navigateToTermsAndConditions()" class="link p3">Terms and Conditions</u> and
          <u class="link p3" (click)="navigateToPrivacyPolicy()"> Privacy Policy </u>
        </p>
      </div>
      <div class="error-message-container">
        <span
          class="error-message pl-0"
          *ngIf="signUpFormGroup.controls.acceptPolicy.touched && signUpFormGroup.controls.acceptPolicy.errors"
          >Please accepts our policy to continue</span
        >
      </div>
    </div>
  </ion-list>

  <div class="d-flex ion-justify-content-between mt-10">
    <ion-button [mode]="appFeaturesState.isIos ? 'ios' : 'md'" size="default" fill="outline" (click)="closeModal()" color="dark"
      >Close</ion-button
    >
    <ion-button [mode]="appFeaturesState.isIos ? 'ios' : 'md'" size="default" (click)="insertUser()" color="primary">
      Sign Up And Login
    </ion-button>
  </div>
</div>

<ion-content [scrollEvents]="true" (ionScrollStart)="handleIonScrollStart()" *ngIf="!isSignUp" [formGroup]="forgotPasswordFormGroup">
  <div [ngClass]="{ 'ion-margin-horizontal': !appFeaturesState.isIos, 'ion-margin-end': appFeaturesState.isIos }">
    <ion-list>
      <ng-container *ngIf="forgotPasswordStep === forgotPasswordSteps.CONTACT_METHOD">
        <ion-item>
          <ion-label position="floating"> Select a contact method </ion-label>
          <ion-select #contactMethodSelect (ionChange)="contactMethod = contactMethodSelect.value" interface="popover">
            <ion-select-option [value]="contactMethods.EMAIL"> Email </ion-select-option>
            <ion-select-option [value]="contactMethods.PHONE_NUMBER"> Phone Number </ion-select-option>
          </ion-select>
        </ion-item>

        <ion-item *ngIf="contactMethod === contactMethods.EMAIL">
          <ion-label position="floating">Email</ion-label>
          <ion-input [required]="true" type="text" formControlName="email"></ion-input>
          <span slot="error">
            <app-error-view
              *ngIf="forgotPasswordFormGroup.controls.email.touched"
              name="email"
              [errors]="forgotPasswordFormGroup.controls.email.errors"
            ></app-error-view>
          </span>
          <span slot="helper"></span>
        </ion-item>
        <div class="d-flex" *ngIf="contactMethod === contactMethods.PHONE_NUMBER">
          <ion-item class="country-code-item">
            <ion-label position="stacked">Country Code</ion-label>
            <ion-select [compareWith]="countryCodeCompareWith" interface="popover" formControlName="countryCode">
              <ion-select-option [value]="code" *ngFor="let code of supportedCountryCodes$ | async">
                {{ code.code }}&nbsp;{{ code.name }}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item class="phone-number-item ml-10">
            <ion-label position="floating"> Phone Number </ion-label>
            <ion-input [required]="true" appPhoneMask type="tel" inputmode="tel" formControlName="phoneNumber"></ion-input>
            <span slot="error">
              <app-error-view
                *ngIf="forgotPasswordFormGroup.controls.phoneNumber.touched"
                name="phone"
                [errors]="forgotPasswordFormGroup.controls.phoneNumber.errors"
                minLengthErrorMessage="must be a valid phone number"
                patternErrorMessage="must be a valid phone number"
              ></app-error-view>
            </span>
            <span slot="helper"></span>
          </ion-item>
        </div>
      </ng-container>

      <ng-container *ngIf="forgotPasswordStep === forgotPasswordSteps.VERIFY_CODE">
        <ion-item>
          <ion-label position="floating"> Verification Code </ion-label>
          <ion-input [required]="true" formControlName="verificationCode"></ion-input>
          <span slot="error">
            <app-error-view
              *ngIf="forgotPasswordFormGroup.controls.verificationCode.touched"
              name="code"
              [errors]="forgotPasswordFormGroup.controls.verificationCode.errors"
            ></app-error-view>
          </span>
          <span slot="helper"></span>
        </ion-item>
      </ng-container>

      <ng-container *ngIf="forgotPasswordStep === forgotPasswordSteps.RESET_PASSWORD">
        <app-reset-password (onReset)="closeModal()" [onlyForm]="true"></app-reset-password>
      </ng-container>
    </ion-list>
    <div
      *ngIf="forgotPasswordStep !== forgotPasswordSteps.RESET_PASSWORD"
      class="d-flex ion-justify-content-between mt-10"
      [ngClass]="{ 'ion-padding-start': appFeaturesState.isIos, 'app-action-btns': !appFeaturesState.isApp }"
    >
      <ion-button fill="outline" (click)="closeModal()" color="dark"> Close </ion-button>
      <ion-button
        *ngIf="forgotPasswordStep === forgotPasswordSteps.CONTACT_METHOD"
        [disabled]="forgotPasswordFormGroup.invalid || !contactMethod"
        (click)="resetPassword()"
        color="primary"
      >
        Reset Password
      </ion-button>

      <ion-button
        (click)="verifyPhoneNumber()"
        *ngIf="forgotPasswordStep === forgotPasswordSteps.VERIFY_CODE"
        [disabled]="forgotPasswordFormGroup.invalid"
      >
        Submit Code
      </ion-button>
    </div>
  </div>
</ion-content>
