import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import camelcaseKeys from 'camelcase-keys';
import {
  BaseResponse,
  BooleanStatus,
  IncompletePaymentResponse,
  Order,
  OrderServiceStatus,
  StripePayloadEnum,
  UserProfileImageResponse,
} from '../shared';

export enum OrdersType {
  USER = '0',
  MECHANICS = '1',
}

export enum OrderByOrders {
  PREFERRED_TIME = 'preferred-time',
}

interface StripePayload {
  stripe: StripePayloadResponse;
  message: string;
  title: string;
  type: StripePayloadEnum.NEXT_ACTION_NEEDED;
}

interface OrderServiceUploadImage {
  file_path: string;
  file_path_m: string;
  file_path_s: string;
  id: string;
}

interface GetOrdersOptions {
  start?: number;
  length?: number;
  search?: string;
  ordersType?: OrdersType;
  id?: number;
  orderServiceStatus?: OrderServiceStatus | undefined;
  orderBy?: OrderByOrders;
}

interface StripePayloadResponse {
  type: string;
  use_stripe_sdk?: {
    source: string;
    stripe_js: string;
    type: string;
  };
}

interface GetOrders extends BaseResponse {
  data: {
    date_created: string;
    fname: string;
    lname: string;
    id: string;
    orders_id: string;
    orders_lockers_number: number;
    orders_services_id: string;
    phone: string;
    preferred_time: string;
    preferred_time_unformatted: string;
    to_be_paid: number;
    order_service_status?: OrderServiceStatus;
    services_title?: string;
    cost_to_show?: string;
    cost_to_show_number?: string;
    discount_amount: string;
    parts_cost?: string;
    service_cost: string;
    tax: string;
    taxRate: string;
    extra_charge_per_km?: string;
    extra_charges?: string;
    extra_distance?: string;
    decline_reason?: string;
    order_payment_method_message: string;
    order_payment_option?: string;
    user_profile_picture?: UserProfileImageResponse;
    odometer?: string;
    mechanic?: {
      user_profile_picture?: UserProfileImageResponse;
      fname: string;
      id: string;
      lname: string;
      phone: string;
    };
    location?: {
      address: string;
      city: string;
      country: string;
      id: string;
      locations_name: string;
      state: string;
      zip: string;
      customer_name?: string;
      customer_phone?: string;
    };
    part?: {
      name: string;
      price: string;
      code: string;
      img: string;
    };
    vehicle?: {
      body_type_id: string;
      body_type_name: string;
      drive_type_id: string;
      drive_type_name: string;
      engine_base_id: string;
      id: string;
      liter: string;
      make_id: string;
      make_name: string;
      model_id: string;
      model_name: string;
      sub_model_id: string;
      sub_model_name: string;
      year: string;
      vin_number: string;
    };
    images?: OrderServiceUploadImage[];
    locker: {
      code?: string;
      number?: string;
    };
    stripe?: {
      payment1: {
        is_valid: boolean;
        next_action: StripePayloadResponse;
      };
      payment2: { is_valid: boolean; next_action: StripePayloadResponse };
    };
    incomplete_order_payments: IncompletePaymentResponse[];
    order_service_addons: {
      date_created: string;
      orders_id: string;
      orders_services_addons_id: string;
      orders_services_id: string;
      service_cost_with_markup: string;
      services_addons_id: string;
      services_addons_title: string;
    }[];
    payment_collected_by_mechanic: BooleanStatus;
  }[];
  recordsFiltered: string;
  recordsTotal: string;
}

interface UploadPictureRes extends BaseResponse {
  pictures: OrderServiceUploadImage[];
}

interface SubmitFeedbackResponse extends BaseResponse {
  googleUrl?: string;
}

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  constructor(private http: HttpClient) {}

  getOrders(options: GetOrdersOptions) {
    const start = options.start ?? '';
    const length = options.length ?? '';
    const search = options.search ?? '';
    const ordersType = options.ordersType ?? OrdersType.USER;
    const orderServiceStatus = options.orderServiceStatus ?? '';
    const orderServicesId = options.id ?? '0';

    let orderBy = '';
    // switch (options.orderBy) {
    //   case OrderByOrders.PREFERRED_TIME:
    //     orderBy = '&order[0][column]=6&order[0][dir]=desc';
    //     break;

    //   default:
    //     orderBy = '';
    //     break;
    // }

    const query = `start=${start}&length=${length}&order_service_status=${orderServiceStatus}&search[value]=${search}&orders_type=${ordersType}&${orderBy}`;
    const encodedQuery = encodeURI(query);
    return this.http
      .get<GetOrders>(`${environment.api_url}/orders_services/${orderServicesId}?${encodedQuery}`)
      .pipe(map((res) => camelcaseKeys(res, { deep: true, pascalCase: false })));
  }

  updateOrderService(orderServiceId: string, order: Partial<Order>) {
    const formData = new FormData();
    formData.set('preferred_time', order.preferredTimeUnformatted ?? '');
    formData.set('order_service_status', order.orderServiceStatus ?? '');
    if (order.declineReason) {
      formData.set('decline_reason', order.declineReason);
    }
    return this.http.post<void>(`${environment.api_url}/orders_services/${orderServiceId}`, formData);
  }

  updateOrderLockerStatus(orderId: string) {
    const formData = new FormData();

    formData.append('fields_array[0][field]', 'orders_lockers_id');
    formData.append('fields_array[0][field_value]', '0');
    return this.http.post<any>(`${environment.api_url}/orders/${orderId}`, formData);
  }

  completeOrder(orderServiceId: string, order?: Partial<Order>) {
    const formData = new FormData();
    formData.append('total_amount_collected', order?.totalAmountCollected?.toString() ?? '');
    formData.append('odometer', order?.odometer?.toString() ?? '');
    formData.append('payment_collected_by_mechanic', order?.paymentCollectedByMechanic ?? BooleanStatus.FALSE);
    return this.http.post<void>(`${environment.api_url}/orders_services/${orderServiceId}/complete-payment`, formData);
  }

  cancelOrder(orderServiceId: string, payload: { cancelReason: string }) {
    const form = new FormData();
    form.append('cancel_reason', payload.cancelReason);
    return this.http
      .post<{ order_refunded?: boolean }>(`${environment.api_url}/orders_services/${orderServiceId}/cancel-payment`, form)
      .pipe(map((res) => camelcaseKeys(res, { deep: true })));
  }

  uploadPicture(orderServiceId: number, files: Blob[]) {
    const formData = new FormData();
    for (const file of files) {
      formData.append('File[]', file);
    }
    return this.http
      .post<UploadPictureRes>(`${environment.api_url}/orders_services/${orderServiceId}/upload`, formData)
      .pipe(map((res) => camelcaseKeys(res, { deep: true })));
  }

  deletePicture(orderServiceId: string, imageId: string) {
    const form = new FormData();
    form.append('_method', 'DELETE');
    return this.http.post<void>(`${environment.api_url}/orders_services/${orderServiceId}/upload/${imageId}`, form);
  }

  getColor(status: OrderServiceStatus | undefined) {
    let color;
    switch (status) {
      case OrderServiceStatus.PENDING:
        color = 'warning';
        break;

      case OrderServiceStatus.CONFIRM:
        color = 'tertiary';
        break;

      case OrderServiceStatus.COMPLETE:
        color = 'success';
        break;

      case OrderServiceStatus.DECLINED:
        color = 'danger';
        break;
      case OrderServiceStatus.CANCELED:
        color = 'danger';
        break;
      default:
        break;
    }
    return color;
  }

  submitFeedback(ordersId: string, ordersServicesId: number, stars: number, comment: string | undefined, name: string) {
    const form = new FormData();
    form.append('orders_id', ordersId);
    form.append('orders_services_id', ordersServicesId.toString());
    form.append('stars', stars.toString());
    form.append('comments', comment ?? '');
    form.append('name', name);

    return this.http
      .post<SubmitFeedbackResponse>(`${environment.api_url}/orders/${ordersId}/submit-feedback`, form)
      .pipe(map((res) => camelcaseKeys(res, { deep: true })));
  }

  switchToPayByCard(orderServiceId: string, payload: { userCreditCardId: string }) {
    const data = new FormData();
    data.append('users_credit_cards_id', payload.userCreditCardId);
    return this.http
      .post<{
        stripe_payload1?: StripePayload;
        stripe_payload2?: StripePayload;
      }>(`${environment.api_url}/orders_services/${orderServiceId}/pay-with-card`, data)
      .pipe(map((res) => camelcaseKeys(res, { deep: true })));
  }
}
