<ion-header size="small">
  <ion-toolbar [ngClass]="{ 'pt-0': appFeaturesState.isAndroid }">
    <ion-item lines="none" class="item ion-align-items-center ion-justify-content-between">
      <div class="toolbar-img-container">
        <img src="../../../../assets/images/all-rotors-icon-120x120-px-@2x-cropped.png" width="90" />
      </div>
      <ion-label class="ion-text-center font-weight-600 mb-5"> {{ title ?? '' }} </ion-label>

      <div (click)="navigateToSelectLocation()" *ngIf="!(orderRequestInProgress$ | async); else orderInProgress">
        <ion-icon size="large" color="secondary" slot="end" name="cart-outline"> </ion-icon>
      </div>

      <ng-template #orderInProgress>
        <div (click)="continueService()">
          <ion-icon size="large" color="primary" slot="end" name="cart"></ion-icon>
        </div>
      </ng-template>
    </ion-item>
    <ion-progress-bar *ngIf="loading" type="indeterminate"></ion-progress-bar>
  </ion-toolbar>
</ion-header>
<ng-container *ngIf="appStoreBanner$ | async as appStoreBanner">
  <div *ngIf="appStoreBanner.show" class="html-background-secondary ion-justify-content-between d-flex py-5 px-8 ion-align-items-center">
    <label [innerHTML]="appStoreBanner.text"> </label>
    <ion-button (click)="closeBanner()" color="secondary" slot="end" class="ion-no-padding" size="medium" fill="clear">
      <ion-icon color="primary" size="large" name="close"></ion-icon>
    </ion-button>
  </div>
</ng-container>
