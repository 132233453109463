import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { SetLoading } from '../../core/loading.state';
import { PaymentMethod, StripeError, Token } from '@stripe/stripe-js';
import { AuthState } from '../../portal/auth.state';
import { ManagePaymentsService } from './manage-payments.service';
import { ResetState } from '../../shared';
import { AllRotorsError } from '../../shared/contracts/errors';

export interface CustomerCard {
  address: string;
  cardExpiryMonth: number;
  cardExpiryYear: number;
  cardNumber: number; // last 4
  cardType: string;
  firstName: string;
  lastName: string;
  merchantToken: string;
  zipCode: string;
  usersCreditCardsId: number;
}

interface ManagePaymentModel {
  cards: CustomerCard[];
  recordsTotal: number;
  message: string | undefined;
  stripePublishableKey: string | undefined;
  recentlyAddedCard: CustomerCard | undefined;
}

interface CardData {
  firstName: string;
  lastName: string;
  zipCode: string;
  sourceType?: string;
}

export class PaymentGetCustomerCards {
  static readonly type = '@managePaymentState.getCustomerCards';
  constructor() {}
}

export class PaymentAddCustomerCard {
  static readonly type = '@managePaymentState.addCustomerCard';
  constructor(
    public createTokenRes: {
      token?: Token;
      error?: StripeError;
    },
    public cardData: CardData
  ) {}
}

export class PaymentDeleteCustomerCard {
  static readonly type = '@managePaymentState.deleteCustomerCard';
  constructor(public customerCardId: number) {}
}

export class PaymentAttachCustomerCard {
  static readonly type = '@managePaymentState.attachCard';
  constructor(
    public paymentMethod: PaymentMethod,
    public cardData: CardData
  ) {}
}

@State<ManagePaymentModel>({
  name: 'ManagePaymentState',
  defaults: {
    cards: [],
    recordsTotal: 0,
    message: undefined,
    stripePublishableKey: undefined,
    recentlyAddedCard: undefined,
  },
})
@Injectable()
export class ManagePaymentState {
  constructor(
    private store: Store,
    private authState: AuthState,
    private managePaymentsService: ManagePaymentsService
  ) {}

  @Selector()
  private static state(s: ManagePaymentModel) {
    return s;
  }
  state$ = this.store.select(ManagePaymentState.state);

  private get snapshot() {
    return this.store.selectSnapshot(ManagePaymentState.state);
  }

  @Selector()
  static cards(state: ManagePaymentModel): CustomerCard[] {
    return state.cards;
  }
  public cards$ = this.store.select(ManagePaymentState.cards);

  @Selector()
  public static cardsTotals(state: ManagePaymentModel) {
    return state.recordsTotal;
  }

  @Selector()
  public static cardsMessage(state: ManagePaymentModel) {
    return state.message;
  }

  @Selector()
  public static stripePublishableKey(state: ManagePaymentModel) {
    return state.stripePublishableKey;
  }

  @Selector()
  public static recentlyAddedCard(state: ManagePaymentModel) {
    return state.recentlyAddedCard;
  }

  public loading$: Observable<boolean> = of(false);

  private setLoading(loading: boolean) {
    this.store.dispatch(new SetLoading(loading, this));
  }

  @Action(PaymentGetCustomerCards)
  private getCustomerPayments(ctx: StateContext<ManagePaymentModel>) {
    ctx.dispatch(new SetLoading(true, this, true, PaymentGetCustomerCards.name));
    return this.managePaymentsService.getCustomerCards(this.authState.userId).pipe(
      tap((res) => {
        ctx.patchState({
          cards: res.data,
          recordsTotal: res.recordsTotal,
          message: res.message,
          stripePublishableKey: res.stripe.stripePublishableKey,
        });
      }),
      finalize(() => ctx.dispatch(new SetLoading(false, this, true, PaymentGetCustomerCards.name)))
    );
  }

  @Action(PaymentAddCustomerCard)
  private addCard(ctx: StateContext<ManagePaymentModel>, action: PaymentAddCustomerCard) {
    const { cardData, createTokenRes } = action;
    this.setLoading(true);
    if (createTokenRes.error || !createTokenRes.token) {
      this.setLoading(false);
      throw new AllRotorsError(createTokenRes.error?.message ?? 'Unable to store card information', cardData);
    }
    return this.managePaymentsService
      .addCustomerPaymentCard(this.authState.userId, {
        merchantToken: createTokenRes.token.id,
        ...cardData,
      })
      .pipe(
        switchMap((card) => {
          ctx.patchState({
            recentlyAddedCard: card,
          });
          return ctx.dispatch(new PaymentGetCustomerCards());
        }),
        finalize(() => this.setLoading(false))
      );
  }

  @Action(PaymentDeleteCustomerCard)
  private deleteCard(ctx: StateContext<ManagePaymentModel>, action: PaymentDeleteCustomerCard) {
    const { customerCardId } = action;
    this.setLoading(true);
    return this.managePaymentsService.deleteCustomerPaymentCard(this.authState.userId, customerCardId).pipe(
      tap((card) => {
        if (card.message !== ' CC is deleted; ') {
          return;
        }
        const cards = this.snapshot.cards;
        ctx.patchState({
          cards: cards.filter((c) => c.usersCreditCardsId !== customerCardId),
          recordsTotal: ctx.getState().recordsTotal ? ctx.getState().recordsTotal - 1 : 0,
        });
      }),
      finalize(() => this.setLoading(false))
    );
  }

  @Action(PaymentAttachCustomerCard)
  attachCustomerCard(ctx: StateContext<ManagePaymentModel>, action: PaymentAttachCustomerCard) {
    this.setLoading(true);
    return this.managePaymentsService
      .attachPaymentMethod(this.authState.userId, {
        merchantToken: action.paymentMethod.id,
        ...action.cardData,
      })
      .pipe(
        switchMap((card) => {
          ctx.patchState({
            recentlyAddedCard: card,
          });
          return ctx.dispatch(new PaymentGetCustomerCards());
        }),
        finalize(() => this.setLoading(false))
      );
  }

  @Action(ResetState)
  resetState(ctx: StateContext<ManagePaymentModel>, action: ResetState) {
    ctx.setState({
      cards: [],
      recordsTotal: 0,
      message: undefined,
      stripePublishableKey: undefined,
      recentlyAddedCard: undefined,
    });
  }
}
