import { Component, OnInit, ChangeDetectionStrategy, ViewChild, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { LoadingState } from '../../core/loading.state';
import { AppFeaturesState } from '../../portal/app-feature.state';
import { PrivacyPolicyComponent } from '../privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from '../terms-and-conditions/terms-and-conditions.component';

export enum PolicyViewType {
  TERMS_AND_CONDITION = 'termsAndCondition',
  PRIVACY_POLICIES = 'privacyPolicies',
}

@Component({
  selector: 'app-policy-view-component',
  templateUrl: './policy-view-component.component.html',
  styleUrls: ['./policy-view-component.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PolicyViewComponentComponent implements OnInit {
  @Input() viewType: PolicyViewType;
  policyViewType = PolicyViewType;

  @ViewChild('termsAndConditions') termsAndConditions: TermsAndConditionsComponent;
  @ViewChild('privacyPolicy') privacyPolicy: PrivacyPolicyComponent;

  @Select(LoadingState.isLoading(AppFeaturesState.name)) loading$: Observable<boolean>;

  constructor(private modalController: ModalController) {}

  ngOnInit(): void {}

  ionViewWillEnter() {
    if (this.termsAndConditions) {
      this.termsAndConditions.ionViewWillEnter();
    }

    if (this.privacyPolicy) {
      this.privacyPolicy.ionViewWillEnter();
    }
  }

  async closeModal() {
    await this.modalController.dismiss();
  }
}
