import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaymentIntent } from '@stripe/stripe-js';
import camelcaseKeys from 'camelcase-keys';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { BaseResponse } from '../../shared';
import { CustomerCard } from './manage-payment.state';

interface DeleteCardResponse {
  deleted: boolean;
  id: string;
}

interface GetCustomerCardRes extends BaseResponse {
  data: {
    address: string;
    card_expiry_month: number;
    card_expiry_year: number;
    card_number: number; // last 4
    card_type: string;
    first_name: string;
    last_name: string;
    merchant_token: string;
    zip_code: string;
    users_credit_cards_id: number;
  }[];
  stripe: {
    stripe_publishable_key: string;
  };
  recordsTotal: number;
}

interface CreateCardPayload {
  merchantToken: string;
  firstName: string;
  lastName: string;
  zipCode: string;
  sourceType?: string;
}

interface CreatePaymentIntentResponse extends BaseResponse {
  payment_intent: PaymentIntent;
}

@Injectable({
  providedIn: 'root',
})
export class ManagePaymentsService {
  constructor(private http: HttpClient) {}

  getCustomerCards(userId: number) {
    return this.http
      .get<GetCustomerCardRes>(`${environment.api_url}/users/${userId}/users_credit_cards`)
      .pipe(map((res) => camelcaseKeys(res, { deep: true })));
  }

  addCustomerPaymentCard(userId: number, payload: CreateCardPayload) {
    const formData = new FormData();
    formData.append('merchant_token', payload.merchantToken);
    formData.append('first_name', payload.firstName);
    formData.append('last_name', payload.lastName);
    formData.append('zip_code', payload.zipCode);
    return this.http.post<{ data: CustomerCard }>(`${environment.api_url}/users/${userId}/users_credit_cards`, formData).pipe(
      map((res) => camelcaseKeys(res, { deep: true })),
      map((res) => res.data)
    );
  }

  attachPaymentMethod(userId: number, payload: CreateCardPayload) {
    const formData = new FormData();
    formData.append('payment_method_token', payload.merchantToken);
    formData.append('first_name', payload.firstName);
    formData.append('last_name', payload.lastName);
    formData.append('zip_code', payload.zipCode);
    formData.append('source_type', payload.sourceType ?? '');
    return this.http.post<{ data: CustomerCard }>(`${environment.api_url}/users/${userId}/users_credit_cards/attach`, formData).pipe(
      map((res) => camelcaseKeys(res, { deep: true })),
      map((res) => res.data)
    );
  }

  deleteCustomerPaymentCard(userId: number, customerCardId: number) {
    const form = new FormData();
    form.append('_method', 'delete');
    return this.http.post<BaseResponse>(`${environment.api_url}/users/${userId}/users_credit_cards/${customerCardId}`, form);
  }

  createPaymentIntent(userId: number) {
    return this.http.post<CreatePaymentIntentResponse>(
      `${environment.api_url}/users/${userId}/users_credit_cards/create-payment-intent`,
      null
    );
  }

  getCardIcon(cardType: string): string | undefined {
    if (cardType === 'Visa') {
      return '../../../assets/images/cc-visa-brands.svg';
    } else if (cardType === 'MasterCard') {
      return '../../../assets/images/cc-mastercard-brands.svg';
    } else if (cardType === 'American Express') {
      return '../../../assets/images/cc-amex-brands.svg';
    }

    return undefined;
  }
}
