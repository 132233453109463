import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ToastService } from '../toast.service';
import { AppFeaturesState } from '../../portal/app-feature.state';
import { Store } from '@ngxs/store';
import { InsideNavigateToHome } from '../../portal/inside.state';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponent {
  constructor(private toastService: ToastService, private appFeaturesState: AppFeaturesState, private store: Store) {}

  async ionViewWillEnter() {
    await this.toastService.showToast(this.appFeaturesState.isApp, {
      message: 'Page Not Found',
    });
    this.store.dispatch(new InsideNavigateToHome());
  }
}
