import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AppCashPaymentOptions,
  BaseResponse,
  CountryCode,
  IncompletePaymentResponse,
  PhoneStatus,
  UserAuthenticationPayload,
  UserProfileImageResponse,
} from '../shared';
import { environment } from '../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, Router } from '@angular/router';
import camelcaseKeys from 'camelcase-keys';

export interface UserAuthenticationResult {
  message: string;
  remember?: string;
  status: number;
  token?: string;
  usersId?: number;
}

interface UserUploadPictureRes extends BaseResponse {
  pictures: UserProfileImageResponse[];
}

interface UserCheckToken {
  data: {
    email: string;
    fname: string;
    lname: string;
    phone: string;
    phoneCountryCode: string | undefined;
    phoneStatus: PhoneStatus;
    mechanic: number;
    usersId: string;
    stripeCustomerId: string;
    invitationToken: string;
  };
  allowAppCashPayment: AppCashPaymentOptions;
  message: string;
  status: number;
}

export interface UserCreationPayload {
  fname: string;
  lname: string;
  phone: string;
  number: number;
  email: string;
  password: string;
  password2: string;
  addStripeCustomerId: number; // being used as a boolean 1 or 0
  fieldsToUpdate: string[];
  invitationToken: string | undefined;
  countryCode: CountryCode;
}

interface User {
  email: string;
  emailNote: string;
  fname: string;
  lname: string;
  phone: string;
  last_service?: { users_locations_id: string; users_vehicles_id: string };
  incomplete_order_payments: IncompletePaymentResponse[];
  user_profile_picture?: UserProfileImageResponse;
}

interface UserCreationRes extends BaseResponse {
  data?: {
    token: string;
    userId: number;
    phone: string;
  };
}

export interface UserDetailsResponse extends BaseResponse {
  data: User[];
}

export interface ResetPasswordPayload {
  password1: string;
  password2: string;
}

interface UpdateUserResponse extends BaseResponse {
  stripeCustomerId?: string;
}

interface VerifyPhoneRes extends BaseResponse {
  data: {
    phoneStatus: PhoneStatus;
  };
}

interface SendForgotPasswordResponse extends BaseResponse {
  token: string;
  users_id: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  authenticateUser(userPayload: UserAuthenticationPayload): Observable<UserAuthenticationResult> {
    const { email, password, tokenType, remember } = userPayload;
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    formData.append('token_type', tokenType?.toString());
    formData.append('remember', remember?.toString());
    return this.http.post<UserAuthenticationResult>(`${environment.api_url}/users-login`, formData).pipe(
      map((res) => camelcaseKeys(res, { deep: true })),
      tap((data) => {
        this.setSession(data);
      })
    );
  }

  private setSession(authResult: UserAuthenticationResult) {
    if (authResult.token && authResult.usersId) {
      localStorage.setItem('allrotors_token', authResult.token);
      localStorage.setItem('allrotors_users_id', authResult.usersId.toString());
      this.cookieService.set('Token', authResult.token);
      this.cookieService.set('users_id', authResult.usersId.toString());
    } else {
      throw new Error('missing token or user id');
    }
  }

  public getToken() {
    return localStorage.getItem('allrotors_token');
  }

  checkToken(): Observable<UserCheckToken> {
    return this.http.get<UserCheckToken>(`${environment.api_url}/check-token`).pipe(map((res) => camelcaseKeys(res, { deep: true })));
  }

  logoutUser() {
    localStorage.clear();
    this.cookieService.deleteAll();
  }

  createUser(userCreationPayload: UserCreationPayload): Observable<UserCreationRes> {
    const formData = new FormData();
    formData.append('fname', userCreationPayload.fname?.toString() || '');
    formData.append('lname', userCreationPayload.lname?.toString() || '');
    formData.append('phone', userCreationPayload.phone?.toString() || '');
    formData.append('number', userCreationPayload.number?.toString() || '');
    formData.append('email', userCreationPayload.email?.toString() || '');
    formData.append('password', userCreationPayload.password?.toString() || '');
    formData.append('password2', userCreationPayload.password2?.toString() || '');
    formData.append('invitation_token', userCreationPayload.invitationToken || '');
    formData.append('phone_country_code', userCreationPayload.countryCode.code);
    formData.append('country', userCreationPayload.countryCode.name);
    for (const val of userCreationPayload.fieldsToUpdate || []) {
      formData.append(`fields_to_update[]`, val);
    }
    formData.append(`fields_to_update[]`, 'country');
    return this.http.post<UserCreationRes>(`${environment.api_url}/users/0`, formData).pipe(
      map((res) => camelcaseKeys(res, { deep: true })),
      tap((res) => {
        if (!res.data) {
          return;
        }
        this.setSession({ message: '', status: 1, token: res.data.token, usersId: res.data.userId });
      })
    );
  }

  updateUser(userId: number, userPayload: Partial<UserCreationPayload>, token?: string) {
    const formData = new FormData();
    formData.append('fname', userPayload.fname?.toString() || '');
    formData.append('lname', userPayload.lname?.toString() || '');
    formData.append('phone', userPayload.phone?.toString() || '');
    formData.append('number', userPayload.number?.toString() || '');
    formData.append('email', userPayload.email?.toString() || '');
    formData.append('password', userPayload.password?.toString() || '');
    formData.append('password2', userPayload.password2?.toString() || '');
    formData.append('add_stripe_customer_id', userPayload.addStripeCustomerId?.toString() || '');
    formData.append('invitation_token', userPayload.invitationToken?.toString() || '');
    formData.append('phone_country_code', userPayload.countryCode?.code || '');
    formData.append('country', userPayload.countryCode?.name ?? '');
    for (const val of userPayload.fieldsToUpdate || []) {
      formData.append(`fields_to_update[]`, val);
    }
    formData.append(`fields_to_update[]`, 'country');
    return this.http.post<UpdateUserResponse>(`${environment.api_url}/users/${userId}`, formData, { headers: { Token: token ?? '' } }).pipe(
      map((res) => camelcaseKeys(res, { deep: true })),
      map((data) => {
        return data;
      })
    );
  }

  sendForgotPasswordLink(options: {
    email?: string;
    phoneNumber?: string;
    phoneVerificationCode?: string;
    phoneCountryCode?: CountryCode;
  }) {
    const data = new FormData();
    data.append('email', options.email ?? '');
    data.append('phone_number', options.phoneNumber ?? '');
    data.append('phone_verification_code', options.phoneVerificationCode ?? '');
    data.append('phone_country_code', options.phoneCountryCode?.code ?? '');
    return this.http.post<SendForgotPasswordResponse>(`${environment.api_url}/users/0/forgot-password`, data).pipe(
      map((data) => {
        if (data.token && data.users_id) {
          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: { code: data.token, userId: data.users_id },
          });
        }
        return undefined;
      })
    );
  }

  resetPassword(resetPasswordPayload: ResetPasswordPayload) {
    const { code, userId } = this.activatedRoute.snapshot.queryParams;
    const data = new FormData();
    data.append('code', code);
    for (const [key, value] of Object.entries(resetPasswordPayload)) {
      data.append(key, value);
    }
    return this.http.post<BaseResponse>(`${environment.api_url}/users/${userId}/forgot-password`, data);
  }

  getUser(userId: number) {
    return this.http.get<UserDetailsResponse>(`${environment.api_url}/users/${userId}`).pipe(
      map((res) => camelcaseKeys(res, { deep: true })),
      map((res) => res.data[0])
    );
  }

  requestPhoneVerification(userId: number, token?: string) {
    return this.http
      .post<BaseResponse>(`${environment.api_url}/users/${userId}/request-phone-verification`, {}, { headers: { Token: token ?? '' } })
      .pipe(map((res) => camelcaseKeys(res, { deep: true })));
  }

  verifyPhoneCode(userId: number, verificationCode: number, token: string) {
    const formData = new FormData();
    formData.append('verification_code', verificationCode.toString());
    return this.http
      .post<VerifyPhoneRes>(`${environment.api_url}/users/${userId}/verify-phone`, formData, { headers: { Token: token } })
      .pipe(map((res) => camelcaseKeys(res, { deep: true })));
  }

  deleteAccount(userId: number, payload: { reason: string | undefined }) {
    const formData = new FormData();
    formData.append('reason', payload.reason?.toString() || '');
    return this.http.post<BaseResponse>(`${environment.api_url}/users/${userId}/delete-account`, formData);
  }

  uploadProfilePicture(userId: number, files: Blob[]) {
    const formData = new FormData();
    for (const file of files) {
      formData.append('File[]', file);
    }
    return this.http
      .post<UserUploadPictureRes>(`${environment.api_url}/users/${userId}/upload-picture`, formData)
      .pipe(map((res) => camelcaseKeys(res, { deep: true })));
  }
}
