import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { SetLoading } from '../../core/loading.state';
import { ResetState } from '../../shared';
import { BaseVehiclePayload, VehicleMake, VehicleModel, VehicleService } from './vehicle.service';

interface VehicleSubModelsBase {
  [key: string]: BaseVehiclePayload;
}

interface VehicleStateModel {
  vehicleMake: VehicleMake[];
  vehicleModel: VehicleModel[];
  vehicleSubModel: BaseVehiclePayload;
  vehicleBody: VehicleSubModelsBase;
  vehicleDriveType: VehicleSubModelsBase;
  vehicleEngine: VehicleSubModelsBase;

  selectedVehicleSubModelId: string | undefined;
}

export class VehicleGetMakes {
  static readonly type = '@vehicleState.getVehicleMakes';
  constructor(public year: string, public option?: { resetDependentValues: boolean }) {}
}

export class VehicleGetModels {
  static readonly type = '@vehicleState.getVehicleModel';
  constructor(public vehicleMakeId: string, public year: string, public option?: { resetDependentValues: boolean }) {}
}

export class VehicleGetSubModelsAndDetails {
  static readonly type = '@vehicleState.getVehicleSubModelAndDetails';
  constructor(
    public vehicleMakeId: string,
    public year: string,
    public vehicleModelId: string,
    public option?: { resetDependentValues: boolean }
  ) {}
}

export class VehiclesPatchState {
  static readonly type = '@vehicleState.patchState';
  constructor(public state: Partial<VehicleStateModel>) {}
}

@State<VehicleStateModel>({
  name: 'VehicleState',
  defaults: {
    vehicleMake: [],
    vehicleModel: [],
    vehicleSubModel: {},
    vehicleBody: {},
    vehicleDriveType: {},
    vehicleEngine: {},

    selectedVehicleSubModelId: undefined,
  },
})
@Injectable()
export class VehicleState {
  constructor(private vehicleService: VehicleService, private Store: Store) {}

  @Selector()
  static state(state: VehicleStateModel) {
    return state;
  }
  private state$ = this.Store.select(VehicleState.state);

  @Selector()
  static selectedVehicleSubModelId(state: VehicleStateModel) {
    return state.selectedVehicleSubModelId;
  }
  @Selector()
  static vehicleBody(state: VehicleStateModel) {
    return state.vehicleBody;
  }
  public vehicleBody$: Observable<VehicleSubModelsBase> = this.state$.pipe(map((s) => s.vehicleBody));
  @Selector([VehicleState.vehicleBody, VehicleState.selectedVehicleSubModelId])
  static vehicleBodyForSelectedSubModel(vehicleBody: VehicleSubModelsBase, selectedVehicleSubModelId: string | undefined) {
    if (!vehicleBody || !selectedVehicleSubModelId || !vehicleBody[selectedVehicleSubModelId]) {
      return {};
    }
    return vehicleBody[selectedVehicleSubModelId];
  }

  @Selector()
  static vehicleEngine(state: VehicleStateModel) {
    return state.vehicleEngine;
  }
  public vehicleEngine$: Observable<VehicleSubModelsBase> = this.state$.pipe(map((s) => s.vehicleEngine));
  @Selector([VehicleState.vehicleEngine, VehicleState.selectedVehicleSubModelId])
  static vehicleEngineForSelectedSubModel(vehicleEngine: VehicleSubModelsBase, selectedVehicleSubModelId: string | number) {
    if (!vehicleEngine || !selectedVehicleSubModelId || !vehicleEngine[selectedVehicleSubModelId]) {
      return {};
    }
    return vehicleEngine[selectedVehicleSubModelId];
  }

  @Selector()
  static vehicleDriveType(state: VehicleStateModel) {
    return state.vehicleDriveType;
  }
  @Selector([VehicleState.vehicleDriveType, VehicleState.selectedVehicleSubModelId])
  static vehicleDriveTypeForSelectedSubModel(vehicleDriveType: VehicleSubModelsBase, selectedVehicleSubModelId: string | number) {
    if (!vehicleDriveType || !selectedVehicleSubModelId || !vehicleDriveType[selectedVehicleSubModelId]) {
      return {};
    }
    return vehicleDriveType[selectedVehicleSubModelId];
  }

  public vehicleMakes$: Observable<VehicleMake[]> = this.state$.pipe(map((s) => s.vehicleMake));
  public vehicleModel$: Observable<VehicleModel[]> = this.state$.pipe(map((s) => s.vehicleModel));
  public vehicleSubModel$: Observable<BaseVehiclePayload> = this.state$.pipe(map((s) => s.vehicleSubModel));

  public vehicleDriveType$: Observable<VehicleSubModelsBase> = this.state$.pipe(map((s) => s.vehicleDriveType));

  private setLoading(loading: boolean) {
    this.Store.dispatch(new SetLoading(loading, this));
  }

  @Action(VehicleGetMakes)
  private getVehicleMakes(ctx: StateContext<VehicleStateModel>, action: VehicleGetMakes) {
    this.setLoading(true);
    return this.vehicleService.getVehicleMakes(action.year).pipe(
      tap((vehicleMake) => {
        let stateValues: Partial<VehicleStateModel> = {};
        if (action.option?.resetDependentValues) {
          stateValues = {
            vehicleModel: [],
            vehicleSubModel: {},
            vehicleBody: {},
            vehicleDriveType: {},
            vehicleEngine: {},
            selectedVehicleSubModelId: undefined,
          };
        }
        ctx.patchState({
          ...stateValues,
          vehicleMake,
        });
      }),
      finalize(() => this.setLoading(false))
    );
  }

  @Action(VehicleGetModels)
  private getVehicleModel(ctx: StateContext<VehicleStateModel>, action: VehicleGetModels) {
    this.setLoading(true);
    return this.vehicleService.getVehicleModel(action.year, action.vehicleMakeId).pipe(
      tap((vehicleModel) => {
        let stateValues: Partial<VehicleStateModel> = {};
        if (action.option?.resetDependentValues) {
          stateValues = {
            vehicleSubModel: {},
            vehicleBody: {},
            vehicleDriveType: {},
            vehicleEngine: {},
            selectedVehicleSubModelId: undefined,
          };
        }
        ctx.patchState({
          ...stateValues,
          vehicleModel,
        });
      }),
      finalize(() => this.setLoading(false))
    );
  }

  @Action(VehicleGetSubModelsAndDetails)
  private getVehicleSubModelAndDetails(ctx: StateContext<VehicleStateModel>, action: VehicleGetSubModelsAndDetails) {
    this.setLoading(true);
    return this.vehicleService.getVehicleSubModelAndDetails(action.year, action.vehicleMakeId, action.vehicleModelId).pipe(
      map((response) => {
        const { arr_body: vehicleBody, arr_drive_type: vehicleDriveType, arr_engine: vehicleEngine, arr_model } = response;
        let stateValues: Partial<VehicleStateModel> = {};
        if (action.option?.resetDependentValues) {
          stateValues = {
            selectedVehicleSubModelId: undefined,
          };
        }
        const details = {
          ...stateValues,
          vehicleSubModel: arr_model,
          vehicleBody,
          vehicleDriveType,
          vehicleEngine,
        };
        ctx.patchState(details);
        return details;
      }),
      finalize(() => this.setLoading(false))
    );
  }

  @Action(VehiclesPatchState)
  private patchState(ctx: StateContext<VehicleStateModel>, action: VehiclesPatchState) {
    ctx.patchState(action.state);
  }

  @Action(ResetState)
  resetState(ctx: StateContext<VehicleStateModel>, action: ResetState) {
    ctx.setState({
      vehicleMake: [],
      vehicleModel: [],
      vehicleSubModel: {},
      vehicleBody: {},
      vehicleDriveType: {},
      vehicleEngine: {},

      selectedVehicleSubModelId: undefined,
    });
  }
}
