export const environment = {
  production: true,
  embedSourceWhitelisted: false,
  forceApp: false,
  inDev: false,
  appUrl: 'https://app.allrotors.com',
  stripePublishableKey: 'pk_test_Yfm3JBIXnKEJhfUy0yVxOolt00oNQqOUgX',
  plugins: [],
  api_url: 'https://www.allrotors.com/api',
  serverHost: 'https://www.allrotors.com',
  firebase: {
    firebaseConfig: {
      apiKey: 'AIzaSyBzomDUvXDUfHqsCXUwT2DoVICCVoyCB9k',
      authDomain: 'allrotors.firebaseapp.com',
      projectId: 'allrotors',
      storageBucket: 'allrotors.appspot.com',
      messagingSenderId: '894317547964',
      appId: '1:894317547964:web:7a3fda98b5714916f91bb9',
    },
    vapidKey: 'BCXna3JgPqfOxT_4I9-xKzZzHdIIbWCOXRzyg0Y-4GwKlUF0qJUMzDPY91RICpOtJAbaxk6hM1xCTEf-gmsDqL8',
  },
  googleAnalytics: {
    webApp: {
      measurementId: 'G-6VDFMNH5NR',
    },
    android: {
      measurementId: 'G-LF5H4SG5YW',
    },
    ios: {
      measurementId: 'G-R8WQVWNPCZ',
    },
  },
};
