import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { AppFeaturesState, AppNavigateToStore } from '../../portal/app-feature.state';

@Component({
  selector: 'app-update-modal',
  templateUrl: './app-update-modal.component.html',
  styleUrls: ['./app-update-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppUpdateModalComponent {
  appUpdateBanner$ = this.store.select(AppFeaturesState.appUpdateBanner);
  constructor(private store: Store, private appFeaturesState: AppFeaturesState) {}

  downloadApp() {
    this.store.dispatch(new AppNavigateToStore());
  }
}
