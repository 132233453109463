import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { AlertController, NavController } from '@ionic/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { tap, debounceTime } from 'rxjs/operators';
import { AppFeaturesState } from '../portal/app-feature.state';
import { AuthResetPassword, AuthState } from '../portal/auth.state';
import { GoogleAnalyticsService, LogEventType } from '../core/google-analytics.service';

@UntilDestroy()
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordComponent implements OnInit {
  @Input() onlyForm: boolean;

  @Output() onReset = new EventEmitter<void>();
  resetPasswordFormGroup = this.buildFormGroup();

  passwordMatched: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private navController: NavController,
    private alertController: AlertController,
    private store: Store,
    public appFeaturesState: AppFeaturesState,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}

  ngOnInit(): void {}

  async handleIonScrollStart() {
    await this.googleAnalyticsService.logEvent({ name: LogEventType.SCROLL });
  }

  private buildFormGroup() {
    const passwordFc = this.fb.control('', {
      validators: [Validators.required, Validators.minLength(6)],
    });
    const passwordConfirmationFc = this.fb.control('', {
      validators: [this.confirmPasswordValidator.bind(this, passwordFc)],
    });
    passwordFc.valueChanges.pipe(untilDestroyed(this), debounceTime(50)).subscribe(() => {
      passwordConfirmationFc.updateValueAndValidity({
        emitEvent: false,
        onlySelf: true,
      });
    });
    const form = this.fb.group({
      password: passwordFc,
      password2: passwordConfirmationFc,
    });
    return form;
  }

  private confirmPasswordValidator(passwordFc: AbstractControl, confirmPasswordFc: AbstractControl): ValidationErrors | null {
    const password = passwordFc.value;
    const confirmPassword = confirmPasswordFc.value;
    const error = confirmPassword === password ? null : { doNotMatch: true };
    if (error) {
      this.passwordMatched = false;
    } else {
      this.passwordMatched = true;
    }
    return error;
  }

  resetPassword() {
    this.store
      .dispatch(new AuthResetPassword(this.resetPasswordFormGroup.value))
      .pipe(
        tap(async () => {
          const alert = await this.alertController.create({
            subHeader: 'Password reset',
            message: 'New password is saved, please login',

            buttons: ['Ok'],
          });
          await alert.present();
          await alert.onDidDismiss();
          this.onReset.next();
          await this.navController.navigateRoot('/home-page');
        })
      )
      .subscribe();
  }
}
