import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from './loading/loading.component';
import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LocationAvailablePipe } from './location-available.pipe';
import { AppUpdateModalComponent } from './update-modal/app-update-modal.component';
import { ReviewFormComponent } from './review-form/review-form.component';
import { ReviewFormState } from './review-form/review-form.state';
import { NgxsModule } from '@ngxs/store';
import { FormsModule } from '@angular/forms';
import { DecimalPlacesDirective } from './decimal-places.directive';

@NgModule({
  declarations: [
    LoadingComponent,
    HeaderComponent,
    FooterComponent,
    LocationAvailablePipe,
    AppUpdateModalComponent,
    ReviewFormComponent,
    DecimalPlacesDirective,
  ],
  imports: [CommonModule, IonicModule, FormsModule, NgxsModule.forFeature([ReviewFormState])],
  exports: [LoadingComponent, HeaderComponent, FooterComponent, LocationAvailablePipe, DecimalPlacesDirective],
})
export class SharedComponentsModule {}
