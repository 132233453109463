import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxsModule } from '@ngxs/store';
import { GoogleMapsState } from './google-map.state';
import { GoogleMapsAutocompleteDirective } from './google-maps-autocomplete.component';

@NgModule({
  declarations: [GoogleMapsAutocompleteDirective],
  imports: [CommonModule, ReactiveFormsModule, FormsModule, NgxsModule.forFeature([GoogleMapsState])],
  exports: [GoogleMapsAutocompleteDirective],
})
export class GoogleMapsAutocompleteModule {}
