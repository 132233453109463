import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { UserDetailsState } from './userDetails.state';
import { AppFeaturesState } from './app-feature.state';
import { AuthState } from './auth.state';
import { AuthService } from './auth.service';
import { VehicleState } from './vehicle/vehicle.state';
import { OrdersState } from './orders.state';
import { PhoneVerificationModule } from '../inside/shared/dialog/phone-verification/phone-verification.module';
import { InsideState } from './inside.state';
import { GoogleMapsAutocompleteModule } from '../inside/shared/google-maps-autocomplete/google-maps-autocomplete.module';
import { NextActionState } from './next-action.state';
import { SignupForgotPasswordModule } from '../shared-standalone/signup-forgot-password/signup-forgot-password.module';
import { DataStore } from './data-house/data-store.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    PhoneVerificationModule,
    GoogleMapsAutocompleteModule,
    SignupForgotPasswordModule,
    NgxsModule.forFeature([
      UserDetailsState,
      AppFeaturesState,
      AuthState,
      VehicleState,
      OrdersState,
      InsideState,
      NextActionState,
      DataStore,
    ]),
  ],
  providers: [AuthService],
})
export class PortalModule {}
