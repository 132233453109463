import { Injectable } from '@angular/core';
import { FirebaseAnalytics, LogEventOptions } from '@capacitor-firebase/analytics';
import { AppFeaturesService } from '../portal/app-features.service';
import { ActivationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs';

export enum LogEventType {
  APP_OPEN = 'app_open',
  PAGE_VIEW = 'page_view',
  SCROLL = 'scroll',
  CLICK = 'click',
}

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor(
    private appFeaturesService: AppFeaturesService,
    private router: Router
  ) {
    if (!this.appFeaturesService.isNativeIos) {
      return;
    }

    this.setEnabled(true).then(async () => {
      await this.setTimeoutDuration();
      await this.logEvent({ name: LogEventType.APP_OPEN });
    });
    this.setupRouteMonitor();
    this.setUpClickEventMonitor();
  }

  setupRouteMonitor() {
    if (!this.appFeaturesService.isNativeIos) {
      return;
    }
    this.router.events
      .pipe(
        filter((event) => {
          if (event instanceof ActivationEnd && event.snapshot.data.title) {
            return true;
          }
          return false;
        }),
        map(async (event) => {
          if (!(event instanceof ActivationEnd)) {
            return '';
          }
          const title = event.snapshot.data.title;
          await this.setCurrentScreen(title);
          await this.logEvent({
            name: LogEventType.PAGE_VIEW,
            params: {
              page_location: window.location.href,
              page_path: window.location.pathname,
              page_title: title,
            },
          });
          return title;
        })
      )
      .subscribe();
  }

  setUpClickEventMonitor() {
    if (!this.appFeaturesService.isNativeIos) {
      return;
    }
    document.querySelector('body')?.addEventListener('click', async () => {
      await this.logEvent({ name: LogEventType.CLICK });
    });
  }

  async setUserId(userId: string) {
    if (!this.appFeaturesService.isNativeIos) {
      return;
    }
    await FirebaseAnalytics.setUserId({
      userId: userId,
    });
  }

  async setUserProperty(property: { key: string; value: string }) {
    if (!this.appFeaturesService.isNativeIos) {
      return;
    }
    await FirebaseAnalytics.setUserProperty(property);
  }

  async setCurrentScreen(screenName: string) {
    if (!this.appFeaturesService.isNativeIos) {
      return;
    }
    await FirebaseAnalytics.setCurrentScreen({
      screenName,
      screenClassOverride: null,
    });
  }

  async logEvent(option: LogEventOptions) {
    if (!this.appFeaturesService.isNativeIos) {
      return;
    }
    await FirebaseAnalytics.logEvent(option);
  }

  async setTimeoutDuration() {
    if (!this.appFeaturesService.isNativeIos) {
      return;
    }
    await FirebaseAnalytics.setSessionTimeoutDuration({
      duration: 120,
    });
  }

  async setEnabled(enabled: boolean) {
    if (!this.appFeaturesService.isNativeIos) {
      return;
    }
    await FirebaseAnalytics.setEnabled({
      enabled,
    });
  }

  async isEnabled() {
    if (!this.appFeaturesService.isNativeIos) {
      return;
    }
    const { enabled } = await FirebaseAnalytics.isEnabled();
    return enabled;
  }

  async resetAnalyticsData() {
    if (!this.appFeaturesService.isNativeIos) {
      return;
    }
    await FirebaseAnalytics.resetAnalyticsData();
  }
}
