import { Injectable } from '@angular/core';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { Action, NgxsAfterBootstrap, Selector, State, StateContext, Store } from '@ngxs/store';
import { EMPTY, from, of, switchMap, tap } from 'rxjs';
import { AppFeaturesState } from '../portal/app-feature.state';
import { AppFeaturesService } from '../portal/app-features.service';
import { ResetState } from '../shared';
import { FirebaseService } from './firebase.service';

export class FireBaseInitialize {
  static readonly type = '@firebaseState.initialize';
}

interface FirebaseStateModel {
  deviceToken: string | undefined;
  initialized: boolean;
}

@State<FirebaseStateModel>({
  name: 'firebaseState',
  defaults: {
    deviceToken: undefined,
    initialized: false,
  },
})
@Injectable()
export class FirebaseState implements NgxsAfterBootstrap {
  constructor(private firebaseService: FirebaseService, private appFeaturesState: AppFeaturesState) {}

  @Selector()
  static deviceToken(state: FirebaseStateModel) {
    return state.deviceToken;
  }

  ngxsAfterBootstrap(ctx?: StateContext<FirebaseStateModel> | undefined): void {
    const isApp = this.appFeaturesState.isNativeIos || this.appFeaturesState.isNativeAndroid;
    if (!isApp) {
      // this.firebaseService
      //   .requestPermission()
      //   .pipe(
      //     tap((deviceToken) => {
      //       if (ctx) {
      //         ctx.patchState({ deviceToken });
      //         this.firebaseService.listen();
      //       }
      //     })
      //   )
      //   .subscribe();
    } else {
      PushNotifications.addListener('registration', (token: Token) => {
        console.log('Push registration success, token: ' + token.value);
        ctx?.patchState({ deviceToken: token.value });
      });
      const permissionStatus$ = from(PushNotifications.requestPermissions());
      permissionStatus$
        .pipe(
          switchMap((pushNotificationPermissionState) => {
            if (pushNotificationPermissionState.receive !== 'granted') {
              console.log('notificationState', pushNotificationPermissionState.receive);
              return of(undefined);
            }
            return from(PushNotifications.register());
          })
        )
        .subscribe((res) => {
          console.log('res', res);
        });
    }
  }

  @Action(ResetState)
  resetState(ctx: StateContext<FirebaseStateModel>, action: ResetState) {
    ctx.setState({
      deviceToken: undefined,
      initialized: false,
    });
  }

  @Action(FireBaseInitialize)
  initializeFirebase(ctx: StateContext<FirebaseStateModel>) {
    const state = ctx.getState();
    if (state.initialized) {
      return;
    }
    this.firebaseService.initializeFirebase();
    ctx.patchState({
      initialized: true,
    });
  }
}
