import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { format } from 'date-fns';
import { combineLatest, EMPTY, Observable } from 'rxjs';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { SetLoading } from '../../core/loading.state';
import { ToastService } from '../../core/toast.service';
import { AppFeaturesState } from '../../portal/app-feature.state';
import { AuthState } from '../../portal/auth.state';
import { CouponDetails, ErrorMessage, ResetState, ScheduleServiceComputedPriceDetail, UserLocation, Vehicle } from '../../shared';
import { CouponsService } from './coupons.service';
import {
  ScheduleServicesService,
  VehicleServices,
  VehicleSubServices,
  ServicePartType,
  SubServiceAddon,
} from './schedule-services.service';
import { DataStorePatch } from '../../portal/data-house/data-store.action';

export interface ServiceGroupAndService {
  group: { title: string; servicesId?: number };
  subService: {
    title: string;
    priceTax: number;
    price: string;
    partsTypes?: ServicePartType[];
    priceTotal: string;
    extraChargePerKm?: number;
    extraCharges?: number;
    extraDistance?: number;
    subTotal?: number;
    stripePriceTotal: number;
  };
  partType?: ServicePartType;
}

interface ScheduleServiceModel {
  loading: boolean;
  serviceProgress: number;
  selectedVehicle: Vehicle | undefined;
  selectedService: VehicleSubServices | undefined;
  selectedSubServiceAddons: SubServiceAddon[] | undefined; // user selected addons for order
  selectedServicePartsType: ServicePartType | undefined;
  selectedVehicleServices: VehicleServices[];
  selectedVehicleServicesFilterRows: number;
  selectedLocation: UserLocation | undefined;
  couponDetails: CouponDetails | undefined;
  checkoutInfo: string | undefined;
  checkoutInfoPayWithCash: string | undefined;
  scheduleServiceComputedPriceDetail: ScheduleServiceComputedPriceDetail | undefined;
}

export class ScheduleServiceSetSelectedVehicle {
  static readonly type = '@scheduleServiceState.setSelectedVehicle';
  constructor(public vehicle: Vehicle | undefined) {}
}

export class ScheduleServiceSetSelectedService {
  static readonly type = '@scheduleServiceState.setSelectedService';
  constructor(public service: VehicleSubServices | undefined) {}
}

export class ScheduleServiceSetPartType {
  static readonly type = '@scheduleServiceState.setPartType';
  constructor(public partType: ServicePartType | undefined) {}
}

export class ScheduleServiceGetVehicleServices {
  static readonly type = '@scheduleServiceState.getVehicleServices';
  constructor(public userVehicleId: string | undefined) {}
}

export class ScheduleServicePatchState {
  static readonly type = '@scheduleServiceState.patchState';
  constructor(public state: Partial<ScheduleServiceModel>) {}
}

export class ScheduleServiceSetLocation {
  static readonly type = '@scheduleServiceState.setLocation';
  constructor(public userLocation: UserLocation | undefined) {}
}

export class ScheduleServiceResetState {
  static readonly type = '@scheduleServiceState.resetState';
  constructor() {}
}

export class ScheduleServiceValidateCoupon {
  static readonly type = '@scheduleServiceState.validateCoupon';
  constructor(public coupon: string) {}
}

export class ScheduleServiceGetCheckoutInfo {
  static readonly type = '@scheduleServiceState.getCheckoutInfo';
  constructor() {}
}

export class ScheduleServiceSetServiceAddon {
  static readonly type = '@scheduleServiceState.setServiceAddon';
  constructor(public serviceAddons: SubServiceAddon[] | undefined) {}
}

@State<ScheduleServiceModel>({
  name: 'ScheduleServicesState',
  defaults: {
    loading: false,
    serviceProgress: 0.6,
    selectedVehicle: undefined,
    selectedService: undefined,
    selectedServicePartsType: undefined,
    selectedVehicleServices: [],
    selectedVehicleServicesFilterRows: 0,
    selectedLocation: undefined,
    couponDetails: undefined,
    checkoutInfo: undefined,
    checkoutInfoPayWithCash: undefined,
    selectedSubServiceAddons: undefined,
    scheduleServiceComputedPriceDetail: undefined,
  },
})
@Injectable()
export class ScheduleServicesState {
  constructor(
    private store: Store,
    private scheduleServiceServices: ScheduleServicesService,
    private authState: AuthState,
    private couponsServices: CouponsService,
    private toastServices: ToastService,
    private appFeaturesState: AppFeaturesState
  ) {}

  @Selector()
  static state(state: ScheduleServiceModel) {
    return state;
  }
  state$ = this.store.select(ScheduleServicesState.state);

  get snapshot() {
    return this.store.selectSnapshot(ScheduleServicesState.state);
  }
  serviceProgress$: Observable<number> = this.state$.pipe(map((s) => s.serviceProgress));

  @Selector()
  static selectedVehicle(state: ScheduleServiceModel) {
    return state.selectedVehicle;
  }
  selectedVehicle$: Observable<Vehicle | undefined> = this.store.select(ScheduleServicesState.selectedVehicle);
  get selectedVehicle(): Vehicle | undefined {
    return this.snapshot.selectedVehicle;
  }

  @Selector()
  static selectedLocation(state: ScheduleServiceModel) {
    return state.selectedLocation;
  }
  selectedLocation$ = this.store.select(ScheduleServicesState.selectedLocation);
  get selectedLocation() {
    return this.snapshot.selectedLocation;
  }

  @Selector()
  static selectedService(state: ScheduleServiceModel) {
    return state.selectedService;
  }
  selectedService$: Observable<undefined | VehicleSubServices> = this.store.select(ScheduleServicesState.selectedService);
  public get selectedService(): undefined | VehicleSubServices {
    return this.snapshot.selectedService;
  }

  @Selector()
  static selectedVehicleService(state: ScheduleServiceModel) {
    return state.selectedVehicleServices;
  }
  selectedVehicleServices$: Observable<VehicleServices[]> = this.store.select(ScheduleServicesState.selectedVehicleService);

  @Selector()
  static expandVehicleServices(state: ScheduleServiceModel) {
    return state.selectedVehicleServicesFilterRows === 1;
  }

  @Selector()
  static selectedServicePartsType(state: ScheduleServiceModel) {
    return state.selectedServicePartsType;
  }
  selectedServicePartsType$: Observable<ServicePartType | undefined> = this.store.select(ScheduleServicesState.selectedServicePartsType);
  get selectedServicePartsType() {
    return this.snapshot.selectedServicePartsType;
  }

  @Selector()
  static couponDetails(state: ScheduleServiceModel) {
    return state.couponDetails;
  }

  @Selector([
    ScheduleServicesState.selectedVehicleService,
    ScheduleServicesState.selectedService,
    ScheduleServicesState.selectedServicePartsType,
  ])
  static selectedServiceGroupAndService(
    selectedVehicleServices: VehicleServices[] | undefined,
    selectedService: VehicleSubServices | undefined,
    selectedServicePartsType: ServicePartType | undefined
  ): ServiceGroupAndService {
    if (!selectedVehicleServices || !selectedService) {
      return { group: { title: '' }, subService: { title: 'Cost', priceTax: 0, price: '0.00', priceTotal: '0.00', stripePriceTotal: 0 } };
    }
    let serviceGroup: ServiceGroupAndService | undefined;
    for (const service of selectedVehicleServices) {
      for (const subService of service.subServices) {
        if (subService.servicesId === selectedService.servicesId) {
          serviceGroup = { group: { servicesId: service.servicesId || undefined, title: service.title }, subService: selectedService };
        }
      }
    }

    if (!serviceGroup) {
      return { group: { title: '' }, subService: { title: 'Cost', priceTax: 0, price: '0.00', priceTotal: '0.00', stripePriceTotal: 0 } };
    }
    if (!serviceGroup.subService.partsTypes || !selectedServicePartsType) {
      return serviceGroup;
    }

    for (const partType of serviceGroup.subService.partsTypes) {
      if (partType.code === selectedServicePartsType.code) {
        serviceGroup.partType = partType;
        break;
      }
    }
    return serviceGroup;
  }

  selectedServiceGroupAndService$: Observable<ServiceGroupAndService> = this.store.select(
    ScheduleServicesState.selectedServiceGroupAndService
  );

  @Selector()
  static serviceSelectionIsValid(state: ScheduleServiceModel) {
    const { selectedVehicle, selectedLocation, selectedService } = state;
    return selectedVehicle && selectedLocation && selectedService ? true : false;
  }
  serviceSelectionIsValid$ = combineLatest([this.selectedVehicle$, this.selectedLocation$, this.selectedService$]).pipe(
    map(([selectedVehicle, selectedLocation, selectedService]) => selectedVehicle && selectedLocation && selectedService)
  );

  @Selector()
  static checkoutInfo(state: ScheduleServiceModel) {
    return state.checkoutInfo;
  }

  @Selector()
  static checkoutInfoPayWithCash(state: ScheduleServiceModel) {
    return state.checkoutInfoPayWithCash;
  }

  @Selector() static availableSubServiceAddons(state: ScheduleServiceModel) {
    return state.selectedService?.serviceAddons;
  }

  @Selector() static scheduleServiceComputedPrices(state: ScheduleServiceModel) {
    return state.scheduleServiceComputedPriceDetail;
  }

  @Selector() static selectedSubServiceAddons(state: ScheduleServiceModel) {
    return state.selectedSubServiceAddons;
  }

  private setLoading(loading: boolean) {
    this.store.dispatch(new SetLoading(loading, this));
  }

  @Action(ScheduleServiceSetSelectedVehicle)
  setSelectedVehicle(ctx: StateContext<ScheduleServiceModel>, action: ScheduleServiceSetSelectedVehicle) {
    ctx.patchState({ selectedVehicle: action.vehicle });
  }

  @Action(ScheduleServiceSetSelectedService)
  setSelectedService(ctx: StateContext<ScheduleServiceModel>, action: ScheduleServiceSetSelectedService) {
    ctx.patchState({
      selectedService: action.service,
      selectedServicePartsType: undefined,
      couponDetails: undefined,
      selectedSubServiceAddons: undefined,
    });
  }

  @Action(ScheduleServiceSetPartType)
  setSelectedServicePartType(ctx: StateContext<ScheduleServiceModel>, action: ScheduleServiceSetPartType) {
    ctx.patchState({ selectedServicePartsType: action.partType });
  }

  @Action(ScheduleServiceGetVehicleServices)
  getSelectedVehicleServices(ctx: StateContext<ScheduleServiceModel>, action: ScheduleServiceGetVehicleServices) {
    if (!action.userVehicleId) {
      ctx.patchState({
        selectedVehicleServices: undefined,
      });
      return EMPTY;
    }
    const location = ctx.getState().selectedLocation;
    if (!location) {
      return EMPTY;
    }
    this.setLoading(true);
    const userId = this.authState.userId;
    return this.scheduleServiceServices.getSelectedVehicleServices(userId, action.userVehicleId, location?.usersLocationsId).pipe(
      tap((res) => {
        ctx.patchState({
          selectedVehicleServices: res.data,
          selectedVehicleServicesFilterRows: res.recordsFiltered,
        });
      }),
      finalize(() => this.setLoading(false))
    );
  }

  @Action(ScheduleServicePatchState)
  private patchState(ctx: StateContext<ScheduleServiceModel>, action: ScheduleServicePatchState) {
    ctx.patchState(action.state);
  }

  @Action(ScheduleServiceSetLocation)
  private setLocation(ctx: StateContext<ScheduleServiceModel>, action: ScheduleServiceSetLocation) {
    const updates: Partial<ScheduleServiceModel> = { selectedLocation: action.userLocation };
    ctx.dispatch(new ScheduleServicePatchState(updates));
    const selectedVehicle = ctx.getState().selectedVehicle;
    if (selectedVehicle && updates.selectedLocation) {
      ctx.dispatch(new ScheduleServiceGetVehicleServices(selectedVehicle.usersVehiclesId));
    }
  }

  @Action(ScheduleServiceResetState)
  private resetState(ctx: StateContext<ScheduleServiceModel>, action: ScheduleServiceResetState) {
    ctx.dispatch(new DataStorePatch({ datesList: [] }));
    ctx.setState({
      serviceProgress: 0.6,
      selectedVehicle: undefined,
      selectedService: undefined,
      selectedServicePartsType: undefined,
      selectedVehicleServices: [],
      selectedLocation: undefined,
      couponDetails: undefined,
      checkoutInfo: undefined,
      loading: false,
      selectedVehicleServicesFilterRows: 0,
      checkoutInfoPayWithCash: undefined,
      selectedSubServiceAddons: undefined,
      scheduleServiceComputedPriceDetail: undefined,
    });
  }

  @Action(ResetState)
  resetStateByParent(ctx: StateContext<ScheduleServiceModel>, action: ResetState) {
    ctx.dispatch(new ScheduleServiceResetState());
  }

  @Action(ScheduleServiceValidateCoupon)
  private validateCoupon(ctx: StateContext<ScheduleServiceModel>, action: ScheduleServiceValidateCoupon) {
    const state = ctx.getState();
    if (!state.selectedVehicle) {
      this.toastServices.showToast(this.appFeaturesState.isApp, {
        message: 'Please select a vehicle before applying a coupon ',
      });
      return;
    }
    if (!state.selectedService) {
      this.toastServices.showToast(this.appFeaturesState.isApp, {
        message: 'Please select a service before applying a coupon ',
      });
      return;
    }
    if (!state.selectedLocation?.usersLocationsId) {
      this.toastServices.showToast(this.appFeaturesState.isApp, {
        message: 'Please select a location before applying a coupon ',
      });
      return;
    }
    ctx.dispatch(new SetLoading(true, this, false, ScheduleServiceValidateCoupon.name));
    return this.couponsServices
      .validateCoupon(
        action.coupon,
        state.selectedService.servicesId,
        state.selectedServicePartsType?.code ?? '',
        state.selectedVehicle.usersVehiclesId,
        state.selectedLocation.usersLocationsId,
        state.selectedSubServiceAddons?.map((sa) => sa.servicesAddonsId)
      )
      .pipe(
        tap((res) => {
          if (res.message) {
            this.toastServices.showToast(this.appFeaturesState.isApp, {
              message: res.message,
            });
          }
          ctx.patchState({
            couponDetails: {
              coupon: res.coupon,
              serviceDiscountDetails: res.serviceDetails,
            },
          });
        }),
        switchMap(() => ctx.dispatch(new ScheduleServiceGetCheckoutInfo())),
        finalize(() => ctx.dispatch(new SetLoading(false, this, false, ScheduleServiceValidateCoupon.name)))
      );
  }

  @Action(ScheduleServiceGetCheckoutInfo)
  getCheckoutInfo(ctx: StateContext<ScheduleServiceModel>, action: ScheduleServiceGetCheckoutInfo) {
    ctx.dispatch(new SetLoading(true, this, false, ScheduleServiceGetCheckoutInfo.name));
    const state = ctx.getState();
    const currentDate = format(Date.now(), `yyyy-MM-dd'T'HH:mm:00`);
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (!state.selectedVehicle?.usersVehiclesId || !state.selectedLocation?.usersLocationsId || !state.selectedService?.servicesId) {
      return;
    }
    return this.scheduleServiceServices
      .getCheckoutInfo(
        state.selectedVehicle.usersVehiclesId,
        state.selectedLocation.usersLocationsId,
        state.selectedService.servicesId.toString(),
        state.selectedServicePartsType?.code,
        currentDate,
        timezone,
        state.couponDetails?.coupon.code,
        state.selectedSubServiceAddons?.map((sa) => sa.servicesAddonsId) ?? []
      )
      .pipe(
        tap((res) => {
          ctx.patchState({
            checkoutInfo: res.checkoutInfo,
            checkoutInfoPayWithCash: res.checkoutInfoPayWitCash,
            scheduleServiceComputedPriceDetail: res.service.serviceDetails,
          });
          ctx.dispatch(new DataStorePatch({ datesList: res.datesList }));
        }),
        finalize(() => ctx.dispatch(new SetLoading(false, this, false, ScheduleServiceGetCheckoutInfo.name)))
      );
  }

  @Action(ScheduleServiceSetServiceAddon)
  setServiceAddon(ctx: StateContext<ScheduleServiceModel>, action: ScheduleServiceSetServiceAddon) {
    ctx.patchState({
      selectedSubServiceAddons: action.serviceAddons,
    });

    return ctx.dispatch(new ScheduleServiceGetCheckoutInfo());
  }
}
