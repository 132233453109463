import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { finalize, from, tap } from 'rxjs';
import { SetLoading } from '../../core/loading.state';
import { ToastService } from '../../core/toast.service';
import { AppFeaturesState } from '../../portal/app-feature.state';
import { AuthState } from '../../portal/auth.state';
import { OrdersService } from '../../portal/orders.service';
import { sanitizeHtml } from '../../shared/function';
import { ResetState } from '../../shared/state.model';
import { ReviewFormComponent } from './review-form.component';

interface ReviewFormStateModel {
  ordersId: string | undefined;
  ordersServicesId: number | undefined;
  googleUrl: string | undefined;
  message: string | undefined;
}

export class ReviewFormPreviewModal {
  static readonly type = '@reviewFormState.previewModal';
  constructor(public ordersId: string, public ordersServicesId: number) {}
}

export class ReviewFormSubmit {
  static readonly type = '@reviewFormState.submit';
  constructor(public stars: number, public comment?: string) {}
}

export class ReviewFormReset {
  static readonly type = '@reviewFormState.reset';
}

@State<ReviewFormStateModel>({
  name: 'ReviewFormState',
  defaults: {
    ordersId: undefined,
    ordersServicesId: undefined,
    googleUrl: undefined,
    message: undefined,
  },
})
@Injectable()
export class ReviewFormState {
  constructor(
    private modalController: ModalController,
    private ordersService: OrdersService,
    private store: Store,
    private toastService: ToastService,
    private appFeaturesState: AppFeaturesState
  ) {}

  @Selector()
  static ordersServicesId(state: ReviewFormStateModel) {
    return state.ordersServicesId;
  }

  @Selector()
  static message(state: ReviewFormStateModel) {
    return state.message;
  }

  @Action(ReviewFormPreviewModal)
  previewModal(ctx: StateContext<ReviewFormStateModel>, action: ReviewFormPreviewModal) {
    ctx.patchState({
      ordersId: action.ordersId,
      ordersServicesId: action.ordersServicesId,
    });
    const promise = new Promise(async (resolve) => {
      const modal = await this.modalController.create({
        component: ReviewFormComponent,
      });
      await modal.present();
      resolve(modal);
    });
    return from(promise);
  }

  @Action(ReviewFormSubmit)
  submit(ctx: StateContext<ReviewFormStateModel>, action: ReviewFormSubmit) {
    const state = ctx.getState();
    if (!state.ordersId) {
      throw Error('ordersId is undefined');
    }
    if (!state.ordersServicesId) {
      throw Error('ordersServicesId is undefined');
    }
    const user = this.store.selectSnapshot(AuthState.userInfo);
    ctx.dispatch(new SetLoading(true, this, false));
    return this.ordersService
      .submitFeedback(state.ordersId, state.ordersServicesId, action.stars, action.comment, `${user.firstName} ${user.lastName}`.trim())
      .pipe(
        tap((res) => {
          if (action.stars === 5) {
            const sanitizedHtml = sanitizeHtml(res.message);
            ctx.patchState({
              googleUrl: res.googleUrl,
              message: sanitizedHtml,
            });
            return;
          }
          this.toastService.showToast(this.appFeaturesState.isApp, {
            message: res.message,
          });
        }),
        finalize(() => ctx.dispatch(new SetLoading(false, this, false)))
      );
  }

  @Action(ReviewFormReset)
  @Action(ResetState)
  resetState(ctx: StateContext<ReviewFormStateModel>) {
    ctx.setState({
      googleUrl: undefined,
      message: undefined,
      ordersId: undefined,
      ordersServicesId: undefined,
    });
  }
}
