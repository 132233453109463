import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appPhoneFormat',
})
export class PhoneFormatPipe implements PipeTransform {
  transform(rawNum: string) {
    const sanitizeNum = rawNum.replace(/[^0-9]*/g, '');
    const areaCode = sanitizeNum.slice(0, 3);
    const centralOfficeCode = sanitizeNum.slice(3, 6);
    const stationCode = sanitizeNum.slice(6);

    return `(${areaCode}) ${centralOfficeCode}-${stationCode}`;
  }
}
