import { Injectable } from '@angular/core';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Analytics, getAnalytics } from 'firebase/analytics';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { environment } from '../../environments/environment';
import { ActionPerformed as localNotificationActionPerformed, LocalNotifications } from '@capacitor/local-notifications';
import { from } from 'rxjs';
import { ActionPerformed, PushNotifications, PushNotificationSchema } from '@capacitor/push-notifications';
import { AppFeaturesService } from '../portal/app-features.service';
import { Capacitor } from '@capacitor/core';
import { ReviewFormPreviewModal } from '../shared-components/review-form/review-form.state';
import { NotificationType } from '../shared';
import { Store } from '@ngxs/store';
import { AuthService } from '../portal/auth.service';
import { UserGetNotifications, UserUpdateNotification } from '../portal/userDetails.state';
import { AppFeaturesState } from '../portal/app-feature.state';
import { SetLoading } from './loading.state';
import { VehicleViewUpdateVehicleDetailsOptions } from '../inside/schedule-services/vehicle-v2/vehicles-view.state';
import { InsideNavigateToHome } from '../portal/inside.state';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  app: FirebaseApp;
  analytics: Analytics;

  localNotificationCounter = 0;
  constructor(
    private appFeaturesService: AppFeaturesService,
    private store: Store,
    private authService: AuthService
  ) {
    if (Capacitor.isPluginAvailable('PushNotifications')) {
      this.initializePushNotifications();
    }

    if (Capacitor.isPluginAvailable('LocalNotifications')) {
      this.initializeLocalNotification();
    }
  }

  initializeFirebase() {
    this.app = initializeApp(environment.firebase.firebaseConfig);
    this.analytics = getAnalytics(this.app);
  }

  requestPermission() {
    const messaging = getMessaging();
    console.log('messaging', messaging);
    const observable = from(getToken(messaging, { vapidKey: environment.firebase.vapidKey }));
    return observable;
  }

  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
    });
  }

  initializePushNotifications() {
    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error on registration: ' + JSON.stringify(error));
    });
    PushNotifications.addListener('pushNotificationReceived', async (notification: PushNotificationSchema) => {
      console.log('Push received: ' + JSON.stringify(notification));
      // To handle foreground notifications properly we recreate them as local notifications
      this.scheduleLocalNotification(notification);
      this.store.dispatch(new UserGetNotifications());
    });
    PushNotifications.addListener('pushNotificationActionPerformed', (notificationActionPerformed: ActionPerformed) => {
      const data = notificationActionPerformed.notification.data.data
        ? JSON.parse(notificationActionPerformed.notification.data.data)
        : undefined;
      const url = data?.navigate_to;
      if (url) {
        this.store.dispatch(new SetLoading(true, this, true, this.initializePushNotifications.name));
        setTimeout(() => {
          this.store.dispatch(new SetLoading(false, this, true, this.initializePushNotifications.name));
          this.appFeaturesService.inAppNavigationHandler(url);
          this.handleNotificationAction(url);
        }, 3000);
      }
    });
  }

  handleNotificationAction(url: string) {
    const featureFlags = this.store.selectSnapshot(AppFeaturesState.featureFlags);
    if (!featureFlags.notifications) {
      return;
    }
    if (!this.authService.getToken()) {
      return;
    }
    const notificationInfo = this.appFeaturesService.getNotificationInfoFromQuery(url);
    if (!notificationInfo) {
      return;
    }
    if (notificationInfo.usersNotificationsId) {
      this.store.dispatch(new UserUpdateNotification(notificationInfo.usersNotificationsId));
    }
    if (notificationInfo.notificationType === NotificationType.ORDER_REVIEW) {
      this.store.dispatch(new ReviewFormPreviewModal(notificationInfo.ordersId, parseInt(notificationInfo.ordersServicesId)));
    }
    if (notificationInfo.notificationType === NotificationType.REQUEST_VIN_NUMBER) {
      this.store.dispatch(
        new VehicleViewUpdateVehicleDetailsOptions({
          backAction: new InsideNavigateToHome(),
          loadVehicles: true,
        })
      );
    }
  }

  initializeLocalNotification() {
    LocalNotifications.addListener('localNotificationReceived', () => {
      this.store.dispatch(new UserGetNotifications());
    });
    LocalNotifications.addListener('localNotificationActionPerformed', (event: localNotificationActionPerformed) => {
      console.log('localNotificationActionPerformed', JSON.stringify(event));
      const url = event.notification.extra.navigateTo;
      if (url) {
        console.log('navigating', url);
        this.appFeaturesService.inAppNavigationHandler(url);
        this.handleNotificationAction(url);
      }
    });
  }

  public scheduleLocalNotification(notification: PushNotificationSchema) {
    this.localNotificationCounter += 1;
    const data = notification.data.data ? JSON.parse(notification.data.data) : undefined;
    const localNotificationData = {
      notifications: [
        {
          title: notification.title ?? 'AllRotors',
          body: notification.body ?? '',
          id: this.localNotificationCounter,
          smallIcon: 'res/drawable/ic_notification',
          extra: {
            navigateTo: data?.navigate_to,
          },
        },
      ],
    };
    LocalNotifications.schedule(localNotificationData);
  }

  async setCurrentScreen() {
    // setCurrentScreen({
    //   screenName: 'Login',
    //   screenClassOverride: 'LoginPage',
    // });
  }
}
