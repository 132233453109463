import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ResetState } from '../../shared';
import { DataStoreStateModel, DataStorePatch } from './data-store.action';

@State<DataStoreStateModel>({
  name: 'DataStoreStateModel',
  defaults: {
    datesList: [], // e.g 2023-02-18 05:00:00
  },
})
@Injectable()
export class DataStore {
  @Selector()
  static datesList(state: DataStoreStateModel) {
    return state.datesList;
  }

  @Action(ResetState)
  resetStateByParent(ctx: StateContext<DataStoreStateModel>) {
    ctx.setState({
      datesList: [],
    });
  }

  @Action(DataStorePatch)
  patchDataStore(ctx: StateContext<DataStoreStateModel>, action: DataStorePatch) {
    ctx.patchState(action.state);
  }
}
