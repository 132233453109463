import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { finalize, from, switchMap, tap } from 'rxjs';
import { SetLoading } from '../../../core/loading.state';
import { OrdersService, OrdersType } from '../../../portal/orders.service';
import { Order, ResetState, ScheduleServiceStripePayload, StripePayloadEnum } from '../../../shared';
import { UploadPictureSetImages } from '../order-service-photo-view/order-upload-picture.state';
import { OrderDetailsContainerPatchState } from '../order-details-container/order-details-container.action';
import { AppFeaturesState } from '../../../portal/app-feature.state';
import { StripePaymentService } from '../../../portal/stripe-payment.service';

export class OrderDetailGet {
  static readonly type = '@orderDetail.get';
  constructor(
    public id: number,
    public orderType: OrdersType
  ) {}
}

export class OrderDetailUpdate {
  static readonly type = '@orderDetail.updateOrder';
  constructor(public order: Partial<Order>) {}
}

export class OrderDetailReset {
  static readonly type = '@orderDetail.reset';
  constructor() {}
}

export class OrderDetailPayByCard {
  static readonly type = '@orderDetail.payByCard';
  constructor(
    public orderServiceId: string,
    public payload: { userCreditCardId: string }
  ) {}
}

interface OrderDetailStateModel {
  order: Order | undefined;
  recordsFiltered: number | undefined;
}

@State<OrderDetailStateModel>({
  name: 'OrderDetailsState',
  defaults: {
    order: undefined,
    recordsFiltered: undefined,
  },
})
@Injectable()
export class OrderDetailsState {
  constructor(
    private orderService: OrdersService,
    private appFeaturesState: AppFeaturesState,
    private stripePaymentService: StripePaymentService
  ) {}

  @Selector()
  public static order(state: OrderDetailStateModel) {
    return state.order;
  }

  @Selector()
  public static recordsFiltered(state: OrderDetailStateModel) {
    return state.recordsFiltered;
  }

  @Action(OrderDetailGet)
  private getOrderDetail(ctx: StateContext<OrderDetailStateModel>, action: OrderDetailGet) {
    ctx.dispatch(new SetLoading(true, this));
    return this.orderService
      .getOrders({
        id: action.id,
        ordersType: action.orderType,
      })
      .pipe(
        tap((res) => {
          ctx.patchState({
            order: res.data[0],
            recordsFiltered: parseFloat(res.recordsFiltered) || undefined,
          });
          ctx.dispatch(new OrderDetailsContainerPatchState({ order: res.data[0] }));
          if (res.data[0]?.images) {
            ctx.dispatch(new UploadPictureSetImages(res.data[0]?.images));
          }
        }),
        finalize(() => ctx.dispatch(new SetLoading(false, this)))
      );
  }

  @Action(OrderDetailUpdate)
  private updateOrder(ctx: StateContext<OrderDetailStateModel>, action: OrderDetailUpdate) {
    ctx.setState(
      patch({
        order: patch(action.order),
      })
    );
  }

  @Action(OrderDetailReset)
  private resetState(ctx: StateContext<OrderDetailStateModel>, action: OrderDetailReset) {
    ctx.patchState({
      order: undefined,
      recordsFiltered: undefined,
    });
  }

  @Action(ResetState)
  resetStateByParent(ctx: StateContext<OrderDetailStateModel>, action: ResetState) {
    ctx.dispatch(new OrderDetailReset());
  }

  @Action(OrderDetailPayByCard)
  payByCard(ctx: StateContext<OrderDetailStateModel>, action: OrderDetailPayByCard) {
    ctx.dispatch(new SetLoading(true, this, true, OrderDetailPayByCard.name));
    return this.orderService.switchToPayByCard(action.orderServiceId, action.payload).pipe(
      switchMap((res) => {
        const promise = async () => {
          const stripePayloads: ScheduleServiceStripePayload[] = [];
          let validateOrder: boolean = false; // validate order after user completes authorization steps
          if (res.stripePayload1 && res.stripePayload1.type === StripePayloadEnum.NEXT_ACTION_NEEDED) {
            validateOrder = true;
            stripePayloads.push(res.stripePayload1);
          }
          if (res.stripePayload2 && res.stripePayload2.type === StripePayloadEnum.NEXT_ACTION_NEEDED) {
            validateOrder = true;
            stripePayloads.push(res.stripePayload2);
          }
          if (!validateOrder) {
            return res;
          }
          ctx.dispatch(new SetLoading(false, this, true, OrderDetailPayByCard.name));
          if (!this.appFeaturesState.isNativeIos) {
            // modal and iframe approach for all systems
            await this.stripePaymentService.handleStripeAuthForWebApp(stripePayloads);
          } else {
            await this.stripePaymentService.handleStripeAuthForNativeApp(stripePayloads);
          }
          return res;
        };
        return from(promise());
      }),
      finalize(() => ctx.dispatch(new SetLoading(false, this, true, OrderDetailPayByCard.name)))
    );
  }
}
