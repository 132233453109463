<ion-content [scrollEvents]="true" (ionScrollStart)="handleIonScrollStart()" class="home-img" *ngIf="!onlyForm">
  <app-header></app-header>
  <ion-grid class="grid-container">
    <ion-row class="ion-align-items-center ion-justify-content-center" class="grid-row">
      <ion-col size-md="4" size-sm="12">
        <ion-card color="light" [formGroup]="resetPasswordFormGroup">
          <ion-card-content color="light">
            <ion-card-header>
              <ion-card-title> Reset Password </ion-card-title>
            </ion-card-header>

            <ion-item color="light">
              <ion-label position="floating"> New Password </ion-label>
              <ion-input [clearOnEdit]="false" formControlName="password" type="password"></ion-input>
              <span slot="error">
                <app-error-view
                  *ngIf="resetPasswordFormGroup.controls.password.touched"
                  name="password"
                  [errors]="resetPasswordFormGroup.controls.password.errors"
                  minLengthErrorMessage="Password must be of length 6 or more"
                ></app-error-view>
              </span>
              <span slot="helper"></span>
            </ion-item>

            <ion-item color="light">
              <ion-label position="floating"> Confirm New Password </ion-label>
              <ion-input [clearOnEdit]="false" formControlName="password2" type="password"></ion-input>
              <span slot="error">
                <app-error-view
                  *ngIf="resetPasswordFormGroup.controls.password2.touched"
                  name="password"
                  [errors]="resetPasswordFormGroup.controls.password2.errors"
                  minLengthErrorMessage="Password must be of length 6 or more"
                ></app-error-view>
                <div *ngIf="!passwordMatched">passwords do not match</div>
              </span>
              <span slot="helper"></span>
            </ion-item>

            <div class="action-buttons ion-margin-top">
              <ion-button
                [disabled]="resetPasswordFormGroup.invalid"
                expand="block"
                size="medium"
                color="primary"
                (click)="resetPassword()"
              >
                Reset Password
              </ion-button>
            </div>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
  <app-footer></app-footer>
</ion-content>

<ng-container *ngIf="onlyForm" [formGroup]="resetPasswordFormGroup">
  <ion-list>
    <ion-item>
      <ion-label position="floating"> New Password </ion-label>
      <ion-input [clearOnEdit]="false" formControlName="password" type="password"></ion-input>
      <span slot="error">
        <app-error-view
          *ngIf="resetPasswordFormGroup.controls.password.touched"
          name="password"
          [errors]="resetPasswordFormGroup.controls.password.errors"
          minLengthErrorMessage="Password must be of length 6 or more"
        ></app-error-view>
      </span>
      <span slot="helper"></span>
    </ion-item>

    <ion-item>
      <ion-label position="floating"> Confirm New Password </ion-label>
      <ion-input [clearOnEdit]="false" formControlName="password2" type="password"></ion-input>
      <span slot="error">
        <app-error-view
          *ngIf="resetPasswordFormGroup.controls.password2.touched"
          name="password"
          [errors]="resetPasswordFormGroup.controls.password2.errors"
          minLengthErrorMessage="Password must be of length 6 or more"
        ></app-error-view>
        <div *ngIf="!passwordMatched">passwords do not match</div>
      </span>
      <span slot="helper"></span>
    </ion-item>

    <div class="action-buttons ion-margin-top" [ngClass]="{ 'ion-margin-start': appFeaturesState.isIos }">
      <ion-button [disabled]="resetPasswordFormGroup.invalid" expand="block" size="medium" color="primary" (click)="resetPassword()">
        Reset Password
      </ion-button>
    </div>
  </ion-list>
</ng-container>
