import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import camelcaseKeys from 'camelcase-keys';
import { map } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BaseResponse } from '../../shared';

interface ValidateCouponResponse extends BaseResponse {
  coupon: {
    allowed_to_use: string;
    apply_to_id: string;
    code: string;
    code_type: string;
    coupon_codes_id: string;
    discount: string;
    discount_on_id: string;
    expiry_date: string;
    name: string;
  };
  service_details: {
    is_free: boolean;
    discount_value: string;
    parts_cost?: string;
    service_total: string;
    service_total_with_tax: string;
    service_cost: string;
    tax_amount: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class CouponsService {
  constructor(private http: HttpClient) {}

  validateCoupon(
    coupon: string,
    serviceId: number,
    partsCode: string,
    usersVehicleId: string,
    usersLocationsId: string,
    servicesAddonsIds: string[] | undefined
  ) {
    const form = new FormData();
    form.append('code', coupon);
    form.append('users_vehicles_id', usersVehicleId);
    form.append('services_id', serviceId.toString());
    form.append('service_part_code', partsCode);
    form.append('users_locations_id', usersLocationsId);
    for (const addonId of servicesAddonsIds ?? []) {
      form.append('services_addons_id[]', addonId);
    }
    return this.http
      .post<ValidateCouponResponse>(`${environment.api_url}/coupons/validate`, form)
      .pipe(map((res) => camelcaseKeys(res, { deep: true })));
  }
}
