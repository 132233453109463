import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';
import { AppFeaturesState } from '../../../portal/app-feature.state';

@Pipe({
  name: 'appDateFormat',
})
export class DateFormatPipe implements PipeTransform {
  constructor(private appFeaturesState: AppFeaturesState) {}
  transform(date: any): string | undefined {
    if (!date) {
      return undefined;
    }
    if (date instanceof Date === false && (this.appFeaturesState.isIos || navigator.userAgent.indexOf('Safari') != -1)) {
      date = date.replace(/-/, '/');
      date = date.replace(/-/, '/');
      date = date.replace(/T/, ' ');
      date = new Date(date);
    }

    const formattedDate = format(date, 'MMM d, y, h:mm a');
    return formattedDate;
  }
}
