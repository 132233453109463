import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, combineLatest, map, of, switchMap } from 'rxjs';
import {
  AppActivateAppleSdk,
  AppActivateGoogleAnalytics,
  AppActivateGoogleMap,
  AppGetAppConfigs,
  AppGetAppVersion,
} from '../portal/app-feature.state';
import { FireBaseInitialize } from '../core/firebase.state';

@Injectable({
  providedIn: 'root',
})
export class InitializeAppResolver implements Resolve<boolean> {
  constructor(private store: Store) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return combineLatest([
      this.store.dispatch(new AppGetAppVersion()),
      this.store.dispatch(new AppGetAppConfigs()).pipe(switchMap(() => this.store.dispatch(new AppActivateGoogleAnalytics()))),
      this.store.dispatch(new AppActivateAppleSdk()),
      this.store.dispatch(new AppActivateGoogleMap()),
      this.store.dispatch(new FireBaseInitialize()),
    ]).pipe(map(() => true));
  }
}
