<ion-header size="small" class="ion-no-border">
  <ion-toolbar>
    <ion-button fill="clear" slot="end" color="secondary" (click)="closeModal()">
      <ion-icon size="large" name="close"></ion-icon>
    </ion-button>

    <ion-title class="ion-text-center heading-title">
      <h2 *ngIf="viewType === policyViewType.TERMS_AND_CONDITION" class="ion-text-wrap">Terms and Conditions</h2>
      <h2 *ngIf="viewType === policyViewType.PRIVACY_POLICIES" class="ion-text-wrap">Privacy Policy</h2>
    </ion-title>
    <ion-progress-bar *ngIf="loading$ | async" type="indeterminate"></ion-progress-bar>
  </ion-toolbar>
</ion-header>

<div class="content-container">
  <app-terms-and-conditions
    *ngIf="viewType === policyViewType.TERMS_AND_CONDITION"
    #termsAndConditions
    [inModal]="true"
  ></app-terms-and-conditions>
  <app-privacy-policy *ngIf="viewType === policyViewType.PRIVACY_POLICIES" #privacyPolicy [inModal]="true"></app-privacy-policy>
</div>
