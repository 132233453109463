import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

interface Response {
  message: string;
  status: number;
  warning: string;
}

export interface BaseVehiclePayload {
  [key: string]: string;
}

export interface VehicleMake {
  MakeID: number;
  name: string;
}

export interface VehicleModel {
  ModelID: number;
  name: string;
}

interface ResponseGetVehicleSubModel extends Response {
  arr_body: { [key: string]: BaseVehiclePayload };
  arr_drive_type: { [key: string]: BaseVehiclePayload };
  arr_engine: { [key: string]: BaseVehiclePayload };
  arr_model: BaseVehiclePayload;
  arr_sub: BaseVehiclePayload;
}

@Injectable({
  providedIn: 'root',
})
export class VehicleService {
  constructor(private http: HttpClient) {}

  getVehicleMakes(year: string): Observable<VehicleMake[]> {
    return this.http.get<{ data: VehicleMake[] }>(`${environment.api_url}/make?year=${year}`).pipe(map((response) => response.data));
  }

  getVehicleModel(year: string, vehicleMakeId: string): Observable<VehicleModel[]> {
    return this.http
      .get<{ data: VehicleModel[] }>(`${environment.api_url}/model?year=${year}&makesID=${vehicleMakeId}`)
      .pipe(map((response) => response.data));
  }

  getVehicleSubModelAndDetails(year: string, vehicleMakeId: string, vehicleModelId: string) {
    return this.http.get<ResponseGetVehicleSubModel>(
      `${environment.api_url}/sub_model_vehicle?year_vehicle=${year}&make_vehicle=${vehicleMakeId}&model_vehicle=${vehicleModelId}`
    );
  }
}
