import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlSerializer } from '@angular/router';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { Store } from '@ngxs/store';
import camelcaseKeys from 'camelcase-keys';
import { from, map, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { BaseResponse, DeviceType, FieldsType, NotificationType } from '../shared';
import { AuthCheckUser, AuthSetNewUserInvitationToken, AuthState, AuthUpdateUser } from './auth.state';
import { AppInsideUrls, InsideNavigateToHome, InsideNavigateToLocation, InsideNavigateToUrl } from './inside.state';
import { InAppBrowser } from '@capgo/inappbrowser';
import { AuthService } from './auth.service';

interface AppInfo extends BaseResponse {
  data: {
    terms_and_conditions?: string;
    privacy_policy?: string;
    landing_page_mobile_car_detailing?: string;
    landing_page_car_break_content?: string;
    home_screen?: string;
    checkout_payment_note?: string;
    account_deletion_request?: string;
    app_version?: string;
    app_version_details?: string;
    mobile_store_banner_play_store?: string;
    mobile_store_banner_app_store?: string;
    app_update_banner_play_store?: string;
    app_update_banner_app_store?: string;
    app_store_url?: string;
    play_store_url?: string;
    feature_flag_native_apple_pay?: string;
    feature_flag_native_google_pay?: string;
    feature_flag_notifications?: string;
    feature_flag_google_analytics: string;
    feature_flag_service_addon?: string;
    allow_app_cash_payment: string;
    feature_flag_pay_with_card_switch: string;
  };
}

export interface NotificationInfo {
  ordersId: string;
  ordersServicesId: string;
  notificationType: NotificationType;
  usersNotificationsId: string;
}

export interface NotificationInfo {
  ordersId: string;
  ordersServicesId: string;
  notificationType: NotificationType;
  usersNotificationsId: string;
}

export interface SupportedCountryCodeRes extends BaseResponse {
  country_codes: {
    code: string;
    name: string;
    flag: string;
  }[];
}

@Injectable({
  providedIn: 'root',
})
export class AppFeaturesService {
  googleAnalyticsLoaded = false;
  appleSdkLoaded = false;
  googleMapLoaded = false;

  constructor(
    private http: HttpClient,
    private urlSerializer: UrlSerializer,
    private store: Store,
    private navController: NavController,
    private platform: Platform,
    private authService: AuthService,
    private modalController: ModalController
  ) {}

  get isAndroid() {
    return this.platform.is('android');
  }
  get isNativeAndroid() {
    return this.platform.is('android') && this.platform.is('capacitor');
  }

  get isNative() {
    return this.isNativeAndroid || this.isNativeIos;
  }

  get isIos() {
    return this.platform.is('ios');
  }

  get isNativeIos() {
    return this.platform.is('ios') && this.platform.is('capacitor');
  }

  get deviceType(): DeviceType {
    if (this.isNativeAndroid) {
      return DeviceType.ANDROID_APP;
    } else if (this.isNativeIos) {
      return DeviceType.IOS_APP;
    } else {
      return DeviceType.WEB_APP;
    }
  }

  get googleAnalyticsMeasurementId() {
    let measurementId: string = environment.googleAnalytics.webApp.measurementId;
    if (this.isNativeAndroid) {
      measurementId = environment.googleAnalytics.android.measurementId;
    } else if (this.isNativeIos) {
      measurementId = environment.googleAnalytics.ios.measurementId;
    }
    return measurementId;
  }

  getAppInfos(...fields: FieldsType[]) {
    const get_fields = fields.join(',');
    return this.http
      .get<AppInfo>(`${environment.api_url}/info?get_fields=${get_fields}`)
      .pipe(map((res) => camelcaseKeys(res, { deep: true })));
  }

  getSupportedCountryCodes() {
    return this.http
      .get<SupportedCountryCodeRes>(`${environment.api_url}/info/supported-country-codes`)
      .pipe(map((res) => camelcaseKeys(res, { deep: true })));
  }

  getOrderServiceIdFromQuery() {
    const host = window.location.host;
    const [, url] = window.location.href.split(host);
    const serializeUrl = this.urlSerializer.parse(url);
    console.log(url);
    const query = serializeUrl.queryParams;
    const ordersServicesId = query.orders_services_id;
    return ordersServicesId;
  }

  getNotificationInfoFromQuery(url: string): NotificationInfo | undefined {
    const host = window.location.host;
    const [, urlPathAndQuery] = url.split(host);
    console.log(urlPathAndQuery);
    const serializeUrl = this.urlSerializer.parse(urlPathAndQuery);
    const query = serializeUrl.queryParams;
    const notificationType = query.notification_type;
    const ordersId = query.orders_id;
    const ordersServicesId = query.orders_services_id;
    const usersNotificationsId = query.users_notifications_id;
    return { notificationType, ordersId, ordersServicesId, usersNotificationsId };
  }

  inAppNavigationHandler(url: string) {
    if (!url.includes(environment.appUrl)) {
      throw Error('Invalid url');
      return;
    }
    const slug = url.split(environment.appUrl).pop();
    if (!slug?.trim()) {
      return;
    }
    if (Object.values(AppInsideUrls).some((url) => slug.includes(url))) {
      this.store
        .dispatch(new AuthCheckUser())
        .pipe(
          tap(async () => {
            const authState = this.store.selectSnapshot(AuthState.state);
            if (!authState?.token) {
              return;
            }
            const requiresMechanicsPermission = slug.includes('/mechanics/');
            if (requiresMechanicsPermission && authState.mechanics != 1) {
              return;
            }
            this.store.dispatch(new InsideNavigateToUrl(slug));
          })
        )
        .subscribe();
      return;
    }
    if (slug.includes('/mobile/')) {
      const goToLandingPagePromise = new Promise<void>(async (resolve, reject) => {
        if (this.isNativeIos) {
          await InAppBrowser.open({ url: `${url}?source=${this.deviceType}` });
        } else {
          await InAppBrowser.openWebView({ url: `${url}?source=${this.deviceType}`, title: 'All Rotors' });
        }

        InAppBrowser.addListener('urlChangeEvent', async (event) => {
          console.log('urlChangeEvent', JSON.stringify(event));
          if (event.url === `${environment.appUrl}/close/browser`) {
            await InAppBrowser.close();
            await InAppBrowser.removeAllListeners();
            if (this.authService.getToken()) {
              this.store.dispatch(new InsideNavigateToLocation());
            } else {
              this.navController.navigateRoot('/');
            }
            resolve();
          }
        });

        this.store.dispatch(new InsideNavigateToHome());
      });
      from(goToLandingPagePromise);
      return;
    }
    //admin page routing
    if (slug.includes('/admin')) {
      from(this.navController.navigateRoot(slug));
      return;
    }
    const token = slug.split('?invitationCode=').pop();
    if (!token?.trim()) {
      return;
    }
    this.store.dispatch(new AuthSetNewUserInvitationToken(token));
    this.store
      .dispatch(new AuthCheckUser())
      .pipe(
        tap(async () => {
          const authState = this.store.selectSnapshot(AuthState.state);
          if (!authState.token) {
            await this.navController.navigateRoot(`/home-page${slug}`);
            return;
          }

          this.store.dispatch(
            new AuthUpdateUser({
              invitationToken: token,
              fieldsToUpdate: ['invitation_token'],
            })
          );
          await this.navController.navigateRoot(`/inside/accounts/profile${slug}`);
        })
      )
      .subscribe();
  }

  dataURItoBlob(dataURI: string, format: string, fileName: string) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    //New Code
    const blob = new Blob([ab], { type: mimeString });
    const file = new File([blob], `${fileName}.${format}`, {
      type: mimeString,
    });
    return file;
  }

  loadGoogleAnalytics(trackingID: string): void {
    const analyticsUrl = `https://www.googletagmanager.com/gtag/js?id=${trackingID}`;
    if (this.googleAnalyticsLoaded) {
      return;
    }
    this.googleAnalyticsLoaded = true;
    const script = document.querySelector(`script[src="${analyticsUrl}"]`);
    if (script) {
      return;
    }
    let gaScript = document.createElement('script');
    gaScript.setAttribute('async', 'true');
    gaScript.setAttribute('loading', 'async');
    gaScript.setAttribute('src', `${analyticsUrl}`);

    let gaScript2 = document.createElement('script');
    gaScript2.setAttribute('async', 'true');
    gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${trackingID}\');`;

    document.documentElement.firstChild?.appendChild(gaScript);
    document.documentElement.firstChild?.appendChild(gaScript2);
  }

  loadAppleSdk(): void {
    const applePayUrl = 'https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js';
    if (this.appleSdkLoaded) {
      return;
    }
    this.appleSdkLoaded = true;
    const script = document.querySelector(`script[src="${applePayUrl}"]`);
    if (script) {
      return;
    }

    let appleScript = document.createElement('script');
    appleScript.setAttribute('async', 'true');
    appleScript.setAttribute('src', `${applePayUrl}`);

    document.documentElement.firstChild?.appendChild(appleScript);
  }

  loadGoogleMaps(): void {
    const mapUrl = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCEzgzMEM9csbodDb2NJ6eLlysJnTc1pzY&libraries=places&loading=async';
    if (this.googleMapLoaded) {
      return;
    }
    this.googleMapLoaded = true;
    const script = document.querySelector(`script[src="${mapUrl}"]`);
    if (script) {
      return;
    }

    let googleMapScript = document.createElement('script');
    googleMapScript.setAttribute('src', `${mapUrl}`);

    document.documentElement.firstChild?.appendChild(googleMapScript);
  }
}
