import { HttpClientModule } from '@angular/common/http';
import { COMPILER_OPTIONS, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { isPlatform } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { IonicModule } from '@ionic/angular';
import { CookieService } from 'ngx-cookie-service';
import { PortalModule } from './portal/portal.module';
import { SharedComponentsModule } from './shared-components/shared-components.module';
import { TimeagoCustomFormatter, TimeagoFormatter, TimeagoIntl, TimeagoModule } from 'ngx-timeago';
import { Store } from '@ngxs/store';
import { DatePipe } from '@angular/common';
import { DateFormatPipe } from './inside/shared/date-time-picker/date-format.pipe';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    HttpClientModule,
    PortalModule,
    IonicModule.forRoot({
      scrollAssist: isPlatform('ios') && !isPlatform('capacitor') ? true : false, // disable scroll for only ios browser only
    }),
    SharedComponentsModule,
    TimeagoModule.forRoot({
      intl: { provide: TimeagoIntl },
      formatter: { provide: TimeagoFormatter, useClass: TimeagoCustomFormatter },
    }),
  ],
  providers: [
    CookieService,
    DatePipe,
    DateFormatPipe,
    {
      provide: COMPILER_OPTIONS,
      useValue: {},
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(store: Store) {
    (<any>window).Store = store;
  }
}
