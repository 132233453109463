import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneMaskDirective } from './phone-mask.directive';
import { PhoneFormatPipe } from './phone-formatter.pipe';
import { VehicleNameFormatterPipe } from './vehicle-name-formatter.pipe';
import { TrimContentDirective } from './trim-content.directive';
import { MinValueDirective } from './min-value.directive';

@NgModule({
  declarations: [PhoneMaskDirective, PhoneFormatPipe, VehicleNameFormatterPipe, TrimContentDirective, MinValueDirective],
  imports: [CommonModule],
  exports: [PhoneMaskDirective, PhoneFormatPipe, VehicleNameFormatterPipe, TrimContentDirective, MinValueDirective],
})
export class InputMaskModule {}
