import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { Observable } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { SetLoading } from '../core/loading.state';
import { Order, OrderServiceStatus, ResetState } from '../shared';
import { OrdersService } from './orders.service';

interface OrdersStateModel {
  orders: Order[];
  allOrders: Order[];
  recordsTotal: number;
  recordsFiltered: number;
  length: number;
  search: string;
}

export class OrdersSetLength {
  static readonly type = '@ordersState.setLength';
  constructor(public length: number) {}
}

export class OrdersChangeLength {
  static readonly type = '@ordersState.changeLength';
  constructor(public newLength: number) {}
}

export class OrdersGet {
  static readonly type = '@ordersState.getOrders';
  constructor(public start: number) {}
}

export class OrdersFilterLogs {
  static readonly type = '@ordersState.filterLogs';
  constructor(public search: string) {}
}

export class OrdersReset {
  static readonly type = '@ordersState.reset';
  constructor() {}
}

export class OrdersCancelOrder {
  static readonly type = '@ordersState.cancelOrder';
  constructor(public ordersServiceId: string, public cancelReason: string) {}
}

@State<OrdersStateModel>({
  name: 'OrdersState',
  defaults: {
    orders: [],
    allOrders: [],
    recordsTotal: 0,
    recordsFiltered: 0,
    length: 10, // dropdown option,
    search: '',
  },
})
@Injectable()
export class OrdersState {
  constructor(private store: Store, private ordersServices: OrdersService) {}

  @Selector()
  private static state(state: OrdersStateModel) {
    return state;
  }
  state$ = this.store.select(OrdersState.state);

  private get snapshot() {
    return this.store.selectSnapshot(OrdersState.state);
  }

  public orders$: Observable<Order[]> = this.state$.pipe(map((s) => s.orders));
  public allOrders$: Observable<Order[]> = this.state$.pipe(map((s) => s.allOrders));
  public recordsFiltered$: Observable<number> = this.state$.pipe(map((s) => s.recordsFiltered));

  public get length() {
    return this.snapshot.length;
  }

  public get search() {
    return this.snapshot.search;
  }

  get hasScrollAllOrders() {
    return this.snapshot.allOrders.length == this.snapshot.recordsFiltered;
  }

  @Action(OrdersSetLength)
  public setLength(ctx: StateContext<OrdersStateModel>, action: OrdersSetLength) {
    ctx.patchState({ length: action.length });
  }

  private setLoading(loading: boolean) {
    this.store.dispatch(new SetLoading(loading, this));
  }

  @Action(OrdersGet)
  getOrders(ctx: StateContext<OrdersStateModel>, action: OrdersGet) {
    this.setLoading(true);
    return this.ordersServices.getOrders({ start: action.start, length: this.length, search: this.search }).pipe(
      tap((res) => {
        ctx.patchState({
          orders: res.data,
          recordsFiltered: parseFloat(res.recordsFiltered) || 0,
          recordsTotal: parseFloat(res.recordsTotal) || 0,
          allOrders: [...this.snapshot.allOrders, ...res.data],
        });
      }),
      finalize(() => this.setLoading(false))
    );
  }

  @Action(OrdersChangeLength)
  changeLength(ctx: StateContext<OrdersStateModel>, action: OrdersChangeLength) {
    this.setLoading(true);
    return this.ordersServices.getOrders({ start: 0, length: action.newLength, search: this.search }).pipe(
      tap((res) => {
        ctx.patchState({
          orders: res.data,
          recordsFiltered: parseFloat(res.recordsFiltered) || 0,
          recordsTotal: parseFloat(res.recordsTotal) || 0,
          length: action.newLength,
        });
      }),
      finalize(() => this.setLoading(false))
    );
  }

  @Action(OrdersFilterLogs)
  filterLogs(ctx: StateContext<OrdersStateModel>, action: OrdersFilterLogs) {
    this.setLoading(true);
    return this.ordersServices.getOrders({ start: 0, length: this.length, search: action.search }).pipe(
      tap((res) => {
        ctx.patchState({
          orders: res.data,
          allOrders: res.data,
          recordsFiltered: parseFloat(res.recordsFiltered) || 0,
          recordsTotal: parseFloat(res.recordsTotal) || 0,
          search: action.search,
        });
      }),
      finalize(() => this.setLoading(false))
    );
  }

  @Action(OrdersReset)
  private resetState(ctx: StateContext<OrdersStateModel>, action: OrdersReset) {
    ctx.setState({
      orders: [],
      allOrders: [],
      recordsTotal: 0,
      recordsFiltered: 0,
      length: 10,
      search: '',
    });
  }

  @Action(ResetState)
  resetStateByParent(ctx: StateContext<OrdersStateModel>, action: ResetState) {
    ctx.dispatch(new OrdersReset());
  }

  @Action(OrdersCancelOrder)
  private cancelOrder(ctx: StateContext<OrdersStateModel>, action: OrdersCancelOrder) {
    ctx.dispatch(new SetLoading(true, this));
    return this.ordersServices.cancelOrder(action.ordersServiceId, { cancelReason: action.cancelReason }).pipe(
      tap(() => {
        ctx.setState(
          patch({
            orders: updateItem<Order>(
              (order) => order?.ordersServicesId == action.ordersServiceId,
              patch({
                orderServiceStatus: OrderServiceStatus.CANCELED,
              })
            ),
            allOrders: updateItem<Order>(
              (order) => order?.ordersServicesId == action.ordersServiceId,
              patch({
                orderServiceStatus: OrderServiceStatus.CANCELED,
              })
            ),
          })
        );
      }),
      finalize(() => ctx.dispatch(new SetLoading(false, this)))
    );
  }
}
