import { Injectable } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { untilDestroyed } from '@ngneat/until-destroy';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { EMPTY, forkJoin, from, Observable, of, timer } from 'rxjs';
import { finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { LoadingState, SetLoading } from '../core/loading.state';
import { ToastService } from '../core/toast.service';
import { PhoneVerificationComponent, VerificationView } from '../inside/shared/dialog/phone-verification/phone-verification.component';
import {
  AppCashPaymentOptions,
  CountryCode,
  NextActionType,
  OrderServicePaymentNextAction,
  PhoneStatus,
  ResetState,
  UserAuthenticationPayload,
  UserInfo,
  UserLocation,
  UserProfilePicture,
  Vehicle,
} from '../shared';
import { AppFeaturesState } from './app-feature.state';
import { AuthService, ResetPasswordPayload, UserCreationPayload } from './auth.service';
import { UserAuthenticationResult } from './auth.service';
import { NextActionsPatch } from './next-action.state';
import { UserService } from './user.service';
import { GoogleAnalyticsService } from '../core/google-analytics.service';

export interface UserLastService {
  usersLocation: UserLocation;
  usersVehicle: Vehicle;
}

export interface AuthStateModel {
  adminMockingUser: boolean;
  token: string;
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  emailNote: string | undefined;
  phone: string;
  phoneCountryCode: string | undefined;
  phoneStatus: PhoneStatus;
  mechanics: number;
  stripeCustomerId: string;
  invitationToken: string | undefined;
  newUserInvitationToken: string | undefined;
  invitationApplied: boolean;
  phoneVerificationSent: boolean;
  timer: undefined | number;
  lastService?: UserLastService;
  userProfilePicture: UserProfilePicture | undefined;
  userCashPaymentOptions: AppCashPaymentOptions;
}

export class AuthSetNewUserInvitationToken {
  static readonly type = '@authState.setNewUserInvitationToken';
  constructor(public token: string | undefined) {}
}

export class AuthInitialiseState {
  static readonly type = '@authState.initialiseState';
  constructor(public statePayload: Partial<AuthStateModel>) {}
}

export class AuthenticateUser {
  static readonly type = '@authState.authenticateUser';
  constructor(public userPayload: UserAuthenticationPayload) {}
}

export class AuthCreateUser {
  static readonly type = '@authState.createUser';
  constructor(public userPayload: UserCreationPayload) {}
}

export class AuthResetPassword {
  static readonly type = '@authState.resetPassword';
  constructor(public resetPasswordPayload: ResetPasswordPayload) {}
}

export class AuthSendForgotPasswordLink {
  static readonly type = '@authState.sendForgotPasswordLink';
  constructor(public options: { email?: string; phoneNumber?: string; phoneVerificationCode?: string; phoneCountryCode?: CountryCode }) {}
}

export class AuthUpdateUser {
  static readonly type = '@authState.updateUserDetails';
  constructor(public userPayload: Partial<UserCreationPayload>) {}
}

export class AuthGetUser {
  static readonly type = '@authState.getUser';
  constructor() {}
}

export class AuthCreateStripeCustomer {
  static readonly type = '@authState.createStripeCustomer';
  constructor() {}
}

export class AuthCheckUser {
  static readonly type = '@authState.checkUser';
  constructor() {}
}

export class AuthResetState {
  static readonly type = '@authState.restState';
  constructor() {}
}

export class AuthRequestPhoneVerification {
  static readonly type = '@authState.requestPhoneVerification';
  constructor() {}
}

export class AuthVerifyPhone {
  static readonly type = '@authState.verifyPhone';
  constructor(public verificationCode: number) {}
}

export class AuthUpdateLastService {
  static readonly type = '@authState.updateLastService';
  constructor(public lastService: UserLastService) {}
}

export class AuthDeleteUser {
  static readonly type = '@authState.deleteUser';
  constructor(public reason: string | undefined) {}
}

export class AuthUploadProfilePicture {
  static readonly type = '@authState.uploadProfilePicture';
  constructor(public files: Blob[]) {}
}

export class AuthLogoutUser {
  static readonly type = '@authState.logoutUser';
  constructor() {}
}

export class AuthValidatePhoneNumber {
  static readonly type = '@authState.validatePhoneNumber';
}

class AuthSetPhoneVerificationTimmer {
  static readonly type = '@authState.setPhoneVerificationTimmer';
  constructor() {}
}

@State<AuthStateModel>({
  name: 'AuthState',
  defaults: {
    adminMockingUser: false,
    token: '',
    userId: 0,
    firstName: '',
    lastName: '',
    email: '',
    emailNote: undefined,
    phone: '0',
    phoneCountryCode: undefined,
    phoneStatus: PhoneStatus.NOT_VERIFIED,
    mechanics: 0,
    stripeCustomerId: '',
    invitationToken: undefined,
    newUserInvitationToken: undefined,
    invitationApplied: false,
    phoneVerificationSent: false,
    timer: undefined,
    lastService: undefined,
    userProfilePicture: undefined,
    userCashPaymentOptions: AppCashPaymentOptions.USE_DEFAULT,
  },
})
@Injectable()
export class AuthState {
  constructor(
    private store: Store,
    private authServices: AuthService,
    private navController: NavController,
    private modalController: ModalController,
    private toastService: ToastService,
    private usersService: UserService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}

  @Selector()
  public static isMechanic(state: AuthStateModel) {
    return state.mechanics == 1;
  }

  @Selector()
  public static userLastService(state: AuthStateModel) {
    return state.lastService;
  }

  @Selector()
  public static state(state: AuthStateModel) {
    return state;
  }
  state$ = this.store.select(AuthState.state);

  public get snapshot() {
    return this.store.selectSnapshot(AuthState.state);
  }

  phoneVerificationSent$ = this.state$.pipe(map((s) => s.phoneVerificationSent));
  timer$ = this.state$.pipe(map((s) => s.timer));

  @Selector()
  static userInfo(state: AuthStateModel): UserInfo {
    const profilePicture: UserProfilePicture | undefined = !state.userProfilePicture
      ? undefined
      : {
          ...state.userProfilePicture,
          filePath: `${environment.serverHost}${state.userProfilePicture.filePath}`,
          filePathM: `${environment.serverHost}${state.userProfilePicture.filePathM}`,
          filePathS: `${environment.serverHost}${state.userProfilePicture.filePathS}`,
        };
    return {
      firstName: state.firstName,
      userId: state.userId,
      lastName: state.lastName,
      email: state.email,
      emailNote: state.emailNote,
      phone: state.phone,
      phoneCountryCode: state.phoneCountryCode,
      phoneStatus: state.phoneStatus,
      mechanics: state.mechanics,
      invitationToken: state.invitationToken,
      userProfilePicture: profilePicture,
    };
  }

  public userDetails$: Observable<UserInfo> = this.store.select(AuthState.userInfo);
  public userName$: Observable<string> = this.userDetails$.pipe(map((s) => `${s.firstName} ${s.lastName}`));

  @Selector([AuthState.state, AppFeaturesState.appAllowCashAppPayment])
  public static cashPaymentAllowedForUser(state: AuthStateModel, appAllowCashAppPayment: AppCashPaymentOptions) {
    if (state.userCashPaymentOptions === AppCashPaymentOptions.USE_CASH_PAYMENT) {
      return true;
    } else if (state.userCashPaymentOptions === AppCashPaymentOptions.DO_NOT_USE_CASH_PAYMENT) {
      return false;
    } else if (state.userCashPaymentOptions === AppCashPaymentOptions.USE_DEFAULT) {
      if (appAllowCashAppPayment === AppCashPaymentOptions.USE_CASH_PAYMENT) {
        return true;
      } else if (
        appAllowCashAppPayment === AppCashPaymentOptions.USE_DEFAULT ||
        appAllowCashAppPayment === AppCashPaymentOptions.DO_NOT_USE_CASH_PAYMENT
      ) {
        return false;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public get token() {
    return this.snapshot.token;
  }

  public get invitationToken() {
    return this.snapshot.invitationToken;
  }

  public get userId() {
    return this.snapshot.userId;
  }

  invitationApplied$ = this.state$.pipe(map((state) => state.invitationApplied));

  newUserInvitationToken$ = this.state$.pipe(map((s) => s.newUserInvitationToken));
  get newUserInvitationToken() {
    return this.snapshot.newUserInvitationToken;
  }

  @Action(AuthSetNewUserInvitationToken)
  setNewUserInvitationToken(ctx: StateContext<AuthStateModel>, action: AuthSetNewUserInvitationToken) {
    const { token } = action;
    let invitationApplied = false;
    if (token) {
      invitationApplied = true;
    }
    ctx.patchState({ newUserInvitationToken: token, invitationApplied });
  }

  private checkToken(ctx: StateContext<AuthStateModel>) {
    this.setLoading(true);
    return this.authServices.checkToken().pipe(
      tap({
        error: () => {
          this.authServices.logoutUser();
          this.navController.navigateRoot('/home-page');
        },
      }),
      switchMap((data) => ctx.dispatch(new AuthGetUser()).pipe(map(() => data))),
      finalize(() => this.setLoading(false))
    );
  }

  @Action(AuthCheckUser)
  checkUser(ctx: StateContext<AuthStateModel>, action: AuthCheckUser) {
    const state = ctx.getState();
    const token = state.token || this.authServices.getToken();
    const userId = state.userId ? state.userId.toString() : localStorage.getItem('allrotors_users_id');

    if (!token || !userId) {
      this.authServices.logoutUser();
      this.navController.navigateRoot('/home-page');
      return;
    }
    return this.checkToken(ctx).pipe(
      tap(({ data, allowAppCashPayment }) => {
        const statePayload: Partial<AuthStateModel> = {
          token,
          userId: parseFloat(userId),
          firstName: data.fname,
          lastName: data.lname,
          email: data.email,
          phone: data.phone.toString().replace(/[^0-9]*/g, ''),
          phoneCountryCode: data.phoneCountryCode,
          phoneStatus: data.phoneStatus,
          mechanics: data.mechanic,
          stripeCustomerId: data.stripeCustomerId,
          invitationToken: data.invitationToken,
          userCashPaymentOptions: allowAppCashPayment,
        };
        this.store.dispatch(new AuthInitialiseState(statePayload));
      }),
      switchMap(({ data }) => from(this.googleAnalyticsService.setUserId(data.usersId)))
    );
  }

  loading$ = this.store.select(LoadingState.isLoading(this.constructor.name));

  private setLoading(loading: boolean) {
    this.store.dispatch(new SetLoading(loading, this));
  }

  @Action(AuthInitialiseState)
  private initState(ctx: StateContext<AuthStateModel>, action: AuthInitialiseState) {
    ctx.patchState(action.statePayload);
  }

  @Action(AuthenticateUser)
  public authenticateUser(ctx: StateContext<AuthStateModel>, action: AuthenticateUser): Observable<UserAuthenticationResult> {
    this.setLoading(true);
    return this.authServices.authenticateUser(action.userPayload).pipe(
      switchMap((userDetails: UserAuthenticationResult) => {
        ctx.patchState({
          token: userDetails.token || '',
          userId: userDetails.usersId,
        });
        return this.checkToken(ctx).pipe(
          tap(async ({ data, allowAppCashPayment }) => {
            const statePayload: Partial<AuthStateModel> = {
              firstName: data.fname,
              lastName: data.lname,
              email: data.email,
              phone: data.phone.toString().replace(/[^0-9]*/g, ''),
              phoneStatus: data.phoneStatus,
              mechanics: data.mechanic,
              stripeCustomerId: data.stripeCustomerId,
              userCashPaymentOptions: allowAppCashPayment,
            };
            ctx.dispatch(new AuthInitialiseState(statePayload));

            if (data.phoneStatus !== PhoneStatus.NOT_VERIFIED) {
              return;
            }
            ctx.dispatch(new AuthValidatePhoneNumber());
          }),
          map(() => userDetails)
        );
      }),
      finalize(() => this.setLoading(false))
    );
  }

  @Action(AuthValidatePhoneNumber)
  async validatePhoneNumber(ctx: StateContext<AuthStateModel>) {
    const state = ctx.getState();
    if (state.phoneStatus === PhoneStatus.NOT_VERIFIED) {
      const modal = await this.modalController.create({
        component: PhoneVerificationComponent,
        componentProps: {
          selectedView: VerificationView.VERIFY_MOBILE,
        },
        cssClass: this.store.selectSnapshot(AppFeaturesState.state).isApp ? '' : 'phone-verification-modal',
      });
      await modal.present();
    }
  }

  @Action(AuthCreateUser)
  public createUser(ctx: StateContext<AuthStateModel>, action: AuthCreateUser) {
    this.setLoading(true);
    return this.authServices.createUser(action.userPayload).pipe(
      tap(async (res) => {
        if (res.data) {
          ctx.patchState({ userId: res.data.userId, token: res.data.token, phone: res.data.phone });
        }
        ctx.dispatch(new AuthSetNewUserInvitationToken(undefined));
        ctx.dispatch(new AuthSetPhoneVerificationTimmer());
        await this.toastService.showToast(true, { message: res.message });
      }),
      switchMap(() => ctx.dispatch(new AuthCheckUser())),
      finalize(() => this.setLoading(false))
    );
  }

  @Action(AuthSendForgotPasswordLink)
  public sendForgotPasswordLink(ctx: StateContext<AuthStateModel>, action: AuthSendForgotPasswordLink) {
    this.setLoading(true);
    const { options } = action;
    return this.authServices.sendForgotPasswordLink(options).pipe(finalize(() => this.setLoading(false)));
  }

  @Action(AuthResetPassword)
  public resetPassword(ctx: StateContext<AuthStateModel>, action: AuthResetPassword) {
    this.setLoading(true);
    return this.authServices.resetPassword(action.resetPasswordPayload).pipe(finalize(() => this.setLoading(false)));
  }

  @Action(AuthUpdateUser)
  public updateUserDetails(ctx: StateContext<AuthStateModel>, action: AuthUpdateUser) {
    this.setLoading(true);
    const { userPayload } = action;
    return this.authServices.updateUser(this.snapshot.userId, userPayload, this.token).pipe(
      tap(() => {
        const invitationApplied = userPayload.fieldsToUpdate?.find((field) => field === 'invitation_token') ? true : false;
        const updatedInstance: Partial<AuthStateModel> = {
          firstName: userPayload.fname ?? this.snapshot.firstName,
          lastName: userPayload.lname ?? this.snapshot.lastName,
          email: userPayload.email ?? this.snapshot.email,
          phone: userPayload.phone ?? this.snapshot.phone,
          invitationApplied: invitationApplied ?? this.snapshot.invitationApplied,
        };
        if (updatedInstance.phone !== this.snapshot.phone) {
          ctx.dispatch(new AuthSetPhoneVerificationTimmer());
        }
        ctx.patchState(updatedInstance);
      }),
      finalize(() => this.setLoading(false))
    );
  }

  @Action(AuthGetUser)
  getUser(ctx: StateContext<AuthStateModel>, action: AuthGetUser) {
    const state = ctx.getState();
    const userId = state.userId || parseFloat(localStorage.getItem('allrotors_users_id') || '');

    if (!userId) {
      return of(undefined);
    }
    return this.authServices.getUser(userId).pipe(
      tap((userInfo) => {
        if (!userInfo) {
          return;
        }
        ctx.patchState({
          firstName: userInfo.fname,
          lastName: userInfo.lname,
          phone: userInfo.phone.toString().replace(/[^0-9]*/g, ''),
          email: userInfo.email,
          emailNote: userInfo.emailNote,
          userProfilePicture: userInfo.userProfilePicture,
        });
        const nextActions = userInfo.incompleteOrderPayments.map((incompleteOrderAction) => {
          const nextAction: OrderServicePaymentNextAction = {
            type: NextActionType.ORDER_SERVICE_PAYMENT_AUTHENTICATION,
            callbackAction: undefined,
            details: incompleteOrderAction,
          };
          return nextAction;
        });
        ctx.dispatch(new NextActionsPatch({ nextActions }));
      }),
      switchMap((userInfo) => {
        if (!userInfo.lastService) {
          return of(undefined);
        }
        const observables = [
          this.usersService.getVehicles(userId, userInfo.lastService.usersVehiclesId).pipe(map((res) => res.arAutos[0])),
          this.usersService.getUserLocations(userId, userInfo.lastService.usersLocationsId).pipe(map((res) => res.data[0])),
        ];
        return forkJoin(observables);
      }),
      tap((res: any) => {
        if (!res) {
          return;
        }
        const [usersVehicle, usersLocation] = res;
        const lastService: UserLastService = {
          usersLocation,
          usersVehicle,
        };
        ctx.patchState({ lastService });
      })
    );
  }

  @Action(AuthCreateStripeCustomer)
  createStripeCustomerForUser(ctx: StateContext<AuthStateModel>, action: AuthCreateStripeCustomer) {
    if (this.snapshot.stripeCustomerId) {
      return EMPTY;
    }
    this.setLoading(true);
    return this.authServices.updateUser(this.snapshot.userId, { addStripeCustomerId: 1, fieldsToUpdate: ['add_stripe_customer_id'] }).pipe(
      tap((res) => {
        if (!res.stripeCustomerId) {
          return;
        }
        ctx.patchState({ stripeCustomerId: res.stripeCustomerId });
      }),
      finalize(() => this.setLoading(false))
    );
  }

  @Action(ResetState)
  resetStateByParent(ctx: StateContext<AuthStateModel>, action: ResetState) {
    ctx.dispatch(new AuthResetState());
  }

  @Action(AuthResetState)
  private resetState(ctx: StateContext<AuthStateModel>, action: AuthResetState) {
    this.destroyPreviousTimer();
    ctx.patchState({
      token: '',
      userId: 0,
      firstName: '',
      lastName: '',
      email: '',
      emailNote: undefined,
      phone: '0',
      mechanics: 0,
      stripeCustomerId: '',
      invitationToken: undefined,
      newUserInvitationToken: undefined,
      invitationApplied: false,
      timer: 0,
      phoneStatus: PhoneStatus.NOT_VERIFIED,
      phoneVerificationSent: false,
      lastService: undefined,
      userProfilePicture: undefined,
    });
  }

  // To Destroy previous timmer observables
  destroyPreviousTimer() {}

  @Action(AuthSetPhoneVerificationTimmer)
  private setTimmer(ctx: StateContext<AuthStateModel>) {
    this.destroyPreviousTimer();
    ctx.patchState({ phoneVerificationSent: true, timer: 0, phoneStatus: PhoneStatus.NOT_VERIFIED });
    setTimeout(() => {
      ctx.patchState({ phoneVerificationSent: false, timer: undefined });
    }, 305000);
    return timer(0, 60000).pipe(
      take(5),
      untilDestroyed(this, 'destroyPreviousTimer'),
      tap((t) => {
        ctx.patchState({ timer: t });
      })
    );
  }

  @Action(AuthRequestPhoneVerification)
  private requestPhoneVerification(ctx: StateContext<AuthStateModel>, action: AuthRequestPhoneVerification) {
    this.setLoading(true);
    return this.authServices.requestPhoneVerification(this.userId, this.token).pipe(
      tap(async (res) => {
        await this.toastService.showToast(this.store.selectSnapshot(AppFeaturesState.state).isApp, {
          message: res.message,
        });
        ctx.dispatch(new AuthSetPhoneVerificationTimmer());
      }),
      finalize(() => this.setLoading(false))
    );
  }

  @Action(AuthVerifyPhone)
  private verifyPhone(ctx: StateContext<AuthStateModel>, action: AuthVerifyPhone) {
    this.setLoading(true);
    return this.authServices.verifyPhoneCode(this.userId, action.verificationCode, this.token).pipe(
      tap((res) => {
        this.toastService.showToast(this.store.selectSnapshot(AppFeaturesState.state).isApp, {
          message: res.message,
        });
        ctx.patchState({ phoneStatus: res.data.phoneStatus });
      }),
      finalize(() => this.setLoading(false))
    );
  }

  @Action(AuthUpdateLastService)
  private updateLastService(ctx: StateContext<AuthStateModel>, action: AuthUpdateLastService) {
    ctx.patchState({ lastService: action.lastService });
  }

  @Action(AuthDeleteUser)
  private deleteUser(ctx: StateContext<AuthStateModel>, action: AuthDeleteUser) {
    ctx.dispatch(new SetLoading(true, this));
    return this.authServices.deleteAccount(this.userId, { reason: action.reason }).pipe(
      tap(() => {
        this.authServices.logoutUser();
        ctx.dispatch(new AuthResetState());
        this.navController.navigateRoot('home-page');
      }),
      finalize(() => ctx.dispatch(new SetLoading(false, this)))
    );
  }

  @Action(AuthUploadProfilePicture)
  private uploadProfilePicture(ctx: StateContext<AuthStateModel>, action: AuthUploadProfilePicture) {
    ctx.dispatch(new SetLoading(true, this, true));
    return this.authServices.uploadProfilePicture(this.userId, action.files).pipe(
      tap((data) => {
        const [profilePicture] = data.pictures;
        ctx.patchState({
          userProfilePicture: profilePicture,
        });
      }),
      finalize(() => ctx.dispatch(new SetLoading(false, this, false)))
    );
  }

  @Action(AuthLogoutUser)
  private logoutUser(ctx: StateContext<AuthStateModel>, action: AuthLogoutUser) {
    this.authServices.logoutUser();
    this.store.dispatch(new ResetState());
    this.navController.navigateRoot('home-page');
  }
}
