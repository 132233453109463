import { Directive, Input, OnInit, OnDestroy } from '@angular/core';
import { IonInput } from '@ionic/angular';
import { createTextMaskInputElement, conformToMask } from 'text-mask-core';
import { NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[appPhoneMask]',
  providers: [IonInput],
})
export class PhoneMaskDirective implements OnInit {
  constructor(public ionInput: IonInput, public ngControl: NgControl) {}

  public ngOnInit() {
    this.configureInput();
  }

  public async configureInput() {
    const input = await this.ionInput.getInputElement();
    if (this.ngControl) {
      const maskedInput = createTextMaskInputElement({
        inputElement: input,
        mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
        guide: false,
      });
      this.ionInput.ionChange.pipe(untilDestroyed(this)).subscribe((event: CustomEvent) => {
        const { value } = event.detail;
        const valueWithNumber = value.replace(/[^0-9]+/g, '');
        if (valueWithNumber >= 11) {
          const updatedValue = valueWithNumber.replace(/^[+]?1|^[+]?01|^[+]?0/, '');
          maskedInput.update(updatedValue);
        } else {
          maskedInput.update(value);
        }
        this.ngControl.control?.patchValue(input.value);
      });
    }
  }
}
