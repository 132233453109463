<ion-header class="ion-no-border html-background-light-tint">
  <ion-toolbar
    class="background-light-tint toolbar"
    [ngClass]="{
      'ion-margin-top': appFeaturesState.isNativeIos,
      'pl-16': appFeaturesState.isNativeIos,
      'pt-0': appFeaturesState.isAndroid
    }"
  >
    <strong [ngClass]="{ 'pl-8': !appFeaturesState.isIos }"> Secure Authorization {{ title ? '(' + title + ')' : '' }} </strong>
    <ion-button
      fill="clear"
      slot="end"
      class="ion-no-padding"
      size="medium"
      (click)="closeModal()"
      [ngClass]="{ 'pr-8': !appFeaturesState.isIos }"
    >
      <ion-icon size="large" color="secondary" name="close"></ion-icon>
    </ion-button>
  </ion-toolbar>
  <p
    class="mt-0 mb-5 ion-padding-end"
    [ngClass]="{
      'pr-16': appFeaturesState.isAndroid,
      'pl-12': appFeaturesState.isIos,
      'pl-10': !appFeaturesState.isApp || appFeaturesState.isAndroid
    }"
  >
    {{ message }}
  </p>
</ion-header>
<ion-content [scrollEvents]="true" (ionScrollStart)="handleIonScrollStart()">
  <iframe height="100%" width="100%" [src]="sanitizedUrl"></iframe>
</ion-content>
