import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { BehaviorSubject } from 'rxjs';
import { ToastService } from '../../core/toast.service';
import { AppFeaturesState } from '../../portal/app-feature.state';
import { ReviewFormReset, ReviewFormState, ReviewFormSubmit } from './review-form.state';

enum ViewState {
  MESSAGE_RESPONSE = 'message_response',
  FORM = 'form',
}

@Component({
  selector: 'app-review-form',
  templateUrl: './review-form.component.html',
  styleUrls: ['./review-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewFormComponent {
  rating$: BehaviorSubject<number | undefined> = new BehaviorSubject<number | undefined>(undefined);
  reviewForm: { comment?: string } = {};
  ViewState = ViewState;

  message$ = this.store.select(ReviewFormState.message);
  viewState$ = new BehaviorSubject<ViewState>(ViewState.FORM);

  constructor(
    public store: Store,
    public appFeaturesState: AppFeaturesState,
    private modalController: ModalController,
    private toastService: ToastService
  ) {}

  async closeModal() {
    this.store.dispatch(new ReviewFormReset());
    await this.modalController.dismiss();
  }

  ionViewWillEnter() {
    this.viewState$.next(ViewState.FORM);
  }

  ionViewDidLeave() {
    this.store.dispatch(new ReviewFormReset());
  }

  async submitReview() {
    const stars = this.rating$.value;
    if (!stars) {
      this.toastService.showToast(this.appFeaturesState.isApp, {
        message: 'Please select a rating',
      });
      return;
    }
    const comment = this.reviewForm.comment;
    this.store.dispatch(new ReviewFormSubmit(stars, comment));
    if (stars !== 5) {
      await this.closeModal();
    } else {
      this.viewState$.next(ViewState.MESSAGE_RESPONSE);
    }
  }
}
