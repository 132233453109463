import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorViewComponent } from './error-view.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [ErrorViewComponent],
  imports: [CommonModule, IonicModule],
  exports: [ErrorViewComponent],
})
export class ErrorViewModule {}
