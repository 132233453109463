import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators, ValidationErrors } from '@angular/forms';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { PolicyViewComponentComponent, PolicyViewType } from '../../company-policies/policy-view-component/policy-view-component.component';
import { LoadingState } from '../../core/loading.state';
import { PhoneVerificationComponent, VerificationView } from '../../inside/shared/dialog/phone-verification/phone-verification.component';
import { AppFeaturesState, AppGetSupportedCountryCodes } from '../../portal/app-feature.state';
import { AuthCreateUser, AuthSendForgotPasswordLink, AuthState } from '../../portal/auth.state';
import { AllRotorsCustomEvent, CountryCode } from '../../shared';
import { ToastService } from '../../core/toast.service';
import { INVALID_FORM_MESSAGE } from '../../shared/function';
import { GoogleAnalyticsService, LogEventType } from '../../core/google-analytics.service';

enum ContactMethods {
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
}

enum ForgotPasswordSteps {
  CONTACT_METHOD = 'contactMethod',
  VERIFY_CODE = 'verifyCode',
  RESET_PASSWORD = 'resetPassword',
}

@UntilDestroy()
@Component({
  selector: 'app-signup-forgot-password',
  templateUrl: './signup-forgot-password.component.html',
  styleUrls: ['./signup-forgot-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignupForgotPasswordComponent implements OnInit {
  @Input() isSignUp: boolean;
  @Input() invitationToken: string | undefined;
  @Input() underIframe: boolean;

  supportedCountryCodes$ = this.store.select(AppFeaturesState.countryCodes);

  contactMethods = ContactMethods;
  contactMethod: ContactMethods;

  forgotPasswordSteps = ForgotPasswordSteps;
  forgotPasswordStep: ForgotPasswordSteps;

  signUpFormGroup: UntypedFormGroup = this.buildSignUpFormGroup();
  forgotPasswordFormGroup: UntypedFormGroup = this.buildForgotPasswordFormGroup();
  passwordMatched: boolean = true;

  constructor(
    private fb: UntypedFormBuilder,
    private modalController: ModalController,
    public authState: AuthState,
    public loadingState: LoadingState,
    private alertController: AlertController,
    public appFeaturesState: AppFeaturesState,
    private store: Store,
    private toastService: ToastService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.store.dispatch(new AppGetSupportedCountryCodes());
  }

  async handleIonScrollStart() {
    await this.googleAnalyticsService.logEvent({ name: LogEventType.SCROLL });
  }

  ngOnInit() {
    if (!this.invitationToken) {
      this.signUpFormGroup.patchValue({
        invitationToken: '',
        fieldsToUpdate: this.signUpFormGroup.controls.fieldsToUpdate.value.filter((field: string) => field !== 'invitation_token'),
      });
      return;
    }
    const fieldsToUpdate = this.signUpFormGroup.controls.fieldsToUpdate.value;
    fieldsToUpdate.push('invitation_token');
    this.signUpFormGroup.patchValue({
      invitationToken: this.invitationToken,
      fieldsToUpdate,
    });
  }

  ionViewWillEnter() {
    this.forgotPasswordStep = ForgotPasswordSteps.CONTACT_METHOD;
  }

  private buildForgotPasswordFormGroup() {
    const supportedCountryCodesCanada = this.store.selectSnapshot(AppFeaturesState.countryCodes).find((c) => c.name === 'Canada');
    const form = this.fb.group(
      {
        email: ['', { validators: [Validators.email] }],
        phoneNumber: [undefined, { validators: [Validators.maxLength(14), Validators.minLength(14)] }],
        countryCode: [supportedCountryCodesCanada],
        verificationCode: [undefined, { validators: [Validators.maxLength(6), Validators.minLength(6)] }],
      },
      {
        validators: [
          (group: UntypedFormGroup) => {
            if (this.forgotPasswordStep === ForgotPasswordSteps.CONTACT_METHOD && !group.value.phoneNumber && !group.value.email) {
              return { invalid: true };
            }

            if (this.forgotPasswordStep === ForgotPasswordSteps.VERIFY_CODE && !group.value.verificationCode) {
              return { invalid: true };
            }
            return null;
          },
        ],
      }
    );

    return form;
  }

  private buildSignUpFormGroup() {
    const passwordFc = this.fb.control('', {
      validators: [Validators.required, Validators.minLength(6)],
    });
    const passwordConfirmationFc = this.fb.control('', {
      validators: [this.confirmPasswordValidator.bind(this, passwordFc)],
    });
    passwordFc.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      passwordConfirmationFc.updateValueAndValidity({
        emitEvent: false,
        onlySelf: true,
      });
    });
    const supportedCountryCodesCanada = this.store.selectSnapshot(AppFeaturesState.countryCodes).find((c) => c.name === 'Canada');
    const form = this.fb.group(
      {
        fname: ['', { validators: [Validators.required] }],
        lname: ['', { validators: [Validators.required] }],
        phone: ['', { validators: [Validators.required, Validators.maxLength(14), Validators.minLength(14)] }],
        countryCode: [supportedCountryCodesCanada],
        email: ['', { validators: [Validators.required, Validators.email] }],
        password: passwordFc,
        password2: passwordConfirmationFc,
        fieldsToUpdate: [['fname', 'lname', 'phone', 'email', 'password', 'phone_country_code']],
        invitationToken: [''],
        acceptPolicy: [undefined, { validators: [Validators.required, Validators.requiredTrue] }],
      },
      {
        updateOn: 'change',
      }
    );
    return form;
  }

  private confirmPasswordValidator(passwordFc: AbstractControl, confirmPasswordFc: AbstractControl): ValidationErrors | null {
    const password = passwordFc.value;
    const confirmPassword = confirmPasswordFc.value;
    const error = confirmPassword === password ? null : { doNotMatch: true };
    if (error) {
      this.passwordMatched = false;
    } else {
      this.passwordMatched = true;
    }
    return error;
  }

  public async closeModal() {
    if (this.underIframe) {
      window.parent.postMessage(AllRotorsCustomEvent.SIGNUP_CLOSE_MODAL, '*');
      return;
    }
    await this.modalController.dismiss();
  }

  public async insertUser() {
    if (this.signUpFormGroup.invalid) {
      this.signUpFormGroup.markAllAsTouched();
      this.toastService.showToast(this.appFeaturesState.isApp, {
        message: INVALID_FORM_MESSAGE,
      });
      return;
    }
    const formValue = this.signUpFormGroup.value;
    formValue.phone = formValue.phone?.toString()?.replace(/[^0-9]*/g, '');
    this.store
      .dispatch(new AuthCreateUser(this.signUpFormGroup.value))
      .pipe(
        tap(async () => {
          if (this.underIframe) {
            window.parent.postMessage(AllRotorsCustomEvent.SIGNUP_NAVIGATE_HOME, '*');
            return;
          }
          await this.closeModal();
          const modal = await this.modalController.create({
            component: PhoneVerificationComponent,
            componentProps: {
              selectedView: VerificationView.VERIFY_MOBILE,
              moveToUrl: `/`,
            },
            cssClass: this.appFeaturesState.isApp ? '' : 'phone-verification-modal',
          });
          await modal.present();
        })
      )
      .subscribe();
  }

  public async resetPassword() {
    if (this.forgotPasswordFormGroup.invalid) {
      return;
    }
    this.store
      .dispatch(
        new AuthSendForgotPasswordLink({
          email: this.forgotPasswordFormGroup.value.email,
          phoneNumber: this.forgotPasswordFormGroup.value.phoneNumber,
          phoneCountryCode: this.forgotPasswordFormGroup.value.countryCode,
        })
      )
      .pipe(
        tap(() => {
          if (this.forgotPasswordFormGroup.controls.phoneNumber.valid && this.forgotPasswordFormGroup.value.phoneNumber) {
            this.forgotPasswordStep = ForgotPasswordSteps.VERIFY_CODE;
          }
        }),
        tap(async () => {
          const alert = await this.alertController.create({
            subHeader: 'Password reset',
            message: 'Password reset instructions has been sent to you',

            buttons: ['Ok'],
          });
          await alert.present();
          await alert.onDidDismiss();

          if (this.forgotPasswordFormGroup.controls.phoneNumber.valid && this.forgotPasswordFormGroup.value.phoneNumber) {
            return;
          }
          this.closeModal();
        })
      )
      .subscribe();
  }

  async navigateToTermsAndConditions() {
    if (this.underIframe) {
      window.parent.postMessage(AllRotorsCustomEvent.OPEN_TERMS_AND_CONDITION, '*');
      return;
    }
    const modal = await this.modalController.create({
      component: PolicyViewComponentComponent,
      showBackdrop: true,
      cssClass: this.appFeaturesState.isApp ? '' : 'policy-view',
      componentProps: {
        viewType: PolicyViewType.TERMS_AND_CONDITION,
      },
    });
    await modal.present();
  }

  async navigateToPrivacyPolicy() {
    if (this.underIframe) {
      window.parent.postMessage(AllRotorsCustomEvent.OPEN_PRIVACY_POLICY, '*');
      return;
    }
    const modal = await this.modalController.create({
      component: PolicyViewComponentComponent,
      showBackdrop: true,
      cssClass: this.appFeaturesState.isApp ? '' : 'policy-view',
      componentProps: {
        viewType: PolicyViewType.PRIVACY_POLICIES,
      },
    });
    await modal.present();
  }

  switchMethod(method: ContactMethods) {
    if (method === ContactMethods.EMAIL) {
      this.forgotPasswordFormGroup.patchValue({
        phoneNumber: undefined,
      });
    } else if (method === ContactMethods.PHONE_NUMBER) {
      this.forgotPasswordFormGroup.patchValue({
        email: undefined,
      });
    }
  }

  verifyPhoneNumber() {
    if (this.forgotPasswordFormGroup.invalid) {
      this.forgotPasswordFormGroup.markAllAsTouched();
      return;
    }
    const form = this.forgotPasswordFormGroup.value;
    this.store
      .dispatch(
        new AuthSendForgotPasswordLink({
          phoneNumber: form.phoneNumber,
          phoneVerificationCode: form.verificationCode,
          phoneCountryCode: form.countryCode,
        })
      )
      .pipe(
        tap(() => {
          this.forgotPasswordStep = ForgotPasswordSteps.RESET_PASSWORD;
        })
      )
      .subscribe();
  }

  countryCodeCompareWith(o1: CountryCode, o2: CountryCode) {
    return o1 && o2 ? o1.name === o2.name : o1 === o2;
  }
}
