import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthCheckUser, AuthState } from '../portal/auth.state';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanLoad {
  constructor(private navController: NavController, private authState: AuthState, private store: Store) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.isAuthenticated();
  }

  private isAuthenticated(): Observable<boolean> {
    const authenticated =
      this.store.dispatch(new AuthCheckUser())?.pipe(
        map(() => {
          return this.store.selectSnapshot(AuthState.state).userId ? true : false;
        })
      ) || of(false);

    authenticated.pipe(
      tap((auth) => {
        if (!auth) {
          this.navController.navigateRoot('/home-page');
        }
      })
    );
    return authenticated;
  }
}
