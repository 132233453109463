import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AllRotorsCustomEvent } from '../../shared/enum';
import { GoogleAnalyticsService, LogEventType } from '../../core/google-analytics.service';

@Component({
  selector: 'app-sign-up-form-container',
  template: `
    <ion-content [scrollEvents]="true" (ionScrollStart)="handleIonScrollStart()">
      <app-signup-forgot-password [underIframe]="true" [isSignUp]="true"></app-signup-forgot-password>
    </ion-content>
  `,
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignUpFormContainerComponent {
  constructor(private googleAnalyticsService: GoogleAnalyticsService) {}

  ionViewWillEnter() {
    window.parent.postMessage(AllRotorsCustomEvent.COMPONENT_LOADED, '*');
  }

  async handleIonScrollStart() {
    await this.googleAnalyticsService.logEvent({ name: LogEventType.SCROLL });
  }
}
