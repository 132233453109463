import { Component, EnvironmentInjector, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavController, Platform } from '@ionic/angular';
import { of, Subscription } from 'rxjs';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { AppFeaturesState } from './portal/app-feature.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ToastService } from './core/toast.service';
import { LoadingState } from './core/loading.state';
import { map, startWith } from 'rxjs/operators';
import { AuthLogoutUser, AuthState } from './portal/auth.state';
import { Store } from '@ngxs/store';
import { AppFeaturesService } from './portal/app-features.service';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
  title = 'allrotors';
  backButtonSubscription: Subscription;
  initialiseAdminMockingLogoutSubscription: Subscription;

  networkConnectionToast: HTMLIonToastElement;

  loading$ = this.authState.loading$.pipe(
    startWith(true),
    map((loading) => {
      if (this.router.url !== '/') {
        return false;
      }
      return loading;
    })
  );

  loadingWithSpinner$ = this.store.select(LoadingState.loadingWithSpinner);

  constructor(
    private platform: Platform,
    private navController: NavController,
    private router: Router,
    private appFeaturesState: AppFeaturesState,
    private toastService: ToastService,
    public loadingState: LoadingState,
    private authState: AuthState,
    private zone: NgZone,
    private store: Store,
    private appFeaturesService: AppFeaturesService,
    public environmentInjector: EnvironmentInjector
  ) {
    this.initialiseBackButton();
    this.initialiseNetworkConnectionCheck();
    this.initialiseUrlOpenListener();
    this.initialiseAdminMockingLogout();
  }

  ngOnInit(): void {
    if (!this.appFeaturesState.isApp) {
      return;
    }
  }

  private initialiseBackButton() {
    this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(10, () => {
      if (this.router.url === '/home-page') {
        App.exitApp();
        return;
      }
      this.navController.back();
    });
  }

  private initialiseNetworkConnectionCheck() {
    this.appFeaturesState.internetAvailable$.pipe(untilDestroyed(this)).subscribe(async (internetAvailable) => {
      if (internetAvailable === undefined) {
        return;
      }
      if (internetAvailable) {
        if (this.networkConnectionToast) {
          await this.networkConnectionToast.dismiss();
        }
        return;
      }
      this.networkConnectionToast = await this.toastService.showToast(true, {
        message: 'Internet connection not available',
        buttons: [],
        duration: undefined,
      });
    });
  }

  private initialiseUrlOpenListener() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        this.appFeaturesService.inAppNavigationHandler(event.url);
      });
    });
  }

  private initialiseAdminMockingLogout() {
    this.initialiseAdminMockingLogoutSubscription = this.platform.pause.subscribe(() => {
      const isAdminMockingUser = this.store.selectSnapshot(AuthState.state).adminMockingUser;
      if (!isAdminMockingUser) {
        return;
      }
      this.store.dispatch(new AuthLogoutUser());
    });
  }

  ngOnDestroy() {
    this.backButtonSubscription.unsubscribe();
    this.initialiseAdminMockingLogoutSubscription.unsubscribe();
  }
}
