import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, ModalController } from '@ionic/angular';
import { AppFeaturesState } from '../../portal/app-feature.state';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { GoogleAnalyticsService, LogEventType } from '../../core/google-analytics.service';

@Component({
  selector: 'app-iframe-modal',
  standalone: true,
  imports: [CommonModule, IonicModule],
  templateUrl: './iframe-modal.component.html',
  styleUrls: ['./iframe-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IframeModalComponent implements OnInit {
  @Input() url: string;
  @Input() message: string | undefined;
  @Input() title: string | undefined;

  sanitizedUrl: SafeUrl | undefined;

  constructor(
    public appFeaturesState: AppFeaturesState,
    private modalController: ModalController,
    private domSanitizer: DomSanitizer,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}

  async handleIonScrollStart() {
    await this.googleAnalyticsService.logEvent({ name: LogEventType.SCROLL });
  }

  ngOnInit(): void {
    if (!this.url) {
      throw Error('A valid url is missing');
    }
    this.sanitizedUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  async closeModal() {
    await this.modalController.dismiss();
  }
}
