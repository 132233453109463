import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { BehaviorSubject, map, of, take } from 'rxjs';
import { ResetState } from '../../../shared';

interface GoogleMapsStateModel {
  predictions: google.maps.places.AutocompletePrediction[];
  selectedPlace: google.maps.places.PlaceResult | undefined;
}

export class GoogleMapStateSetPrediction {
  static readonly type = '@googleMapState.setPredictions';
  constructor(public predictions: google.maps.places.AutocompletePrediction[] | null) {}
}

export class GoogleMapStateGetLocationDetails {
  static readonly type = '@googleMapState.getLocationDetails';
  constructor(public placeId: string) {}
}

@State<GoogleMapsStateModel>({
  name: 'GoogleMapsState',
  defaults: {
    predictions: [],
    selectedPlace: undefined,
  },
})
@Injectable()
export class GoogleMapsState {
  constructor(private store: Store) {}

  @Selector()
  private static state(state: GoogleMapsStateModel) {
    return state;
  }
  state$ = this.store.select(GoogleMapsState.state);

  private get snapshot() {
    return this.store.selectSnapshot(GoogleMapsState.state);
  }

  @Selector()
  static predictions(state: GoogleMapsStateModel) {
    return state.predictions;
  }
  predictions$ = this.store.select(GoogleMapsState.predictions);

  @Selector()
  static selectedPlace(state: GoogleMapsStateModel) {
    return state.selectedPlace;
  }
  selectedPlace$ = this.store.select(GoogleMapsState.selectedPlace);

  @Action(GoogleMapStateSetPrediction)
  setPredictions(ctx: StateContext<GoogleMapsStateModel>, action: GoogleMapStateSetPrediction) {
    if (!action.predictions) {
      ctx.patchState({ predictions: [] });
      return;
    }
    ctx.patchState({ predictions: action.predictions });
  }

  @Action(GoogleMapStateGetLocationDetails)
  getLocationDetails(ctx: StateContext<GoogleMapsStateModel>, action: GoogleMapStateGetLocationDetails) {
    // take one result and then finish
    const result = new BehaviorSubject<undefined | google.maps.places.PlaceResult>(undefined);

    const service = new google.maps.places.PlacesService(document.createElement('div'));
    service.getDetails(
      { placeId: action.placeId, fields: ['place_id', 'geometry', 'address_components'] },
      (place: google.maps.places.PlaceResult | null, status: google.maps.places.PlacesServiceStatus) => {
        console.log('place', place);
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
          throw Error('An error occurred');
        }
        if (!place) {
          ctx.patchState({ selectedPlace: undefined });
          result.next(undefined);
          result.complete();
          return;
        }
        ctx.patchState({ selectedPlace: place });
        result.next(place);
        result.complete();
      }
    );
    return result;
  }
  googleMapService: google.maps.places.AutocompleteService;
  getPlacePredictions(input: string) {
    if (!this.googleMapService) {
      this.googleMapService = new google.maps.places.AutocompleteService();
    }
    this.googleMapService.getPlacePredictions(
      {
        input,
        componentRestrictions: {
          country: 'ca',
        },
      },
      (predictions: google.maps.places.AutocompletePrediction[] | null, status: google.maps.places.PlacesServiceStatus) => {
        if (status != google.maps.places.PlacesServiceStatus.OK) {
          this.store.dispatch(new GoogleMapStateSetPrediction(null));
          return;
        }
        console.log(predictions);
        this.store.dispatch(new GoogleMapStateSetPrediction(predictions));
      }
    );
  }

  @Action(ResetState)
  resetState(ctx: StateContext<GoogleMapsStateModel>, action: ResetState) {
    ctx.setState({
      predictions: [],
      selectedPlace: undefined,
    });
  }
}
