import { Order } from '../../../shared';
import { OrderDetailsContainerStateModel } from './order-details-container.state';

export class OrderDetailsContainerUpdateOrder {
  static readonly type = '@orderDetailsContainer.updateOrder';
  constructor(
    public ordersServicesId: string,
    public orderPayload: Partial<Order>
  ) {}
}

export class OrderDetailsContainerCompleteOrder {
  static readonly type = '@orderDetailsContainer.completeOrder';
  constructor(
    public ordersServicesId: string,
    public orderPayload: Partial<Order>
  ) {}
}

export class OrderDetailsContainerPatchState {
  static readonly type = '@orderDetailsContainer.patchState';
  constructor(public state: Partial<OrderDetailsContainerStateModel>) {}
}

export class OrderDetailsContainerUpdateLockerStatus {
  static readonly type = '@orderDetailsContainer.updateLockerStatus';
  constructor(public orderServiceId: string) {}
}
