<app-toolbar *ngIf="appFeaturesState.isApp && !inModal" [loading]="loading$ | async" title="Terms and Conditions"></app-toolbar>

<ion-content
  [scrollEvents]="true"
  (ionScrollStart)="handleIonScrollStart()"
  [ngClass]="{ 'view-container': !appFeaturesState.isApp && !inModal, 'position-inherit': inIframe }"
>
  <ion-refresher slot="fixed" [pullMin]="50" (ionRefresh)="loadContent($event)">
    <ion-refresher-content pullingIcon="default"> </ion-refresher-content>
  </ion-refresher>
  <div
    [ngClass]="{ 'ion-padding-bottom': !appFeaturesState.isApp && !inModal }"
    class="ion-margin"
    [innerHtml]="termsAndConditions$ | async"
  ></div>
</ion-content>
