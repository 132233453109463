<ion-header class="ion-no-border" *ngIf="inModal">
  <ion-toolbar [ngClass]="appFeaturesState.isIos ? 'ion-margin-top' : ''">
    <ion-buttons slot="end">
      <ion-button size="large" (click)="closeModal()">
        <ion-icon size="large" color="secondary" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-center">
      <div class="ion-text-wrap">Service# {{ (order$ | async)?.id }}</div>
    </ion-title>
    <ion-progress-bar *ngIf="loading$ | async" type="indeterminate"></ion-progress-bar>
  </ion-toolbar>
</ion-header>

<ion-content
  *ngIf="inModal; else orderDetailsContent"
  [scrollEvents]="true"
  (ionScrollStart)="handleIonScrollStart()"
  appSwipeGesture
  (onSwipeRightComplete)="closeModal()"
  [ngClass]="{ 'content': this.appFeaturesState.isApp, 'content-for-web': !this.appFeaturesState.isApp }"
>
  <ng-container *ngTemplateOutlet="orderDetailsContent"></ng-container>
</ion-content>

<ng-template #orderDetailsContent>
  <div class="ion-margin-end" *ngIf="order$ | async as order">
    <ion-item>
      <strong> Details</strong>
    </ion-item>

    <ion-item lines="none">
      <ion-label position="stacked"> Status </ion-label>
      <ion-input
        [readonly]="true"
        [color]="getColor(order.orderServiceStatus)"
        [value]="(order?.orderServiceStatus | readableOrderStatus) ?? '--'"
      ></ion-input>
    </ion-item>
    <ion-item lines="none" *ngIf="order.costToShow !== '0.00'">
      <ion-label position="stacked"> Payment Method </ion-label>
      <ion-input [readonly]="true" [value]="order.orderPaymentMethodMessage"></ion-input>
    </ion-item>
    <ion-item lines="none" *ngIf="order.locker.number && order.locker.code && orderType === ordersTypes.MECHANICS">
      <div class="d-flex ion-justify-content-between">
        <div>
          <ion-label position="stacked"> Locker Info (number/code) </ion-label>
          <ion-input [value]="order.locker.number + '/' + order.locker.code"></ion-input>
        </div>
        <ion-button (click)="updateLockerStatus(true)" size="small"> Complete pickup </ion-button>
      </div>
    </ion-item>
    <ion-item lines="none">
      <ion-label position="stacked"> Services </ion-label>
      <ion-text [ngClass]="{ 'mt-5': appFeaturesState.isIos, 'mt-10': !appFeaturesState.isIos }">
        <ul class="m-0 pl-16">
          <li>
            {{ order.servicesTitle }}
            <ng-container *ngIf="order.part">
              <br />
              {{ order.part.name }}
            </ng-container>
          </li>
          <li *ngFor="let orderAddon of order.orderServiceAddons">{{ orderAddon.servicesAddonsTitle }}</li>
        </ul>
      </ion-text>
    </ion-item>

    <ion-item lines="none" *ngIf="order.part">
      <ion-label position="stacked"> Parts </ion-label>
      <ion-text [ngClass]="{ 'mt-5': appFeaturesState.isIos, 'mt-10': !appFeaturesState.isIos }"> {{ order.part.name }}</ion-text>
    </ion-item>
    <ion-accordion-group>
      <ion-accordion value="first">
        <ion-item lines="none" slot="header">
          <ion-label position="stacked"> Total </ion-label>
          <ion-input readonly="true">
            ${{ order.costToShow }}
            <ion-text color="success" *ngIf="order.discountAmount"> &nbsp;(${{ order.discountAmount }} discount applied) </ion-text>
          </ion-input>
        </ion-item>
        <div class="pr-24 pl-24 ion-padding-bottom" slot="content">
          <div class="d-flex pb-5 ion-justify-content-between" *ngIf="order.partsCost">
            <div>Parts cost:</div>
            <div>${{ order.partsCost }}</div>
          </div>
          <div class="d-flex pb-5 ion-justify-content-between">
            <div class="mr-16">{{ order.servicesTitle }} (Service cost):</div>
            <div>${{ order.serviceCost }}</div>
          </div>
          <div class="d-flex pb-5 ion-justify-content-between" *ngFor="let addon of order.orderServiceAddons">
            <div class="mr-16">{{ addon.servicesAddonsTitle }} (Service cost):</div>
            <div>${{ addon.serviceCostWithMarkup }}</div>
          </div>
          <div class="d-flex pb-5 ion-justify-content-between ion-align-items-end" *ngIf="order.extraCharges">
            <div style="max-width: 200px">Long distance service charges (${{ order.extraChargePerKm }}/Km) :</div>
            <div>${{ order.extraCharges }}</div>
          </div>
          <div class="d-flex pb-5 ion-justify-content-between" *ngIf="order.discountAmount">
            <div>Discount:</div>
            <div>(${{ order.discountAmount }})</div>
          </div>
          <div class="d-flex ion-justify-content-between">
            <div>Tax:</div>
            <div>${{ order.tax }}</div>
          </div>
        </div>
      </ion-accordion>
    </ion-accordion-group>
    <ion-item
      lines="none"
      *ngIf="
        order.orderServiceStatus === orderServiceStatuses.COMPLETE &&
        order.totalAmountCollected &&
        order.orderPaymentOption === OrderPaymentOption.PAY_WITH_CASH_ON_SERVICE
      "
    >
      <ion-label position="stacked"> Total Amount Collected By Mechanic </ion-label>
      <ion-input readonly="true"> ${{ order.totalAmountCollected }} </ion-input>
    </ion-item>

    <ion-item lines="none">
      <ion-label position="stacked"> Order Date </ion-label>
      <ion-input [readonly]="true" [value]="order.dateCreated"></ion-input>
    </ion-item>
    <ion-item lines="none" [detail]="false">
      <ion-label
        [ngClass]="{
          'mb-8':
            !appFeaturesState.isApp &&
            (orderType === ordersTypes.MECHANICS ||
              (orderType === ordersTypes.USER && order.orderServiceStatus === orderServiceStatuses.PENDING)) &&
            (order.orderServiceStatus === orderServiceStatuses.PENDING || order.orderServiceStatus === orderServiceStatuses.CONFIRM)
        }"
        position="stacked"
      >
        {{ order.orderServiceStatus === orderServiceStatuses.PENDING ? ' Preferred Service Date:' : 'Service Date:' }}
        <ion-text [color]="getColor(order.orderServiceStatus)">({{ (order?.orderServiceStatus | readableOrderStatus) ?? '--' }})</ion-text>
      </ion-label>
      <app-date-time-picker
        [skipConfirmDateValidation]="true"
        [ngClass]="{ 'mt-5': appFeaturesState.isAndroid }"
        *ngIf="
          (orderType === ordersTypes.MECHANICS ||
            (orderType === ordersTypes.USER && order.orderServiceStatus === orderServiceStatuses.PENDING)) &&
            (order.orderServiceStatus === orderServiceStatuses.PENDING || order.orderServiceStatus === orderServiceStatuses.CONFIRM);
          else readOnlyDate
        "
        class="w-100"
        (onChange)="updateDate($event)"
        #dateTimePicker
        [currentDate]="order.preferredTimeUnformatted"
      ></app-date-time-picker>
      <ng-template #readOnlyDate>
        <ion-input [readonly]="true" [value]="order.preferredTime"></ion-input>
      </ng-template>
    </ion-item>

    <ion-item lines="none">
      <ion-label position="stacked"> Vehicle </ion-label>
      <ion-text [ngClass]="{ 'mt-5': appFeaturesState.isIos, 'mt-10': !appFeaturesState.isIos }">
        {{ order?.vehicle?.year ?? '' }} {{ order?.vehicle?.makeName ?? '' }} {{ order?.vehicle?.modelName ?? '' }}
        {{ order.vehicle | vehicleNameFormatter }}
      </ion-text>
    </ion-item>

    <ion-item lines="none">
      <ion-label position="stacked"> Vin Number </ion-label>
      <ion-input readonly="true" [value]="order.vehicle?.vinNumber"></ion-input>
    </ion-item>

    <ion-item lines="none" *ngIf="order.orderServiceStatus === orderServiceStatuses.COMPLETE">
      <ion-label position="stacked"> Odometer(Km): </ion-label>
      <ion-input readonly="true" [value]="(order.odometer || '--') + ' Km'"></ion-input>
    </ion-item>

    <ion-button
      class="ion-padding-start"
      expand="block"
      (click)="confirmOrderHandler()"
      *ngIf="orderType === ordersTypes.MECHANICS && order.orderServiceStatus === orderServiceStatuses.PENDING && !inModal"
      color="primary"
    >
      Confirm Order
    </ion-button>

    <ion-button
      class="ion-padding-start"
      expand="block"
      (click)="completeOrderHandler()"
      *ngIf="orderType === ordersTypes.MECHANICS && order.orderServiceStatus === orderServiceStatuses.CONFIRM && !inModal"
      color="success"
    >
      Complete Order
    </ion-button>

    <ng-container *ngIf="payWithCardSwitchEnabled$ | async">
      <ng-container
        *ngIf="
          order.orderServiceStatus !== orderServiceStatuses.CANCELED &&
          order.orderServiceStatus !== orderServiceStatuses.DECLINED &&
          orderType === ordersTypes.USER &&
          order.orderPaymentOption === OrderPaymentOption.PAY_WITH_CASH_ON_SERVICE &&
          order.paymentCollectedByMechanic !== booleanStatus.TRUE &&
          !inModal
        "
      >
        <ion-button class="ion-padding-start" expand="block" (click)="payWithCardHandler()" color="primary"> Pay with card </ion-button>
        <p class="ion-margin-horizontal mt-0 ion-text-center small-font color-medium" [innerHtml]="order.switchToCardPaymentMessage"></p>
      </ng-container>
    </ng-container>

    <ion-item class="ion-margin-top">
      <strong> Customer</strong>
    </ion-item>
    <div class="d-flex w-100 pl-20 pb-8" [ngClass]="{ 'pt-8': !appFeaturesState.isIos }">
      <div>
        <ion-avatar
          class="avatar mr-8"
          (click)="
            viewImage(order.userProfilePicture ? serverHost + order.userProfilePicture.filePathS : '../../../assets/images/avatar.svg')
          "
        >
          <img [src]="order.userProfilePicture ? serverHost + order.userProfilePicture.filePathS : '../../../assets/images/avatar.svg'" />
        </ion-avatar>
      </div>
      <div class="w-100 ion-align-self-center">
        <ion-text> {{ order.location?.customerName || order.fname + ' ' + order.lname }} </ion-text>
        <ion-item
          *ngIf="order.orderServiceStatus !== orderServiceStatuses.COMPLETE"
          class="ion-no-padding"
          style="margin-top: 3px"
          [detail]="false"
          [href]="'tel:' + (order.location?.customerPhone || order.phone?.trim())"
          lines="none"
        >
          <ion-input color="primary" readonly="true" [value]="order.location?.customerPhone || order.phone | appPhoneFormat"></ion-input>
          <ion-icon class="ion-no-margin" color="primary" slot="end" name="call"></ion-icon>
        </ion-item>
        <ion-item
          *ngIf="order.orderServiceStatus === orderServiceStatuses.COMPLETE"
          [detail]="false"
          [href]="getAddressLink(order.location, order.orderServiceStatus)"
          lines="none"
          class="ion-no-padding"
        >
          <div class="d-flex ion-justify-content-between ion-align-items-center">
            <ion-text color="primary">
              {{ order?.location?.city ?? '' }}, {{ order?.location?.state ?? '' }} {{ order?.location?.country ?? '' }}
            </ion-text>
            <ion-icon class="ion-align-self-end ion-no-margin" color="primary" slot="end" size="large" name="open-outline"></ion-icon>
          </div>
        </ion-item>
      </div>
    </div>

    <ion-item
      [detail]="false"
      [href]="getAddressLink(order.location, order.orderServiceStatus)"
      lines="none"
      *ngIf="order.orderServiceStatus !== orderServiceStatuses.COMPLETE"
    >
      <div class="d-flex ion-justify-content-between">
        <ion-text color="primary" style="width: 90%">
          {{ order?.location?.address ?? '' }} {{ order?.location?.city ?? '' }} {{ order?.location?.zip ?? '' }},
          {{ order?.location?.state ?? '' }} {{ order?.location?.country ?? '' }}
        </ion-text>
        <ion-icon class="ion-align-self-end ion-no-margin" color="primary" slot="end" size="large" name="open-outline"></ion-icon>
      </div>
    </ion-item>
    <div class="ion-padding-horizontal ion-margin-bottom" [ngClass]="{ 'd-flex ion-justify-content-between': !appFeaturesState.isApp }">
      <ion-button
        (click)="completePaymentAuthorization(order.incompleteOrderPayments)"
        [expand]="appFeaturesState.isApp ? 'block' : ''"
        *ngIf="orderType === ordersTypes.USER && order.incompleteOrderPayments && order.incompleteOrderPayments.length > 0"
      >
        Complete Payment Authorization
      </ion-button>
      <ion-button
        (click)="cancelOrder()"
        color="danger"
        [expand]="appFeaturesState.isApp ? 'block' : ''"
        *ngIf="orderType === ordersTypes.USER && order.orderServiceStatus === orderServiceStatuses.PENDING"
      >
        Cancel Order
      </ion-button>
    </div>

    <ion-item>
      <strong> Mechanic </strong>
    </ion-item>
    <ng-container *ngIf="order?.mechanic; else noMechanics">
      <div class="d-flex w-100 pl-20 pb-8" [ngClass]="{ 'pt-8': !appFeaturesState.isIos }">
        <div>
          <ion-avatar
            class="avatar mr-8"
            (click)="
              viewImage(
                order.mechanic.userProfilePicture
                  ? serverHost + order.mechanic.userProfilePicture.filePathS
                  : '../../../assets/images/avatar.svg'
              )
            "
          >
            <img
              [src]="
                order.mechanic.userProfilePicture
                  ? serverHost + order.mechanic.userProfilePicture.filePathS
                  : '../../../assets/images/avatar.svg'
              "
            />
          </ion-avatar>
        </div>

        <div class="w-100 ion-align-self-center">
          <ion-text> {{ order.mechanic.fname + ' ' + order.mechanic.lname }} </ion-text>
          <ion-item class="ion-no-padding" style="margin-top: 3px" [detail]="false" [href]="'tel:' + order.mechanic.phone" lines="none">
            <ion-input color="primary" readonly="true" [value]="order.mechanic.phone | appPhoneFormat"></ion-input>
            <ion-icon class="ion-no-margin" color="primary" slot="end" name="call"></ion-icon>
          </ion-item>
        </div>
      </div>

      <ion-button
        class="ion-padding-start"
        (click)="declineOrder()"
        *ngIf="
          orderType === ordersTypes.MECHANICS &&
          (order.orderServiceStatus === orderServiceStatuses.CONFIRM || order.orderServiceStatus === orderServiceStatuses.PENDING)
        "
        [color]="getColor(orderServiceStatuses.DECLINED)"
      >
        Decline
      </ion-button>
    </ng-container>
    <ng-template #noMechanics>
      <ion-item lines="none"> No Mechanic has been assigned to you order yet </ion-item>
    </ng-template>

    <ion-item class="ion-margin-top">
      <strong> Uploaded Pictures </strong>
      <ng-container *ngIf="appFeaturesState.isNative && orderType === ordersTypes.MECHANICS">
        <ion-button (click)="actionList.present($event)" color="secondary" class="ion-no-padding" fill="clear" size="medium">
          <ion-icon size="large" name="add-circle-outline"></ion-icon>
        </ion-button>
        <ion-popover [dismissOnSelect]="true" #actionList>
          <ng-template>
            <ion-item (click)="picUploader?.takePicture()">
              <ion-icon name="camera"></ion-icon>
              <ion-label style="margin-left: 8px"> Take Picture </ion-label>
            </ion-item>
            <ion-item (click)="picUploader?.uploadPictures()">
              <ion-icon name="cloud-upload"></ion-icon> <ion-label style="margin-left: 8px"> Upload </ion-label>
            </ion-item>
          </ng-template>
        </ion-popover>
      </ng-container>
    </ion-item>
    <app-order-picture-uploader
      *ngIf="orderType === ordersTypes.MECHANICS"
      #picUploader
      [noLines]="true"
      [noDefaultHeight]="true"
      [showUploadImageHeader]="false"
      [orderServiceId]="order.ordersServicesId"
    ></app-order-picture-uploader>

    <ion-grid *ngIf="orderType === ordersTypes.USER" [ngClass]="{ 'pl-16': appFeaturesState.isIos, 'pl-10': !appFeaturesState.isIos }">
      <ion-row *ngIf="order?.images?.length === 0">
        <ion-col>
          <p class="ion-no-margin">There are no uploaded images</p>
        </ion-col>
      </ion-row>
      <ion-row>
        <ng-container *ngFor="let pic of order.images">
          <ion-col [size]="appFeaturesState.isApp ? 6 : 4">
            <img class="pointer-cursor" (click)="viewImage(serverHost + pic.filePath)" [src]="serverHost + pic.filePathS" />
          </ion-col>
        </ng-container>
      </ion-row>
    </ion-grid>

    <br />
    <ion-button [class.ion-hide]="!inModal" class="ion-margin-start" fill="outline" size="medium" color="secondary" (click)="closeModal()">
      Close
    </ion-button>
  </div>
</ng-template>
