import { Injectable } from '@angular/core';
import { Action, createSelector, NgxsAfterBootstrap, Selector, State, StateContext, Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { ResetState } from '../shared';

export interface LoadingStateModel {
  loading: boolean;
  id: string;
  useSpinner: boolean;
}

export class SetLoading {
  static readonly type = '@loading.SetLoading';
  /**
   * Set Loading Action ctor
   * @param loading true/false
   * @param caller this from caller class (getting the contructor name)
   */
  constructor(
    public loading: boolean,
    public caller: object,
    public useSpinner: boolean = false,
    public actionName: string | undefined = undefined
  ) {}
}

@State<Record<string, LoadingStateModel>>({
  name: 'loading',
  defaults: {},
})
@Injectable()
export class LoadingState implements NgxsAfterBootstrap {
  constructor(public store: Store) {}

  @Selector([LoadingState.entitiesArray])
  public static loading(entitiesArray: LoadingStateModel[]) {
    const isLoading = entitiesArray.some((l) => l.loading === true);
    return isLoading;
  }
  public loading$: Observable<boolean> = of(false);

  @Selector([LoadingState.entitiesArray])
  public static loadingWithSpinner(entitiesArray: LoadingStateModel[]) {
    const isLoading = entitiesArray.some((l) => l.loading === true && l.useSpinner === true);
    return isLoading;
  }

  @Selector()
  private static entitiesArray(state: Record<string, LoadingStateModel>) {
    return Object.values(state);
  }
  private entitiesArray$ = this.store.select(LoadingState.entitiesArray);

  @Selector()
  private static entities(state: Record<string, LoadingStateModel>) {
    return state;
  }
  private entities$ = this.store.select(LoadingState.entities);

  private selectOne(key: string) {
    const state = this.store.selectSnapshot(LoadingState.entities);
    return state[key];
  }

  @Action(SetLoading)
  setLoadingAction(ctx: StateContext<Record<string, LoadingStateModel>>, action: SetLoading) {
    const key = `${action.caller.constructor.name}${action.actionName ? '_' + action.actionName : ''}`;
    ctx.patchState({
      [key]: {
        id: key,
        loading: action.loading,
        useSpinner: action.useSpinner ? true : false,
      },
    });
  }

  @Action(ResetState)
  resetState(ctx: StateContext<Record<string, LoadingStateModel>>) {
    ctx.patchState({});
  }

  public async ngxsAfterBootstrap(): Promise<void> {
    this.loading$ = this.entitiesArray$.pipe(
      // do not show loading indicator under this value
      debounceTime(200),
      map((l) => l.some((a) => a.loading === true))
    );
  }

  static isLoading(key: string) {
    return createSelector([LoadingState], (state: Record<string, LoadingStateModel>) => {
      // TODO: refactor this when moving away from ngxsData
      return state[key]?.loading ?? false;
    });
  }

  // public loadingKey$ = (key: string) => this.entities$.pipe(map((k) => k[key]?.loading));

  // isKeyLoading(key: string) {
  //   const entity = this.selectOne(key);
  //   return entity?.loading;
  // }
}
