import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Action, NgxsAfterBootstrap, Selector, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs';

const breakPoints = { ...Breakpoints, XXSmall: '(max-width: 300px)' };

interface LayoutStateModel {
  [key: string]: boolean;
}

class UpdateLayout {
  static readonly type = '@layout.updateLayout';
  constructor(public layout: { [key: string]: boolean }) {}
}

@State<LayoutStateModel>({
  name: 'LayoutState',
})
@Injectable()
export class LayoutState implements NgxsAfterBootstrap {
  constructor(private breakpointObserver: BreakpointObserver, private store: Store) {}

  @Selector()
  static breakpoints(state: LayoutStateModel) {
    return state;
  }

  @Selector()
  static isSmall(state: LayoutStateModel) {
    return state[Breakpoints.Small] || state[Breakpoints.XSmall];
  }

  @Selector()
  static isExtraExtraSmall(state: LayoutStateModel) {
    return state[breakPoints.XXSmall];
  }

  @Selector()
  static isMedium(state: LayoutStateModel) {
    return state[Breakpoints.Medium];
  }

  @Selector()
  static isLarge(state: LayoutStateModel) {
    return state[Breakpoints.Large] || state[Breakpoints.XLarge];
  }

  ngxsAfterBootstrap(ctx?: StateContext<any>): void {
    const breakPointsArray = Object.values(breakPoints);
    this.breakpointObserver
      .observe(breakPointsArray)
      .pipe(
        tap((b) => {
          this.store.dispatch(new UpdateLayout(b.breakpoints));
        })
      )
      .subscribe();
  }

  @Action(UpdateLayout)
  private updateLayout(ctx: StateContext<LayoutStateModel>, action: UpdateLayout) {
    ctx.patchState(action.layout);
  }
}
