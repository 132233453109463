<ng-container *ngIf="errors?.required"> Please provide a valid {{ name }} </ng-container>
<ng-container *ngIf="errors?.pattern">
  {{ patternErrorMessage }}
</ng-container>
<ng-container *ngIf="errors?.appMinValue?.valid === false">
  {{ errors?.appMinValue?.message }}
</ng-container>
<ng-container *ngIf="errors?.email"> Please provide a valid email </ng-container>
<ng-container *ngIf="errors?.minlength">
  {{ minLengthErrorMessage ?? 'value must be ' + errors?.minlength.requiredLength + ' characters long' }}
</ng-container>
