import { Injectable } from '@angular/core';
import { ToastController, ToastOptions } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private toastController: ToastController) {}

  async showToast(isApp: boolean, options: ToastOptions) {
    const toast = await this.toastController.create({
      message: '',
      position: 'top',
      buttons: [{ side: 'end', text: 'Ok' }],
      duration: 5000,
      color: 'secondary',
      cssClass: isApp ? '' : 'toast-container',
      htmlAttributes: { tabindex: undefined },
      ...options,
    });
    await toast.present();
    return toast;
  }
}
