import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { NgxsModule } from '@ngxs/store';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SignupForgotPasswordComponent } from './signup-forgot-password.component';
import { ResetPasswordModule } from '../../reset-password/reset-password.module';
import { SignupForgotPasswordState } from './signup-forgot-password.state';
import { InputMaskModule } from '../../inside/shared/input-mask/input-mask.module';
import { SignupForgotPasswordRoutingModule } from './signup-forgot-password-routing.module';
import { SignUpFormContainerComponent } from './sign-up-form-container.component';
import { ErrorViewModule } from '../error-view/error-view.module';

@NgModule({
  declarations: [SignupForgotPasswordComponent, SignUpFormContainerComponent],
  imports: [
    CommonModule,
    SignupForgotPasswordRoutingModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    InputMaskModule,
    NgxsModule.forFeature([SignupForgotPasswordState]),
    ResetPasswordModule,
    ErrorViewModule,
  ],
  exports: [SignupForgotPasswordComponent],
})
export class SignupForgotPasswordModule {}
