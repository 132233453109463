<ion-header class="ion-no-border html-background-light-tint">
  <ion-toolbar
    class="background-light-tint toolbar"
    [ngClass]="{
      'ion-margin-top': appFeaturesState.isNativeIos,
      'pl-16': appFeaturesState.isNativeIos,
      'pt-0': appFeaturesState.isAndroid
    }"
  >
    <strong [ngClass]="{ 'pl-8': !appFeaturesState.isIos }"> Leave a Review </strong>
    <ion-button
      fill="clear"
      slot="end"
      class="ion-no-padding"
      size="medium"
      (click)="closeModal()"
      [ngClass]="{ 'pr-8': !appFeaturesState.isIos }"
    >
      <ion-icon size="large" color="secondary" name="close"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ng-container [ngSwitch]="viewState$ | async">
    <div *ngSwitchCase="ViewState.FORM" class="ion-margin">
      <div>
        <h4>Rate your experience:</h4>
        <div class="d-flex ion-justify-content-between">
          <div>
            <ion-button
              [fill]="(rating$ | async) === 1 ? 'solid' : 'outline'"
              class="rounded-btn"
              [color]="(rating$ | async) === 1 ? 'primary' : 'medium'"
              (click)="rating$.next(1)"
            >
              1
            </ion-button>
            <!-- <sub>worst</sub> -->
          </div>
          <ion-button
            [fill]="(rating$ | async) === 2 ? 'solid' : 'outline'"
            class="rounded-btn"
            [color]="(rating$ | async) === 2 ? 'primary' : 'medium'"
            (click)="rating$.next(2)"
          >
            2
          </ion-button>
          <ion-button
            [fill]="(rating$ | async) === 3 ? 'solid' : 'outline'"
            class="rounded-btn"
            [color]="(rating$ | async) === 3 ? 'primary' : 'medium'"
            (click)="rating$.next(3)"
          >
            3
          </ion-button>
          <ion-button
            [fill]="(rating$ | async) === 4 ? 'solid' : 'outline'"
            class="rounded-btn"
            [color]="(rating$ | async) === 4 ? 'primary' : 'medium'"
            (click)="rating$.next(4)"
          >
            4
          </ion-button>
          <div>
            <ion-button
              [fill]="(rating$ | async) === 5 ? 'solid' : 'outline'"
              class="rounded-btn"
              [color]="(rating$ | async) === 5 ? 'primary' : 'medium'"
              (click)="rating$.next(5)"
            >
              5
            </ion-button>
            <!-- <sub>best</sub> -->
          </div>
        </div>
        <div class="d-flex ion-justify-content-between" [ngClass]="{ 'px-5': appFeaturesState.isIos, 'px-8': appFeaturesState.isAndroid }">
          <ion-label color="medium">Bad</ion-label>
          <ion-label color="medium">Good</ion-label>
        </div>
      </div>
      <div [ngClass]="{ 'mt-8': appFeaturesState.isIos, 'mt-10': !appFeaturesState.isIos }">
        <ion-textarea
          [(ngModel)]="reviewForm.comment"
          [rows]="5"
          class="background-light-shade"
          [ngClass]="{ 'px-8': appFeaturesState.isIos }"
          placeholder="leave a comment"
        ></ion-textarea>
      </div>
      <ion-button (click)="submitReview()" class="ion-margin-top" expand="block"> Submit Review </ion-button>
    </div>
    <div *ngSwitchCase="ViewState.MESSAGE_RESPONSE" class="ion-margin" [innerHTML]="message$ | async"></div>
  </ng-container>
</ion-content>
