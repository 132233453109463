<ion-header class="ion-no-border">
  <ion-toolbar [ngClass]="appFeaturesState.isIos ? 'ion-margin-top' : 'toolbar'">
    <ion-buttons slot="end">
      <ion-button size="large" color="secondary" (click)="close()">
        <ion-icon size="large" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-center">
      <div class="ion-text-wrap" *ngIf="selectedView === verificationView.VERIFY_MOBILE">Verify Your Mobile Number</div>
      <div class="ion-text-wrap" *ngIf="selectedView === verificationView.UPDATE_MOBILE">Update Your Mobile Number</div>
      <div class="ion-text-wrap" *ngIf="selectedView === verificationView.ADD_MOBILE">Add Your Mobile Number</div>
    </ion-title>
    <p class="cancel-message ion-text-center" *ngIf="!appFeaturesState.isApp">
      Mobile Number must be verified before you can request any services
    </p>
    <ion-progress-bar *ngIf="loadingState.loading$ | async" type="indeterminate"></ion-progress-bar>
  </ion-toolbar>
</ion-header>

<ion-content [scrollEvents]="true" (ionScrollStart)="handleIonScrollStart()" *ngIf="appFeaturesState.isApp; else view">
  <ng-container *ngTemplateOutlet="view"> </ng-container>
</ion-content>

<ng-template #view>
  <br *ngIf="appFeaturesState.isApp" />
  <form (keydown)="keyDownFunction($event)" [ngClass]="appFeaturesState.isApp ? 'ion-margin' : 'ml-10'">
    <ng-container *ngIf="selectedView === verificationView.VERIFY_MOBILE">
      <p class="ion-text-center" [ngClass]="appFeaturesState.isApp ? '' : 'ion-no-margin'">
        A 6-digit code was sent to
        <label class="ion-text-nowrap">
          <strong> {{ (authState.userDetails$ | async)?.phone | appPhoneFormat }} </strong>
          <u (click)="toUpdatePhoneStep()" class="link">Change</u>
        </label>
      </p>
      <br *ngIf="appFeaturesState.isApp" />

      <ion-item [formGroup]="verificationForm" mode="md" class="background-light-shade rounded ion-rounded ion-margin-horizontal">
        <ion-label position="stacked">Verification Code</ion-label>
        <ion-input placeholder="please enter the 6-digit code" inputmode="numeric" formControlName="input1"> </ion-input>
        <span slot="error">
          <app-error-view
            *ngIf="verificationForm.controls.input1.touched"
            name="code"
            [errors]="verificationForm.controls.input1.errors"
            minLengthErrorMessage="must be a valid phone number"
            patternErrorMessage="must be a valid phone number"
          ></app-error-view>
        </span>
        <span slot="helper"></span>
      </ion-item>

      <p
        [ngClass]="{
          'ion-margin message-paragraph': appFeaturesState.isIos
        }"
      >
        Didn't received a code?
        <u *ngIf="!(this.authState.phoneVerificationSent$ | async)" (click)="resendCode()" class="p3 link"> Resend </u>
        <span *ngIf="this.authState.phoneVerificationSent$ | async" class="p3 link-inactive small-font">
          Code Sent! ({{ authState.timer$ | async }} minute ago)
        </span>
      </p>

      <div *ngIf="appFeaturesState.isApp" [ngClass]="appFeaturesState.isIos ? 'ion-margin' : 'ion-no-margin'">
        <ion-button expand="block" size="medium" color="primary" [disabled]="verificationForm.invalid" (click)="verifyPhone()">
          Verify & Continue
        </ion-button>

        <ion-button fill="outline" expand="block" size="medium" color="secondary" (click)="close()"> Cancel </ion-button>
        <p class="cancel-message">Mobile Number must be verified before you can request any services</p>
      </div>

      <div *ngIf="!appFeaturesState.isApp" class="update-phone-action-container">
        <div class="d-flex ion-justify-content-between">
          <ion-button fill="outline" expand="block" size="medium" color="secondary" (click)="close()"> Cancel </ion-button>
          <ion-button
            class="mr-10"
            expand="block"
            size="medium"
            color="primary"
            (click)="verifyPhone()"
            [disabled]="verificationForm.invalid"
          >
            Verify & Continue
          </ion-button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="selectedView === verificationView.UPDATE_MOBILE">
      <ion-item
        [formGroup]="mobileNumberChangeForm"
        mode="md"
        class="background-light-shade rounded ion-rounded ion-margin-horizontal"
        [ngClass]="appFeaturesState.isApp ? '' : 'phone-number-item'"
      >
        <ion-label position="stacked"> Mobile Number </ion-label>
        <ion-input appPhoneMask type="tel" inputmode="tel" formControlName="phone" placeholder="(999) 999-999"></ion-input>
        <span slot="error">
          <app-error-view
            *ngIf="mobileNumberChangeForm.controls.phone.touched"
            name="phone number"
            [errors]="mobileNumberChangeForm.controls.phone.errors"
            minLengthErrorMessage="must be a valid phone number"
            patternErrorMessage="must be a valid phone number"
          ></app-error-view>
        </span>
        <span slot="helper"></span>
      </ion-item>

      <div *ngIf="appFeaturesState.isApp" [ngClass]="appFeaturesState.isIos ? 'ion-margin' : 'ion-no-margin'">
        <ion-button expand="block" size="medium" color="primary" [disabled]="mobileNumberChangeForm.invalid" (click)="updatePhone()">
          Update & Verify
        </ion-button>

        <ion-button fill="outline" expand="block" size="medium" color="secondary" (click)="toVerificationStep()"> Back </ion-button>
        <p class="cancel-message">Mobile Number must be verified before you can request any services</p>
      </div>

      <div *ngIf="!appFeaturesState.isApp" class="update-phone-action-container">
        <div class="d-flex ion-justify-content-between ion-margin-top">
          <ion-button fill="outline" expand="block" size="medium" color="secondary" (click)="toVerificationStep()"> Back </ion-button>
          <ion-button class="mr-10" expand="block" size="medium" color="primary"> Update & Verify </ion-button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="selectedView === verificationView.ADD_MOBILE">
      <ion-item
        [formGroup]="mobileNumberChangeForm"
        mode="md"
        class="background-light-shade rounded ion-rounded ion-margin-horizontal"
        [ngClass]="appFeaturesState.isApp ? '' : 'phone-number-item'"
      >
        <ion-label position="stacked"> Mobile Number </ion-label>
        <ion-input appPhoneMask type="tel" inputmode="tel" formControlName="phone" placeholder="(999) 999-999"></ion-input>
        <span slot="error">
          <app-error-view
            *ngIf="mobileNumberChangeForm.controls.phone.touched"
            name="phone number"
            [errors]="mobileNumberChangeForm.controls.phone.errors"
            minLengthErrorMessage="must be a valid phone number"
            patternErrorMessage="must be a valid phone number"
          ></app-error-view>
        </span>
        <span slot="helper"></span>
      </ion-item>

      <div *ngIf="appFeaturesState.isApp" [ngClass]="appFeaturesState.isIos ? 'ion-margin' : 'ion-no-margin'">
        <ion-button expand="block" size="medium" color="primary" [disabled]="mobileNumberChangeForm.invalid" (click)="updatePhone()">
          Update & Verify
        </ion-button>

        <p class="cancel-message">Mobile Number must be verified before you can request any services</p>
      </div>

      <div *ngIf="!appFeaturesState.isApp" class="update-phone-action-container">
        <div class="d-flex ion-justify-content-between ion-margin-top">
          <ion-button fill="outline" expand="block" size="medium" color="secondary" (click)="toVerificationStep()"> Back </ion-button>
          <ion-button class="mr-10" expand="block" size="medium" color="primary"> Update & Verify </ion-button>
        </div>
      </div>
    </ng-container>
  </form>
</ng-template>
