import { Injectable, NgZone } from '@angular/core';
import { ConnectionStatus } from '@capacitor/network';
import { ModalController, Platform } from '@ionic/angular';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { from, Observable, of } from 'rxjs';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Network } from '@capacitor/network';
import { SetLoading } from '../core/loading.state';
import { AppFeaturesService } from './app-features.service';
import { sanitizeHtml } from '../shared/function';
import { Device, DeviceInfo } from '@capacitor/device';
import { App, AppInfo } from '@capacitor/app';
import { AppCashPaymentOptions, CountryCode, DeviceType, LandingPageContent, LandingPageType, FieldsType } from '../shared';
import { patch } from '@ngxs/store/operators';
import { AppUpdateModalComponent } from '../shared-components/update-modal/app-update-modal.component';
import { CapacitorUpdater, DownloadEvent, DownloadFailedEvent } from '@capgo/capacitor-updater';
import { SplashScreen } from '@capacitor/splash-screen';

interface AllRotorsDeviceInfo extends DeviceInfo, Partial<AppInfo> {}

interface Banner {
  text: string | undefined;
  show: boolean;
}

interface AppFeatureModel {
  isApp: boolean;
  networkStatus: ConnectionStatus | undefined;
  deviceInfo: AllRotorsDeviceInfo | undefined;
  termsAndConditionsInfo: string | undefined;
  privacyPolicyInfo: string | undefined;
  landingPageContent: LandingPageContent | undefined;
  homeScreenInfo: string | undefined;
  checkoutPaymentInfo: string | undefined;
  accountDeleteText: string | undefined;
  appStoreBanner: Banner;
  appVersion: string;
  appUpdateBanner: Banner;
  appStoreUrl: string | undefined;
  playStoreUrl: string | undefined;
  countryCodes: CountryCode[];
  featureFlags: {
    nativeApplePay: boolean;
    nativeGooglePay: boolean;
    notifications: boolean;
    googleAnalytics: boolean;
    allowCashAppPayment: AppCashPaymentOptions;
    serviceAddon: boolean;
    payWithCardSwitch: boolean;
  };
}

export class AppFeaturesPatchState {
  static readonly type = '@appFeaturesState.PatchState';
  constructor(public state: Partial<AppFeatureModel>) {}
}

export class TermsAndConditionsGet {
  static readonly type = '@appFeatures.getTermsAndConditions';
}

export class PrivacyPoliciesGet {
  static readonly type = '@appFeatures.getPrivacyPolicies';
}

export class AppFeaturesGetLandingPageContent {
  static readonly type = '@appFeatures.getLandingPageContent';
  constructor(public landingPageType: LandingPageType) {}
}
export class HomeGetContent {
  static readonly type = '@appFeatures.getContent';
}

export class AppGetCheckoutPaymentInfo {
  static readonly type = '@appFeatures.getCheckoutPaymentInfo';
}

export class AppGetAccountDeleteText {
  static readonly type = '@appFeatures.getAccountDeleteText';
}

export class AppGetAppVersion {
  static readonly type = '@appFeatures.getAppVersion';
}

export class AppGetAppConfigs {
  static readonly type = '@appFeatures.getAppConfigs';
}

export class AppUpdateAppStoreBanner {
  static readonly type = '@appFeatures.updateAppStoreBanner';
  constructor(public banner: Partial<Banner>) {}
}

export class AppNavigateToStore {
  static readonly type = '@appFeatures.navigateToStore';
  constructor() {}
}

export class AppActivateGoogleAnalytics {
  static readonly type = '@appFeatures.activateGoogleAnalytics';
}

export class AppActivateAppleSdk {
  static readonly type = '@appFeatures.activateAppleSdk';
}

export class AppActivateGoogleMap {
  static readonly type = '@appFeatures.activateGoogleMap';
}

export class AppGetSupportedCountryCodes {
  static readonly type = '@appFeatures.getSupportedCountryCodes';
}

interface AppVersionDetails {
  version: string;
  forceUpdate: boolean;
  softUpdate: boolean;
  zipFile: string;
}

@State<AppFeatureModel>({
  name: 'AppFeaturesState',
  defaults: {
    isApp: false,
    networkStatus: undefined,
    homeScreenInfo: undefined,
    privacyPolicyInfo: undefined,
    termsAndConditionsInfo: undefined,
    landingPageContent: undefined,
    checkoutPaymentInfo: undefined,
    deviceInfo: undefined,
    accountDeleteText: undefined,
    appStoreBanner: {
      show: false,
      text: undefined,
    },
    appVersion: '2.4.1',
    appUpdateBanner: {
      show: false,
      text: undefined,
    },
    appStoreUrl: undefined,
    playStoreUrl: undefined,
    countryCodes: [],
    featureFlags: {
      nativeApplePay: false,
      nativeGooglePay: false,
      notifications: false,
      googleAnalytics: false,
      allowCashAppPayment: AppCashPaymentOptions.DO_NOT_USE_CASH_PAYMENT,
      serviceAddon: false,
      payWithCardSwitch: false,
    },
  },
})
@Injectable()
export class AppFeaturesState implements NgxsOnInit {
  constructor(
    private platform: Platform,
    private store: Store,
    private appFeaturesService: AppFeaturesService,
    private modalController: ModalController,
    private zone: NgZone
  ) {
    Network.addListener('networkStatusChange', (status) => {
      console.log('Network status changed', status);
      this.store.dispatch(new AppFeaturesPatchState({ networkStatus: status }));
    });
    this.initialiseAppUpdate();
  }

  initialiseAppUpdate() {
    CapacitorUpdater.addListener('download', (state: DownloadEvent) => {
      this.zone.run(() => {
        console.log('state', JSON.stringify(state));
      });
    });
    CapacitorUpdater.addListener('downloadFailed', (state: DownloadFailedEvent) => {
      this.zone.run(() => {
        console.log('dowloadFailedState', JSON.stringify(state));
      });
    });
    CapacitorUpdater.addListener('appReloaded', async () => {
      console.log('appReloaded');
      CapacitorUpdater.notifyAppReady();
    });
  }

  @Selector()
  public static state(state: AppFeatureModel) {
    return state;
  }
  private state$ = this.store.select(AppFeaturesState.state);

  @Selector()
  public static appStoreBanner(state: AppFeatureModel) {
    return state.appStoreBanner;
  }

  @Selector()
  public static appUpdateBanner(state: AppFeatureModel) {
    return state.appUpdateBanner;
  }

  @Selector()
  public static appAllowCashAppPayment(state: AppFeatureModel) {
    return state.featureFlags.allowCashAppPayment;
  }

  private get snapshot() {
    return this.store.selectSnapshot(AppFeaturesState.state);
  }

  internetAvailable$ = this.state$.pipe(map((s) => s.networkStatus?.connected));

  isApp$: Observable<boolean> = this.state$.pipe(map((s) => s.isApp));

  get isApp() {
    return this.snapshot.isApp;
  }

  get isIos() {
    return this.appFeaturesService.isIos;
  }

  get isNativeIos() {
    return this.appFeaturesService.isNativeIos;
  }

  get isAndroid() {
    return this.appFeaturesService.isAndroid;
  }
  get isNativeAndroid() {
    return this.appFeaturesService.isNativeAndroid;
  }

  get isNative() {
    return this.appFeaturesService.isNative;
  }

  get deviceType(): DeviceType {
    return this.appFeaturesService.deviceType;
  }

  get inDev() {
    return environment.inDev;
  }

  get isMobileBrowser() {
    return this.platform.is('mobileweb');
  }

  get googleAnalyticsMeasurementId() {
    return this.appFeaturesService.googleAnalyticsMeasurementId;
  }

  async ngxsOnInit() {
    let isApp = false;
    const isCapacitor = environment.forceApp || this.platform.is('capacitor');
    // if (!isCapacitor && this.isMobileBrowser && this.isAndroid) {
    //   this.navigateToPlayStore();
    //   return;
    // }
    // if (!isCapacitor && this.isMobileBrowser && this.isIos) {
    //   this.navigateToAppStore();
    //   return;
    // }

    if (this.platform.is('ios') || this.platform.is('android')) {
      isApp = true;
    }
    const networkStatus = await Network.getStatus();
    let deviceInfo = await Device.getInfo();
    if (this.platform.is('capacitor')) {
      const appInfo = await App.getInfo();
      deviceInfo = { ...deviceInfo, ...appInfo };
    }

    const appStoreBanner: Banner = {
      show: !this.isNative && isApp,
      text: undefined,
    };
    this.store.dispatch(new AppFeaturesPatchState({ isApp, networkStatus, deviceInfo, appStoreBanner }));
  }

  @Action(AppActivateGoogleAnalytics)
  private enableGoogleAnalytics(ctx: StateContext<AppFeatureModel>) {
    const { featureFlags } = ctx.getState();
    if (featureFlags.googleAnalytics) {
      this.appFeaturesService.loadGoogleAnalytics(this.googleAnalyticsMeasurementId);
    } else {
      this.store.dispatch(new SetLoading(true, this, false, this.enableGoogleAnalytics.name));
      this.appFeaturesService
        .getAppInfos(FieldsType.FEATURE_FLAG_GOOGLE_ANALYTICS)
        .pipe(
          tap((res) => {
            const analyticsEnabled = res.data.featureFlagGoogleAnalytics === 'true';
            ctx.setState(
              patch({
                featureFlags: patch({
                  googleAnalytics: analyticsEnabled,
                }),
              })
            );
            if (analyticsEnabled) {
              console.log('asd');
              this.appFeaturesService.loadGoogleAnalytics(this.googleAnalyticsMeasurementId);
            }
          }),
          finalize(() => this.store.dispatch(new SetLoading(false, this, false, this.enableGoogleAnalytics.name)))
        )
        .subscribe();
    }
  }

  @Action(AppFeaturesPatchState)
  private patchNetworkState(ctx: StateContext<AppFeatureModel>, action: AppFeaturesPatchState) {
    ctx.patchState(action.state);
  }

  @Selector()
  static termsAndConditions(state: AppFeatureModel) {
    return state.termsAndConditionsInfo;
  }

  @Selector()
  static landingPageContent(state: AppFeatureModel) {
    return state.landingPageContent;
  }

  @Selector()
  static privacyPolicies(state: AppFeatureModel) {
    return state.privacyPolicyInfo;
  }

  @Selector()
  static getHomeScreenInfo(state: AppFeatureModel) {
    return state.homeScreenInfo;
  }

  @Selector()
  static accountDeleteText(state: AppFeatureModel) {
    return state.accountDeleteText;
  }

  @Selector()
  static getCheckoutPaymentInfo(state: AppFeatureModel) {
    return state.checkoutPaymentInfo;
  }

  @Selector()
  static featureFlags(state: AppFeatureModel) {
    return state.featureFlags;
  }

  @Selector()
  static serviceAddonEnabled(state: AppFeatureModel) {
    return state.featureFlags.serviceAddon;
  }

  @Selector()
  static payWithCardSwitchEnabled(state: AppFeatureModel) {
    return state.featureFlags.payWithCardSwitch;
  }

  @Selector()
  static countryCodes(state: AppFeatureModel) {
    return state.countryCodes;
  }

  @Action(TermsAndConditionsGet)
  getTermsAndConditions(ctx: StateContext<AppFeatureModel>, action: TermsAndConditionsGet) {
    this.store.dispatch(new SetLoading(true, this));
    return this.appFeaturesService.getAppInfos(FieldsType.TERMS_AND_CONDITIONS).pipe(
      tap((res) => {
        const sanitizedHtml = sanitizeHtml(res.data.termsAndConditions);
        ctx.patchState({ termsAndConditionsInfo: sanitizedHtml });
      }),
      finalize(() => this.store.dispatch(new SetLoading(false, this)))
    );
  }

  @Action(PrivacyPoliciesGet)
  getPrivacyPolicies(ctx: StateContext<AppFeatureModel>, action: PrivacyPoliciesGet) {
    this.store.dispatch(new SetLoading(true, this));
    return this.appFeaturesService.getAppInfos(FieldsType.PRIVACY_POLICY).pipe(
      tap((res) => {
        const sanitizedHtml = sanitizeHtml(res.data.privacyPolicy);
        ctx.patchState({ privacyPolicyInfo: sanitizedHtml });
      }),
      finalize(() => this.store.dispatch(new SetLoading(false, this)))
    );
  }

  @Action(AppFeaturesGetLandingPageContent)
  getLandingPageContent(ctx: StateContext<AppFeatureModel>, action: AppFeaturesGetLandingPageContent) {
    this.store.dispatch(new SetLoading(true, this));
    return this.appFeaturesService.getAppInfos((<unknown>action.landingPageType) as FieldsType).pipe(
      tap((res) => {
        if (res.data.landingPageMobileCarDetailing) {
          const content = res.data.landingPageMobileCarDetailing ? JSON.parse(res.data.landingPageMobileCarDetailing) : undefined;
          ctx.patchState({ landingPageContent: content });
        } else if (res.data.landingPageCarBreakContent) {
          const content = res.data.landingPageCarBreakContent ? JSON.parse(res.data.landingPageCarBreakContent) : undefined;
          ctx.patchState({ landingPageContent: content });
        } else {
          ctx.patchState({ landingPageContent: undefined });
        }
      }),
      finalize(() => this.store.dispatch(new SetLoading(false, this)))
    );
  }

  @Action(HomeGetContent)
  private getContent(ctx: StateContext<AppFeatureModel>, action: HomeGetContent) {
    this.store.dispatch(new SetLoading(true, this));
    return this.appFeaturesService.getAppInfos(FieldsType.HOME_SCREEN).pipe(
      tap((res) => {
        const content = sanitizeHtml(res.data.homeScreen);
        ctx.patchState({ homeScreenInfo: content });
      }),
      finalize(() => this.store.dispatch(new SetLoading(false, this)))
    );
  }

  @Action(AppGetCheckoutPaymentInfo)
  private getCheckoutPaymentInfo(ctx: StateContext<AppFeatureModel>, action: AppGetCheckoutPaymentInfo) {
    this.store.dispatch(new SetLoading(true, this));
    return this.appFeaturesService.getAppInfos(FieldsType.CHECKOUT_PAYMENT_INFO).pipe(
      tap((res) => {
        const content = sanitizeHtml(res.data.checkoutPaymentNote);
        ctx.patchState({ checkoutPaymentInfo: content });
      }),
      finalize(() => this.store.dispatch(new SetLoading(false, this)))
    );
  }

  @Action(AppGetAccountDeleteText)
  private getAccountDeleteText(ctx: StateContext<AppFeatureModel>, action: AppGetAccountDeleteText) {
    ctx.dispatch(new SetLoading(true, this));
    return this.appFeaturesService.getAppInfos(FieldsType.ACCOUNT_DELETE_TEXTS).pipe(
      tap((res) => {
        const content = sanitizeHtml(res.data.accountDeletionRequest);
        ctx.patchState({ accountDeleteText: content });
      }),
      finalize(() => this.store.dispatch(new SetLoading(false, this)))
    );
  }

  @Action(AppGetAppVersion)
  private getAppVersion(ctx: StateContext<AppFeatureModel>) {
    ctx.dispatch(new SetLoading(true, this, false, AppGetAppVersion.name));
    return this.appFeaturesService.getAppInfos(FieldsType.APP_VERSION_DETAILS).pipe(
      switchMap((res) => {
        const state = ctx.getState();
        const appVersionDetails: AppVersionDetails | undefined = res.data.appVersionDetails
          ? JSON.parse(res.data.appVersionDetails)
          : undefined;
        if (appVersionDetails && appVersionDetails.forceUpdate && state.appVersion !== appVersionDetails.version && this.isNative) {
          ctx.setState(
            patch({
              appUpdateBanner: patch({ show: true }),
            })
          );
          return from(
            this.modalController.create({
              component: AppUpdateModalComponent,
            })
          ).pipe(
            switchMap((modal) => {
              return from(modal.present()).pipe(map(() => modal));
            })
          );
        } else if (appVersionDetails && appVersionDetails.softUpdate && state.appVersion !== appVersionDetails.version && this.isNative) {
          const promise = new Promise<void>(async (resolve) => {
            await SplashScreen.show({
              autoHide: false,
            });
            try {
              const buildInfo = await CapacitorUpdater.notifyAppReady();
              console.log('buildInfo', buildInfo);
              const data = await CapacitorUpdater.download({
                url: appVersionDetails.zipFile,
                version: appVersionDetails.version,
              });
              await CapacitorUpdater.set({ id: data.id });
            } catch (err) {
              console.error('error', err);
            }
            await SplashScreen.hide();
            resolve();
          });
          return from(promise);
        } else {
          ctx.setState(
            patch({
              appUpdateBanner: patch({ show: false }),
            })
          );
        }
        return of(undefined);
      }),
      finalize(() => this.store.dispatch(new SetLoading(false, this, false, AppGetAppVersion.name)))
    );
  }

  @Action(AppGetAppConfigs)
  private getAppConfigs(ctx: StateContext<AppFeatureModel>) {
    ctx.dispatch(new SetLoading(true, this, false, AppGetAppConfigs.name));
    return this.appFeaturesService
      .getAppInfos(
        FieldsType.MOBILE_STORE_BANNER_PLAY_STORE,
        FieldsType.MOBILE_STORE_BANNER_APP_STORE,
        FieldsType.APP_UPDATE_BANNER_APP_STORE,
        FieldsType.APP_UPDATE_BANNER_PLAY_STORE,
        FieldsType.APP_STORE_URL,
        FieldsType.PLAY_STORE_URL,
        FieldsType.FEATURE_FLAG_NATIVE_APPLE_PAY,
        FieldsType.FEATURE_FLAG_NATIVE_GOOGLE_PAY,
        FieldsType.FEATURE_FLAG_NOTIFICATIONS,
        FieldsType.FEATURE_FLAG_GOOGLE_ANALYTICS,
        FieldsType.FEATURE_FLAG_ALLOW_APP_CASH_PAYMENT,
        FieldsType.FEATURE_FLAG_ALLOW_APP_CASH_PAYMENT,
        FieldsType.FEATURE_FLAG_SERVICE_ADDON,
        FieldsType.FEATURE_FLAG_PAY_WITH_CARD
      )
      .pipe(
        tap((res) => {
          ctx.setState(
            patch({
              appUpdateBanner: patch({ text: this.isIos ? res.data.appUpdateBannerAppStore : res.data.appUpdateBannerPlayStore }),
              appStoreBanner: patch({ text: this.isIos ? res.data.mobileStoreBannerAppStore : res.data.mobileStoreBannerPlayStore }),
              appStoreUrl: res.data.appStoreUrl,
              playStoreUrl: res.data.playStoreUrl,
              featureFlags: patch({
                nativeApplePay: res.data.featureFlagNativeApplePay === 'true',
                nativeGooglePay: res.data.featureFlagNativeGooglePay === 'true',
                notifications: res.data.featureFlagNotifications === 'true',
                googleAnalytics: res.data.featureFlagGoogleAnalytics === 'true',
                allowCashAppPayment: res.data.allowAppCashPayment as AppCashPaymentOptions,
                serviceAddon: res.data.featureFlagServiceAddon === 'true',
                payWithCardSwitch: res.data.featureFlagPayWithCardSwitch === 'true',
              }),
            })
          );
        }),
        finalize(() => this.store.dispatch(new SetLoading(false, this, false, AppGetAppConfigs.name)))
      );
  }

  @Action(AppUpdateAppStoreBanner)
  private updateAppStoreBanner(ctx: StateContext<AppFeatureModel>, action: AppUpdateAppStoreBanner) {
    ctx.setState(
      patch({
        appStoreBanner: patch(action.banner),
      })
    );
  }

  @Action(AppNavigateToStore)
  navigateToStore(ctx: StateContext<AppFeatureModel>) {
    const state = ctx.getState();
    if (!state.appStoreUrl || !state.playStoreUrl) {
      console.error('appStoreUrl or  playStoreUrl is invalid');
      return;
    }
    if (this.isNativeIos) {
      window.open(state.appStoreUrl, '_blank');
      return;
    }
    if (this.isNativeAndroid) {
      window.open(state.playStoreUrl, '_blank');
      return;
    }
  }

  @Action(AppGetSupportedCountryCodes)
  getSupportedCountryCodes(ctx: StateContext<AppFeatureModel>) {
    const state = ctx.getState();
    if (state.countryCodes.length !== 0) {
      return;
    }
    ctx.dispatch(new SetLoading(true, this, false, AppGetSupportedCountryCodes.name));
    return this.appFeaturesService.getSupportedCountryCodes().pipe(
      tap((res) => {
        ctx.patchState({ countryCodes: res.countryCodes });
      }),
      finalize(() => ctx.dispatch(new SetLoading(false, this, false, AppGetSupportedCountryCodes.name)))
    );
  }

  @Action(AppActivateAppleSdk)
  activateAppleSdk(ctx: StateContext<AppFeatureModel>) {
    this.appFeaturesService.loadAppleSdk();
  }

  @Action(AppActivateGoogleMap)
  activateGoogleMap() {
    this.appFeaturesService.loadGoogleMaps();
  }
}
