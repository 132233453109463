import { Injectable, NgZone } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { AppFeaturesState } from './app-feature.state';
import { AuthState } from './auth.state';
import { PhoneStatus, ResetState } from '../shared';
import { PhoneVerificationComponent, VerificationView } from '../inside/shared/dialog/phone-verification/phone-verification.component';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, tap } from 'rxjs';

interface OrdersRouterParams {
  queryParams?: { orderServiceId?: number };
}

export class InsideNavigateToLocation {
  static readonly type = '@insideState.navigateToLocation';
}

export class InsideNavigateToLocationDetails {
  static readonly type = '@insideState.navigateToLocationDetails';
  constructor(public locationId: string) {}
}

export class InsideNavigateToOrders {
  static readonly type = '@insideState.navigateToOrders';
  constructor(public routerParams: OrdersRouterParams | undefined) {}
}

export class InsideNavigateToOrderPayWithCard {
  static readonly type = '@insideState.navigateToOrderPayWithCard';
  constructor(public orderServiceId: string) {}
}

export class InsideNavigateToMechanics {
  static readonly type = '@insideState.navigateToMechanics';
}

export class InsideNavigateToMechanicsJobs {
  static readonly type = '@insideState.navigateToMechanicsJobs';
}

export class InsideNavigateToProfile {
  static readonly type = '@insideState.navigateToProfile';
}

export class InsideNavigateToProfileAccountDeletion {
  static readonly type = '@insideState.navigateToProfileAccountDeletion';
}

export class InsideNavigateToManagePayments {
  static readonly type = '@insideState.navigateToManagePayments';
}

export class InsideNavigateToTermsAndConditions {
  static readonly type = '@insideState.navigateToTermsAndConditions';
}

export class InsideNavigateToPrivacyPolicy {
  static readonly type = '@insideState.navigateToPrivacyPolicy';
}

export class InsideNavigateToHome {
  static readonly type = '@insideState.navigateToHome';
}

export class InsideNavigateToUrl {
  static readonly type = '@insideState.navigateToUrl';
  constructor(public url: string) {}
}

export class InsideNavigateToSelectService {
  static readonly type = '@insideState.navigateToSelectService';
}

export class InsideNavigateToCheckout {
  static readonly type = '@insideState.navigateToCheckout';
}

export class InsideNavigateToVehicle {
  static readonly type = '@insideState.navigateToVehicle';
}

export class InsideNavigateToVehicleDetails {
  static readonly type = '@insideState.navigateToVehicleDetails';
  constructor(public vehicleId: string) {}
}

export class InsideNavigateToWebScheduleService {
  static readonly type = '@insideState.navigateToWebScheduleService';
}

export class InsideNavigateToSelectServiceAddon {
  static readonly type = '@insideState.navigateToSelectServiceAddon';
}

export class InsideNavigateToNotifications {
  static readonly type = '@insideState.navigateToNotifications';
}

export class InsideNavigateToUserOrderDetails {
  static readonly type = '@insideState.navigateToUserOrderDetails';
  constructor(public orderServicesId: string) {}
}

export class InsideNavigateToUserOrderDetailsNew {
  static readonly type = '@insideState.navigateToUserOrderDetailsNew';
  constructor(public orderServicesId: string) {}
}

export class InsideNavigateToMechanicOrderDetails {
  static readonly type = '@insideState.navigateToMechanicOrderDetails';
  constructor(public orderServicesId: string) {}
}

export class InsidePatchState {
  static readonly type = '@insideState.patchState';
  constructor(public state: Partial<InsideStateModel>) {}
}

interface InsideStateModel {
  mechanicsPortalActive: boolean;
  activeTab: undefined | AppTabs;
  disableTabChange: boolean;
}

export enum AppInsideUrls {
  HOME = '/inside/home',
  LOCATION_DETAILS = '/inside/select-locations/:locationId/details',
  MANAGE_PAYMENTS = 'inside/accounts/manage-payments',
  MECHANICS_HOME = '/inside/mechanics/home',
  MECHANICS_MY_JOBS = 'inside/mechanics/my-jobs',
  NOTIFICATIONS = '/inside/accounts/notifications',
  ORDERS = '/inside/orders',
  ORDERS_USER_ORDER_DETAILS = '/inside/orders/:ordersServicesId/user/details',
  ORDERS_USER_ORDER_DETAILS_NEW = '/inside/orders/:ordersServicesId/user/details/new',
  ORDERS_MECHANIC_ORDER_DETAILS = '/inside/mechanics/orders/:ordersServicesId/details',
  ORDERS_PAY_WITH_CARD = '/inside/orders/:ordersServicesId/pay-with-card',
  PRIVACY_POLICY = '/inside/accounts/company-policies/privacy-policy',
  PROFILE = '/inside/accounts/profile',
  PROFILE_ACCOUNT_DELETION = '/inside/accounts/profile/delete-account',
  SERVICES = '/inside/services',
  SELECT_LOCATIONS = '/inside/select-locations',
  SELECT_SERVICES = '/inside/select-services',
  SELECT_SERVICES_ADDONS = '/inside/select-services-addons',
  SELECT_SERVICE_CHECKOUT = '/inside/select-services-checkout',
  SELECT_VEHICLE = '/inside/select-vehicles',
  TERMS_AND_CONDITIONS = '/inside/accounts/company-policies/terms-and-conditions',
  VEHICLE_DETAILS = '/inside/select-vehicles/:vehicleId/details',
}

export enum AppTabs {
  HOME = 'HOME',
  SCHEDULE_SERVICE = 'SCHEDULE_SERVICE',
  MY_ORDERS = 'MY_ORDERS',
  ACCOUNT = 'ACCOUNT',
  MECHANIC_MY_JOBS = 'MECHANIC_MY_JOBS',
  MECHANIC_HOME = 'MECHANIC_HOME',
}

@State<InsideStateModel>({
  name: 'InsideState',
  defaults: {
    mechanicsPortalActive: false,
    activeTab: undefined,
    disableTabChange: false,
  },
})
@Injectable()
export class InsideState implements NgxsOnInit {
  constructor(
    private store: Store,
    private navController: NavController,
    private modalController: ModalController,
    private router: Router,
    private ngZone: NgZone
  ) {}

  ngxsOnInit(ctx?: StateContext<InsideStateModel> | undefined) {
    const currentUrl = window.location.href;
    if (currentUrl.includes('/mechanics/') && ctx) {
      ctx.patchState({
        mechanicsPortalActive: true,
      });
    }

    this.router.events
      .pipe(
        filter((event) => {
          if (!(event instanceof NavigationEnd)) {
            return false;
          }
          const tabChangeDisabled = ctx?.getState().disableTabChange;
          if (tabChangeDisabled) {
            return false;
          }
          return true;
        }),
        tap((event: NavigationEnd) => {
          let activeTab: AppTabs | undefined = undefined;
          if (event.urlAfterRedirects.startsWith('/inside/select')) {
            activeTab = AppTabs.SCHEDULE_SERVICE;
          } else if (event.urlAfterRedirects.startsWith('/inside/orders')) {
            activeTab = AppTabs.MY_ORDERS;
          } else if (event.urlAfterRedirects.startsWith('/inside/home')) {
            activeTab = AppTabs.HOME;
          } else if (event.urlAfterRedirects.startsWith('/inside/accounts')) {
            activeTab = AppTabs.ACCOUNT;
          } else if (event.urlAfterRedirects.startsWith('/inside/mechanics/my-jobs')) {
            activeTab = AppTabs.MECHANIC_MY_JOBS;
          } else if (event.urlAfterRedirects.startsWith('/inside/mechanics/home')) {
            activeTab = AppTabs.MECHANIC_HOME;
          }
          ctx?.patchState({ activeTab });
        })
      )
      .subscribe();
  }

  @Selector()
  static mechanicsPortalActive(state: InsideStateModel) {
    return state.mechanicsPortalActive;
  }

  @Selector()
  static appActiveTab(state: InsideStateModel) {
    return state.activeTab;
  }

  private async checkPhoneVerification(url: string) {
    const appFeaturesState = this.store.selectSnapshot(AppFeaturesState.state);
    const modal = await this.modalController.create({
      component: PhoneVerificationComponent,
      componentProps: {
        selectedView: VerificationView.VERIFY_MOBILE,
        moveToUrl: url,
      },
      cssClass: appFeaturesState?.isApp ? '' : 'phone-verification-modal',
    });
    await modal.present();
  }

  @Action(InsidePatchState)
  patchState(ctx: StateContext<InsideStateModel>, action: InsidePatchState) {
    ctx.patchState(action.state);
  }

  @Action(InsideNavigateToLocation)
  async navigateToLocations(ctx: StateContext<InsideStateModel>, action: InsideNavigateToLocation) {
    const appFeaturesState = this.store.selectSnapshot(AppFeaturesState.state);
    const authState = this.store.selectSnapshot(AuthState.state);

    let url = AppInsideUrls.SERVICES;
    if (appFeaturesState?.isApp) {
      url = AppInsideUrls.SELECT_LOCATIONS;
    }
    if (authState?.phoneStatus === PhoneStatus.NOT_VERIFIED) {
      await this.checkPhoneVerification(url);
      return;
    }
    await this.navController.navigateRoot(url);
    ctx.patchState({ mechanicsPortalActive: false });
  }

  @Action(InsideNavigateToLocationDetails)
  async navigateToLocationDetails(ctx: StateContext<InsideStateModel>, action: InsideNavigateToLocationDetails) {
    await this.navController.navigateRoot(AppInsideUrls.LOCATION_DETAILS.replace(':locationId', action.locationId));
    ctx.patchState({ mechanicsPortalActive: false });
  }

  @Action(InsideNavigateToMechanics)
  async navigateToMechanics(ctx: StateContext<InsideStateModel>, action: InsideNavigateToMechanics) {
    const authState = this.store.selectSnapshot(AuthState.state);
    const url = AppInsideUrls.MECHANICS_HOME;
    if (authState?.phoneStatus === PhoneStatus.NOT_VERIFIED) {
      await this.checkPhoneVerification(url);
      return;
    }
    await this.navController.navigateRoot(url);
    ctx.patchState({ mechanicsPortalActive: true });
  }

  @Action(InsideNavigateToMechanicsJobs)
  async navigateToMechanicsJobs(ctx: StateContext<InsideStateModel>, action: InsideNavigateToMechanicsJobs) {
    const authState = this.store.selectSnapshot(AuthState.state);
    const url = AppInsideUrls.MECHANICS_MY_JOBS;
    if (authState?.phoneStatus === PhoneStatus.NOT_VERIFIED) {
      await this.checkPhoneVerification(url);
      return;
    }
    await this.navController.navigateRoot(url);
    ctx.patchState({ mechanicsPortalActive: true });
  }

  @Action(InsideNavigateToHome)
  async navigateToHome(ctx: StateContext<InsideStateModel>, action: InsideNavigateToHome) {
    await this.navController.navigateRoot(AppInsideUrls.HOME);
    ctx.patchState({ mechanicsPortalActive: false });
  }

  @Action(InsideNavigateToOrders)
  async navigateToOrders(ctx: StateContext<InsideStateModel>, action: InsideNavigateToOrders) {
    let queryParams: { [key: string]: string } = {};
    if (action.routerParams?.queryParams?.orderServiceId) {
      queryParams = { orders_services_id: action.routerParams?.queryParams.orderServiceId.toString() };
    }
    await this.navController.navigateRoot(AppInsideUrls.ORDERS, { queryParams });
    ctx.patchState({ mechanicsPortalActive: false });
  }

  @Action(InsideNavigateToProfile)
  async navigateToProfile(ctx: StateContext<InsideStateModel>, action: InsideNavigateToProfile) {
    await this.navController.navigateRoot(AppInsideUrls.PROFILE);
    ctx.patchState({ mechanicsPortalActive: false });
  }

  @Action(InsideNavigateToProfileAccountDeletion)
  async navigateToProfileAccountDeletion(ctx: StateContext<InsideStateModel>, action: InsideNavigateToProfile) {
    await this.navController.navigateRoot(AppInsideUrls.PROFILE_ACCOUNT_DELETION);
    ctx.patchState({ mechanicsPortalActive: false });
  }

  @Action(InsideNavigateToManagePayments)
  async navigateToManagePayments(ctx: StateContext<InsideStateModel>, action: InsideNavigateToManagePayments) {
    await this.navController.navigateRoot(AppInsideUrls.MANAGE_PAYMENTS);
    ctx.patchState({ mechanicsPortalActive: false });
  }

  @Action(InsideNavigateToTermsAndConditions)
  async navigateToTermsAndConditions(ctx: StateContext<InsideStateModel>, action: InsideNavigateToTermsAndConditions) {
    await this.navController.navigateRoot(AppInsideUrls.TERMS_AND_CONDITIONS);
    ctx.patchState({ mechanicsPortalActive: false });
  }

  @Action(InsideNavigateToPrivacyPolicy)
  async navigateToPrivacyPolicy(ctx: StateContext<InsideStateModel>, action: InsideNavigateToPrivacyPolicy) {
    await this.navController.navigateRoot(AppInsideUrls.PRIVACY_POLICY);
    ctx.patchState({ mechanicsPortalActive: false });
  }

  @Action(InsideNavigateToUrl)
  async navigateToUrl(ctx: StateContext<InsideStateModel>, action: InsideNavigateToUrl) {
    const isMechanicsUrl = action.url.includes('mechanics');
    ctx.patchState({ mechanicsPortalActive: isMechanicsUrl });
    await this.navController.navigateRoot(action.url);
  }

  @Action(InsideNavigateToSelectService)
  async navigateToSelectService(ctx: StateContext<InsideStateModel>, action: InsideNavigateToSelectService) {
    ctx.patchState({ mechanicsPortalActive: false });
    await this.navController.navigateForward(AppInsideUrls.SELECT_SERVICES);
  }

  @Action(InsideNavigateToCheckout)
  async navigateToCheckout(ctx: StateContext<InsideStateModel>, action: InsideNavigateToCheckout) {
    ctx.patchState({ mechanicsPortalActive: false });
    await this.navController.navigateForward(AppInsideUrls.SELECT_SERVICE_CHECKOUT);
  }

  @Action(InsideNavigateToVehicle)
  async navigateToVehicles(ctx: StateContext<InsideStateModel>, action: InsideNavigateToVehicle) {
    ctx.patchState({ mechanicsPortalActive: false });
    await this.navController.navigateRoot(AppInsideUrls.SELECT_VEHICLE);
  }

  @Action(InsideNavigateToVehicleDetails)
  async navigateToVehiclesDetails(ctx: StateContext<InsideStateModel>, action: InsideNavigateToVehicleDetails) {
    await this.navController.navigateRoot(AppInsideUrls.VEHICLE_DETAILS.replace(':vehicleId', action.vehicleId));
    ctx.patchState({ mechanicsPortalActive: false });
  }

  @Action(InsideNavigateToWebScheduleService)
  async navigateToWebScheduleService(ctx: StateContext<InsideStateModel>, action: InsideNavigateToWebScheduleService) {
    await this.navController.navigateRoot(AppInsideUrls.SERVICES);
    ctx.patchState({ mechanicsPortalActive: false });
  }

  @Action(InsideNavigateToNotifications)
  async navigateToNotifications(ctx: StateContext<InsideStateModel>) {
    await this.navController.navigateRoot(AppInsideUrls.NOTIFICATIONS);
    ctx.patchState({ mechanicsPortalActive: false });
  }

  @Action(InsideNavigateToSelectServiceAddon)
  navigateToSelectServiceAddon(ctx: StateContext<InsideStateModel>) {
    this.ngZone.run(async () => {
      await this.navController.navigateForward(AppInsideUrls.SELECT_SERVICES_ADDONS);
      ctx.patchState({ mechanicsPortalActive: false });
    });
  }

  @Action(InsideNavigateToOrderPayWithCard)
  navigateToOrderPayWithCard(ctx: StateContext<InsideStateModel>, action: InsideNavigateToOrderPayWithCard) {
    this.ngZone.run(async () => {
      await this.navController.navigateRoot(AppInsideUrls.ORDERS_PAY_WITH_CARD.replace(':ordersServicesId', action.orderServiceId));
      ctx.patchState({ mechanicsPortalActive: false });
    });
  }

  @Action(InsideNavigateToUserOrderDetails)
  navigateToUserOrderDetails(ctx: StateContext<InsideStateModel>, action: InsideNavigateToUserOrderDetails) {
    this.ngZone.run(async () => {
      await this.navController.navigateRoot(AppInsideUrls.ORDERS_USER_ORDER_DETAILS.replace(':ordersServicesId', action.orderServicesId));
      ctx.patchState({ mechanicsPortalActive: false });
    });
  }

  @Action(InsideNavigateToUserOrderDetailsNew)
  navigateToUserOrderDetailsNew(ctx: StateContext<InsideStateModel>, action: InsideNavigateToUserOrderDetailsNew) {
    this.ngZone.run(async () => {
      await this.navController.navigateRoot(
        AppInsideUrls.ORDERS_USER_ORDER_DETAILS_NEW.replace(':ordersServicesId', action.orderServicesId)
      );
      ctx.patchState({ mechanicsPortalActive: false });
    });
  }

  @Action(InsideNavigateToMechanicOrderDetails)
  navigateToMechanicOrderDetails(ctx: StateContext<InsideStateModel>, action: InsideNavigateToMechanicOrderDetails) {
    this.ngZone.run(async () => {
      await this.navController.navigateRoot(
        AppInsideUrls.ORDERS_MECHANIC_ORDER_DETAILS.replace(':ordersServicesId', action.orderServicesId)
      );
      ctx.patchState({ mechanicsPortalActive: true });
    });
  }

  @Action(ResetState)
  resetStateByParent(ctx: StateContext<InsideStateModel>, action: ResetState) {
    ctx.setState({
      mechanicsPortalActive: false,
      activeTab: undefined,
      disableTabChange: false,
    });
  }
}
