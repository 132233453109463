import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { AppFeaturesState } from '../../portal/app-feature.state';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent {
  @Input() loading: boolean;

  constructor(public appFeaturesState: AppFeaturesState) {}
}
