import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { AppFeaturesState, AppUpdateAppStoreBanner } from '../../../portal/app-feature.state';
import { Store } from '@ngxs/store';
import { ScheduleServicesState } from '../../schedule-services/schedule-services.state';
import { map } from 'rxjs';
import {
  InsideNavigateToCheckout,
  InsideNavigateToLocation,
  InsideNavigateToSelectService,
  InsideNavigateToVehicle,
} from '../../../portal/inside.state';

@Component({
  selector: 'app-toolbar',
  standalone: true,
  imports: [CommonModule, IonicModule],
  templateUrl: './app-toolbar.component.html',
  styleUrls: ['./app-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppToolbarComponent implements OnInit {
  @Input() loading: boolean;
  @Input() title: string;

  orderRequestInProgress$ = this.store.select(ScheduleServicesState.state).pipe(
    map((state) => {
      if (state?.selectedLocation || state?.selectedService || state?.selectedVehicle) {
        return true;
      }
      return false;
    })
  );

  appStoreBanner$ = this.store.select(AppFeaturesState.appStoreBanner);

  constructor(
    public appFeaturesState: AppFeaturesState,
    private store: Store
  ) {}

  ngOnInit(): void {}

  navigateToSelectLocation() {
    this.store.dispatch(new InsideNavigateToLocation());
  }

  continueService() {
    const state = this.store.selectSnapshot(ScheduleServicesState.state);
    let action: any;
    if (state.selectedService) {
      action = new InsideNavigateToCheckout();
    } else if (state.selectedVehicle) {
      action = new InsideNavigateToSelectService();
    } else if (state.selectedLocation) {
      action = new InsideNavigateToVehicle();
    } else {
      action = new InsideNavigateToLocation();
    }
    this.store.dispatch(action);
  }

  closeBanner() {
    this.store.dispatch(new AppUpdateAppStoreBanner({ show: false }));
  }
}
