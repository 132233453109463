import { EventEmitter, Injectable } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { ToastService } from '../../../core/toast.service';
import { AppFeaturesState } from '../../../portal/app-feature.state';
import { OrdersType } from '../../../portal/orders.service';
import { OrdersCancelOrder } from '../../../portal/orders.state';
import { Order, OrderServiceStatus } from '../../../shared';
import { OrderDetailUpdate } from './order-detail.state';
import { OrderDetailsComponent } from './order-details.component';

@Injectable({
  providedIn: 'root',
})
export class OrderDetailsService {
  constructor(
    private toastService: ToastService,
    private alertController: AlertController,
    private store: Store,
    private modalController: ModalController
  ) {}

  private async cancelOrder(orderServiceId: string) {
    const isApp = this.store.selectSnapshot(AppFeaturesState.state).isApp;
    const alert = await this.alertController.create({
      cssClass: !isApp ? 'delete-alert' : '',
      subHeader: `${orderServiceId}`,
      message: 'Are you sure you want to cancel the order?',
      inputs: [
        {
          name: 'reason',
          type: 'textarea',
          placeholder: 'Reason for cancelling the order',
        },
      ],
      buttons: [
        {
          text: 'No',
          role: 'no',
        },
        {
          role: 'yes',
          handler: (res) => {
            const { reason } = res ?? {};
            if (!reason) {
              this.toastService.showToast(isApp, {
                message: 'Please add a reason for cancelling order',
                position: 'top',
              });
              return false;
            }
            console.log('res', reason);
            return true;
          },
          text: 'Yes',
        },
      ],
    });
    await alert.present();
    const details = await alert.onDidDismiss();
    return details;
  }

  async showDetails(event: Event | undefined, orderServicesId: string | undefined) {
    event?.stopPropagation();
    if (!orderServicesId) {
      return;
    }
    let modal: HTMLIonModalElement;
    const onCancelOrder = new EventEmitter<void>();
    const oncancelOrderSubscription = onCancelOrder.subscribe(async () => {
      const details = await this.cancelOrder(orderServicesId);
      if (details.role !== 'yes') {
        return;
      }
      this.updateOrderForUser(orderServicesId, {
        orderServiceStatus: OrderServiceStatus.CANCELED,
        cancelReason: details?.data?.values?.reason,
      });
      this.store.dispatch(
        new OrderDetailUpdate({ orderServiceStatus: OrderServiceStatus.CANCELED, cancelReason: details?.data?.values?.reason })
      );
    });
    modal = await this.modalController.create({
      component: OrderDetailsComponent,
      componentProps: {
        orderServicesId: orderServicesId,
        orderType: OrdersType.USER,
        onCancelOrder,
      },
    });
    await modal.present();
    setTimeout(async () => {
      await modal.onDidDismiss();
      oncancelOrderSubscription.unsubscribe();
    }, 10);

    return modal;
  }

  updateOrderForUser(orderServicesId: string, order: Partial<Order>) {
    if (!order.cancelReason) {
      throw Error('Missing order cancel reason');
    }
    if (order.orderServiceStatus === OrderServiceStatus.CANCELED) {
      this.store.dispatch(new OrdersCancelOrder(orderServicesId, order.cancelReason));
      return;
    }
  }
}
