import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {
  Vehicle,
  UserLocation,
  BaseResponse,
  StripePayloadResponse,
  DeviceType,
  StripePayloadEnum,
  NotificationType,
  OrderPaymentOption,
} from '../shared';
import camelcaseKeys from 'camelcase-keys';

interface ResponseGetVehicles {
  ar_autos: Vehicle[];
  message: string | undefined;
  records_total: number | undefined;
}

interface ResponseUpsertVehicle {
  request: UpsertVehicle;
}

interface UpdateLocationResponse {
  data: {
    address: string;
    city: string;
    country: string;
    locations_name: string;
    map_url: string;
    primary?: number;
    state: string;
    users_locations_id: string;
    zip: string;
    point_long?: number;
    point_lat?: number;
    customer_name?: string;
    customer_phone?: string;
    isDefault?: number;
  };
}

interface ResponseGetLocations {
  data: {
    address: string;
    city: string;
    country: string;
    locations_name: string;
    map_url: string;
    primary: number;
    state: string;
    users_locations_id: string;
    zip: string;
    point_long?: number;
    point_lat?: number;
    date_not_available?: string;
    date_not_available_message?: string;
    customer_name?: string;
    customer_phone?: string;
    isDefault?: number;
  }[];
  message: string | undefined;
  records_total: number | undefined;
}
interface ResponsePostLocation {
  data: UserLocation;
}

export interface UpsertVehicle {
  yearVehicle: string;
  makeVehicle: string;
  modelVehicle: string;
  subModelVehicle: string;
  modelBody?: string;
  modelEngine?: string;
  driveType?: string;
  vinNumber?: string;
  usersVehiclesId: string;
}

export interface ScheduleServicePayload {
  usersVehiclesId: string;
  servicesId: number;
  servicePartCode: string | undefined;
  usersLocationsId: string;
  usersCreditCardsId?: number;
  isDraft?: boolean;
  preferredTime: string;
  couponCode: string | undefined;
  orderPaymentOption: OrderPaymentOption | undefined;
  selectedServiceAddonIds: string[] | undefined;
}

interface StripePayload {
  stripe: StripePayloadResponse;
  message: string;
  title: string;
  type: StripePayloadEnum.NEXT_ACTION_NEEDED;
}

interface StripePayloadDraftOrder {
  message: string;
  type: StripePayloadEnum.DRAFT_ORDER;
  amount: number;
  client_secret: string;
}

interface ScheduleServiceResponse extends BaseResponse {
  order: {
    orders_id: number;
    orders_services_id: number;
  };
  stripe_payload1?: StripePayload | StripePayloadDraftOrder;
  stripe_payload2?: StripePayload | StripePayloadDraftOrder;
}

interface ScheduleServiceFromCartRes extends BaseResponse {
  data: {
    payment1: {
      is_valid: boolean;
    };
    payment2: {
      is_valid: boolean;
      stripe: StripePayloadResponse;
    };
  };
}

interface OrderServiceValidateRes extends BaseResponse {
  data: {
    payment1: {
      is_valid: boolean;
    };
    payment2: {
      is_valid: boolean;
      stripe: StripePayloadResponse;
    };
  };
}

interface GetUserNotificationRes extends BaseResponse {
  unread_notifications: string;
  user_notifications: {
    users_notifications_id: string;
    date_created: string;
    notification_type: NotificationType;
    title: string;
    body: string;
    date_read: string;
    action_link: string;
    orders_id: string;
    orders_services_id: string;
  }[];
}

interface UpdateUserNotificationRes extends BaseResponse {
  user_notification: {
    users_notifications_id: string;
    date_created: string;
    notification_type: NotificationType;
    title: string;
    body: string;
    date_read: string;
    action_link: string;
    orders_id: string;
    orders_services_id: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  getVehicles(userId: number, userVehiclesId: string | undefined = undefined) {
    let url = `${environment.api_url}/users/${userId}/users_vehicles`;
    if (userVehiclesId) {
      url += `/${userVehiclesId}`;
    }
    return this.http.get<ResponseGetVehicles>(url).pipe(map((res) => camelcaseKeys(res, { deep: true })));
  }

  createVehicles(userId: number, vehicle: Partial<Vehicle>): Observable<UpsertVehicle> {
    const form = this.buildVehicleFormData(vehicle);
    return this.http
      .post<UpsertVehicle>(`${environment.api_url}/users/${userId}/users_vehicles/`, form)
      .pipe(map((res) => camelcaseKeys(res, { deep: true })));
  }

  private buildVehicleFormData(vehicle: Partial<UpsertVehicle>) {
    const form = new FormData();
    form.append('year_vehicle', vehicle.yearVehicle?.toString() ?? '');
    form.append('make_vehicle', vehicle.makeVehicle?.toString() ?? '');
    form.append('model_vehicle', vehicle.modelVehicle?.toString() ?? '');
    form.append('sub_model_vehicle', vehicle.subModelVehicle?.toString() ?? '');
    form.append('model_body', vehicle.modelBody?.toString() ?? '');
    form.append('model_engine', vehicle.modelEngine?.toString() ?? '');
    form.append('drive_type', vehicle.driveType?.toString() ?? '');
    form.append('vin_number', vehicle.vinNumber?.toString() ?? '');
    form.append('users_vehicles_id', vehicle.usersVehiclesId?.toString() ?? '');
    return form;
  }

  updateVehicles(userId: number, vehicle: UpsertVehicle): Observable<UpsertVehicle> {
    const form = this.buildVehicleFormData(vehicle);
    return this.http
      .post<ResponseUpsertVehicle>(`${environment.api_url}/users/${userId}/users_vehicles/${vehicle.usersVehiclesId}`, form)

      .pipe(
        map((res) => camelcaseKeys(res, { deep: true })),
        map((res) => res.request)
      );
  }

  deleteVehicle(userId: number, vehicleId: string): Observable<void> {
    const form = new FormData();
    form.append('users_vehicles_id', vehicleId.toString());
    form.append('_method', 'DELETE');
    return this.http.post<void>(`${environment.api_url}/users/${userId}/users_vehicles/${vehicleId}`, form);
  }

  getUserLocations(userId: number, userLocationId: string | undefined = undefined) {
    let url = `${environment.api_url}/users/${userId}/users_locations`;
    if (userLocationId) {
      url += `/${userLocationId}`;
    }
    return this.http.get<ResponseGetLocations>(url).pipe(
      map((res) => camelcaseKeys(res, { deep: true })),
      map((res) => {
        const response = {
          ...res,
          data: res.data.map((location) => ({ ...location, isDefault: location.isDefault == 1 ? true : false })),
        };
        return response;
      })
    );
  }

  createUserLocation(userId: number, userLocation: UserLocation) {
    const form = new FormData();
    form.append('address', userLocation.address);
    form.append('city', userLocation.city);
    form.append('country', userLocation.country);
    form.append('locations_name', userLocation.locationsName);
    form.append('map_url', userLocation.mapUrl);
    form.append('primary', userLocation.primary?.toString() ?? '');
    form.append('state', userLocation.state);
    form.append('users_locations_id', '0');
    form.append('zip', userLocation.zip);
    form.append('point_long', userLocation.pointLong?.toString() ?? '');
    form.append('point_lat', userLocation.pointLat?.toString() ?? '');
    form.append('customer_name', userLocation.customerName ?? '');
    form.append('customer_phone', userLocation.customerPhone ?? '');
    form.append('is_default', userLocation.isDefault ? '1' : '0');
    form.append('customer_phone_country_code', userLocation.customerPhoneCountryCode || '');

    return this.http
      .post<ResponsePostLocation>(`${environment.api_url}/users/${userId}/users_locations/0`, form)
      .pipe(map((res) => camelcaseKeys(res, { deep: true })))
      .pipe(map((res) => ({ ...res.data, usersLocationsId: res.data.usersLocationsId.toString() })));
  }

  updateUserLocation(userId: number, userLocation: UserLocation): Observable<UserLocation> {
    const form = new FormData();
    form.append('country', userLocation.country);
    form.append('address', userLocation.address);
    form.append('city', userLocation.city);
    form.append('locations_name', userLocation.locationsName);
    form.append('map_url', userLocation.mapUrl);
    form.append('primary', userLocation.primary?.toString() ?? '');
    form.append('state', userLocation.state);
    form.append('users_locations_id', userLocation.usersLocationsId.toString() ?? '');
    form.append('zip', userLocation.zip);
    form.append('point_long', userLocation.pointLong?.toString() ?? '');
    form.append('point_lat', userLocation.pointLat?.toString() ?? '');
    form.append('customer_name', userLocation.customerName ?? '');
    form.append('customer_phone', userLocation.customerPhone ?? '');
    form.append('is_default', userLocation.isDefault ? '1' : '0');
    form.append('customer_phone_country_code', userLocation.customerPhoneCountryCode || '');

    return this.http
      .post<UpdateLocationResponse>(`${environment.api_url}/users/${userId}/users_locations/${userLocation.usersLocationsId}`, form)
      .pipe(
        map((res) => camelcaseKeys(res, { deep: true })),
        map((res) => ({
          ...res.data,
          isDefault: res.data.isDefault == 1 ? true : false,
          usersLocationsId: res.data.usersLocationsId.toString(),
        }))
      );
  }

  deleteUserLocation(userId: number, userLocationId: string) {
    const form = new FormData();
    form.append('_method', 'DELETE');
    return this.http.post<void>(`${environment.api_url}/users/${userId}/users_locations/${userLocationId}`, form);
  }

  scheduleService(userId: number, servicePayload: ScheduleServicePayload) {
    const form = new FormData();
    form.append('users_vehicles_id', servicePayload.usersVehiclesId.toString() ?? '');
    form.append('services_id', servicePayload.servicesId.toString() ?? '');
    form.append('users_locations_id', servicePayload.usersLocationsId.toString() ?? '');
    form.append('preferred_time', servicePayload.preferredTime.toString() ?? '');
    if (servicePayload.servicePartCode) {
      form.append('service_part_code', servicePayload.servicePartCode);
    }
    if (servicePayload.couponCode) {
      form.append('coupon_code', servicePayload.couponCode);
    }
    if (servicePayload.usersCreditCardsId) {
      form.append('users_credit_cards_id', servicePayload.usersCreditCardsId.toString() ?? '');
    }
    if (servicePayload.isDraft) {
      form.append('create_draft_order', '1');
    }
    if (servicePayload.selectedServiceAddonIds && servicePayload.selectedServiceAddonIds.length > 0) {
      for (const addonId of servicePayload.selectedServiceAddonIds) {
        form.append('services_addons_id[]', addonId);
      }
    }
    form.append('order_payment_option', servicePayload.orderPaymentOption ?? '');
    return this.http
      .post<ScheduleServiceResponse>(`${environment.api_url}/users/${userId}/schedule-service`, form)
      .pipe(map((res) => camelcaseKeys(res, { deep: true })));
  }

  scheduleServiceFromCart(orderServiceId: number) {
    return this.http
      .post<ScheduleServiceFromCartRes>(`${environment.api_url}/orders_services/${orderServiceId}/schedule-service`, null)
      .pipe(map((res) => camelcaseKeys(res, { deep: true })));
  }

  validateOrder(ordersServicesId: number) {
    return this.http
      .post<OrderServiceValidateRes>(`${environment.api_url}/orders_services/${ordersServicesId}/validate`, null)
      .pipe(map((res) => camelcaseKeys(res, { deep: true })));
  }

  applyCoupon(userId: number) {
    return this.http
      .post<BaseResponse>(`${environment.api_url}/users/${userId}/apply-coupon`, {})
      .pipe(map((res) => camelcaseKeys(res, { deep: true })));
  }

  registerDevice(deviceToken: string, deviceType: DeviceType, deviceId: string) {
    const formData = new FormData();
    formData.append('device_notification_token', deviceToken);
    formData.append('device_type', deviceType);
    formData.append('device_id', deviceId);
    return this.http.post<void>(`${environment.api_url}/users/register-device`, formData);
  }

  getUserNotifications(userId: number) {
    return this.http
      .get<GetUserNotificationRes>(`${environment.api_url}/users/${userId}/user-notifications`)
      .pipe(map((res) => camelcaseKeys(res, { deep: true })));
  }

  updateUserNotification(userId: number, usersNotificationsId: string, notificationRead: string) {
    const formData = new FormData();
    formData.append('notification_read', notificationRead);
    return this.http
      .post<UpdateUserNotificationRes>(`${environment.api_url}/users/${userId}/user-notifications/${usersNotificationsId}`, formData)
      .pipe(map((res) => camelcaseKeys(res, { deep: true })));
  }
}
