<ion-footer class="footer">
  <ion-toolbar color="secondary" class="ion-padding-bottom">
    <ion-grid>
      <ion-row>
        <ion-col offset-lg="2" [sizeMd]="2" [sizeXs]="6" class="footer-container">
          <span class="footer-item">ALL ROTORS</span>
          <a href="#" class="footer-link"> About us</a>
          <a href="#" class="footer-link"> Support</a>
          <a href="#" class="footer-link"> Contact us</a>
        </ion-col>

        <ion-col [sizeMd]="2" [sizeXs]="6" class="footer-container">
          <span class="footer-item">WORK WITH US</span>
          <a href="#" class="footer-link"> Suppliers</a>
          <a href="#" class="footer-link"> Mechanics</a>
          <a href="#" class="footer-link"> Manufacturers</a>
        </ion-col>

        <ion-col class="footer-container ion-align-items-end">
          <span class="footer-item">FOLLOW</span>
          <div>
            <a href="https://www.facebook.com/allrotors/">
              <ion-icon name="logo-facebook"></ion-icon>
            </a>
            <a href="https://twitter.com/allrotors">
              <ion-icon name="logo-twitter"></ion-icon>
            </a>
            <a href="https://www.instagram.com/allrotors/">
              <ion-icon name="logo-instagram"></ion-icon>
            </a>
            <a href="https://www.youtube.com/channel/UCibEOFj_u3Fd3NMiCg3OWTw">
              <ion-icon name="logo-youtube"></ion-icon>
            </a>
          </div>
        </ion-col>

        <ion-col [size]="0" [sizeLg]="2"> </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
