import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './inside/auth.gaurd';
import { LandingPageType } from './shared';
import { GetPhoneCodeResolver } from './shared-standalone/get-phone-code.resolver';
import { InitializeAppResolver } from './shared-standalone/initialize-app.resolver';
import { NotFoundComponent } from './core';

const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    resolve: [InitializeAppResolver],
  },
  {
    path: 'company',
    loadChildren: () => import('./company-policies/company-policies.module').then((m) => m.TermsAndConditionsModule),
    resolve: [InitializeAppResolver],
  },
  {
    path: 'home-page',
    loadChildren: () => import('./home-page/home-page.module').then((m) => m.HomePageModule),
    resolve: [InitializeAppResolver, GetPhoneCodeResolver],
  },
  {
    path: 'mobile-car-detailing',
    data: {
      landingPageType: LandingPageType.MOBILE_CAR_DETAILING,
    },
    loadChildren: () => import('./landing-page/landing-page.module').then((m) => m.LandingPageModule),
  },
  {
    path: 'mobile/car-detailing',
    data: {
      landingPageType: LandingPageType.MOBILE_CAR_DETAILING,
    },
    title: 'AllRotors Car Detailing',
    loadChildren: () => import('./landing-page/landing-page.module').then((m) => m.LandingPageModule),
  },
  {
    path: 'mobile/brakes-replacement',
    data: {
      landingPageType: LandingPageType.CAR_BREAK_DETAILING,
    },
    title: 'AllRotors Brake Replacement',
    loadChildren: () => import('./landing-page/landing-page.module').then((m) => m.LandingPageModule),
  },
  {
    path: 'inside',
    loadChildren: () => import('./inside/inside.module').then((m) => m.InsideModule),
    canLoad: [AuthGuard],
    resolve: [InitializeAppResolver, GetPhoneCodeResolver],
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
    resolve: [InitializeAppResolver, GetPhoneCodeResolver],
  },
  {
    path: 'standalone/signup-component',
    loadChildren: () =>
      import('./shared-standalone/signup-forgot-password/signup-forgot-password.module').then((m) => m.SignupForgotPasswordModule),
    resolve: [GetPhoneCodeResolver],
  },
  {
    path: 'payments',
    loadChildren: () => import('./stripe/stripe-payment-authorization.module').then((m) => m.StripePaymentAuthorizationModule),
    resolve: [InitializeAppResolver, GetPhoneCodeResolver],
  },
  {
    path: '',
    redirectTo: 'inside',
    pathMatch: 'full',
  },
  { path: '404', component: NotFoundComponent },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
