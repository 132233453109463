import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Action, State } from '@ngxs/store';
import { SignupForgotPasswordComponent } from './signup-forgot-password.component';

export class AppPresentLoginModal {
  static readonly type = '@signupForgotPassword.presentLoginModal';
}

interface SignupForgotPasswordStateModel {}

@State<SignupForgotPasswordStateModel>({
  name: 'SignupForgotPasswordState',
  defaults: {},
})
@Injectable()
export class SignupForgotPasswordState {
  constructor(private modalController: ModalController) {}

  @Action(AppPresentLoginModal)
  async presentModal(isSignUp: boolean) {
    let cssClass: string = '';
    cssClass = isSignUp ? 'signup-modal' : 'forgot-password-modal';

    const modal = await this.modalController.create({
      component: SignupForgotPasswordComponent,
      cssClass,
      componentProps: { isSignUp },
    });
    return await modal.present();
  }
}
