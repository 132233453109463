import { Component, ChangeDetectionStrategy, Input, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppFeaturesState } from '../../../portal/app-feature.state';
import { GoogleAnalyticsService, LogEventType } from '../../../core/google-analytics.service';

@Component({
  selector: 'app-order-service-photo-view',
  templateUrl: './order-service-photo-view.component.html',
  styleUrls: ['./order-service-photo-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderServicePhotoViewComponent {
  @Input() imageUrl: string;

  constructor(
    public appFeaturesState: AppFeaturesState,
    private modalController: ModalController,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}

  async handleIonScrollStart() {
    await this.googleAnalyticsService.logEvent({ name: LogEventType.SCROLL });
  }

  async closeModal() {
    await this.modalController.dismiss();
  }
}
