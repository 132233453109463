<ion-header>
  <ion-toolbar [ngClass]="{ 'ion-margin-top': appFeaturesState.isIos }">
    <ion-buttons slot="start">
      <ion-button
        [shape]="appFeaturesState.isIos ? 'round' : ''"
        expand="block"
        mode="md"
        fill="outline"
        color="secondary"
        size="large"
        (click)="closeModal()"
      >
        <ion-icon name="arrow-back-circle-outline"></ion-icon> Back
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [scrollEvents]="true" (ionScrollStart)="handleIonScrollStart()">
  <div class="ion-margin-horizontal ion-margin-top scroll">
    <img [src]="imageUrl" />
  </div>
</ion-content>
