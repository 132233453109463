import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { AppFeaturesState } from '../../portal/app-feature.state';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  appFeaturesState$ = this.store.select(AppFeaturesState.state);
  constructor(public store: Store) {}
}
