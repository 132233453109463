import { HttpClient, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable, ErrorHandler, inject } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { environment } from '../../environments/environment';
import { ToastService } from './toast.service';
import { Store } from '@ngxs/store';
import { BaseResponse } from '../shared';
import { EMPTY, catchError } from 'rxjs';
import { AllRotorsError } from '../shared/contracts/errors';

@Injectable({
  providedIn: 'root',
})
export class FmErrorHandlerService extends ErrorHandler implements ErrorHandler {
  constructor(
    private navController: NavController,
    private toastService: ToastService,
    private platform: Platform,
    private http: HttpClient,
    private store: Store
  ) {
    super();
  }

  private isInAppMode() {
    let isApp = false;
    const isCapacitor = environment.forceApp || this.platform.is('capacitor');
    if (isCapacitor && (this.platform.is('ios') || this.platform.is('android'))) {
      isApp = true;
    }
    return isApp;
  }

  async handleError(error: any) {
    if (this.isErrorHandled(error)) {
      console.log('[ERROR handled!] ', error);
      return;
    }
    this.recordError(error);

    const messagePrefix = 'Something went wrong!';
    const message = this.parseError(error);
    const isApp = this.isInAppMode();
    this.toastService.showToast(isApp, {
      message: message ? `${message?.replace('Uncaught (in promise):', '').trim()}` : messagePrefix,
      position: 'top',
      buttons: [{ side: 'end', text: 'Ok' }],
      duration: 5000, // autoClose in 5 seconds
    });

    // TODO: log in a log service
    // Make sure to rethrow the error so Angular can pick it up
    // throw error;
    super.handleError(error);
    try {
      const errorMessage = this.isInAppMode() ? JSON.stringify(error) : error;
      // console.log('[ERROR!] ', errorMessage);
    } catch (err) {
      // if failed to stringify error log regular error
      console.log('[ERROR!] ', error);
    }
  }

  handleHttpInterceptorError(error: any) {
    // console.log('[HTTP ERROR!] ', error);

    this.handleForbidden(error);

    this.handleCustomHttpError(error);
    this.recordError(error);
    // Do not trigger UI error from interceptor
    // If we have an http call where we want to catch the error inside the service and handle it there
    // We dont want in this case that the global interceptor to show an error
    // eg: service.getId().pipe(catchError() => customLogic)
    // Unhandled errors will enter in the custom error handler and show the errors
    // We are gonna still keep the interceptor for a while for custom generic errors on API calls

    // this.handleError(error);
  }

  private isErrorHandled(error: any) {
    if (!error) {
      // do not log empty error
      return true;
    }
    return error?.status === 0 || error?.status === 403 || error?.currentTarget?.status === 0 || error?.currentTarget?.status === 403;
  }

  private async handleForbidden(error: any) {
    if (error?.status === 403 || error?.currentTarget?.status === 403) {
      const isApp = this.isInAppMode();
      this.toastService.showToast(isApp, {
        message: 'Forbidden access! Try to relogin or contact support!',
        position: 'top',
        buttons: [{ side: 'end', text: 'Ok' }],
        duration: 5000, // autoClose in 5 seconds
      });
      this.navController.navigateRoot('/home-page');
    }
  }

  /**
   * Handles http request errors that have status as 0 in body.
   * Specific to AllRotors backend
   */
  private async handleCustomHttpError(error: any) {
    if (error?.status === 0) {
      const message =
        this.parseError(error) ?? 'Could not connect to the server! Please check internet connection and reload the application!';
      const isApp = this.isInAppMode();
      this.toastService.showToast(isApp, {
        message: message ?? error,
        position: 'top',
        buttons: [{ side: 'end', text: 'Ok' }],
        duration: 5000, // autoClose in 5 seconds
      });
    }
  }

  private parseError(error: any) {
    if (typeof error === 'string') {
      return error;
    }
    if (error?.error instanceof ErrorEvent) {
      return error.error.message;
    }
    if (error instanceof HttpErrorResponse) {
      return this.getHttpMessage(error);
    }
    if (typeof error === 'object') {
      return this.parseObjectMessage(error);
    }
    return error?.message;
  }

  private getHttpMessage(error: HttpErrorResponse) {
    if (typeof error.error?.message === 'string') {
      return error.error.message;
    }
    if (error.error.message?.isJoi) {
      return error.error.message.details[0]?.message;
    }
    if (error.error.message?.details[0]?.message) {
      return error.error.message.details[0]?.message;
    }
    return error.statusText;
  }

  private parseObjectMessage(error: any) {
    if (typeof error?.message === 'string') {
      return error.message;
    }
    if (error.message?.isJoi) {
      return error.message.details[0]?.message;
    }
    return error;
  }

  private async recordError(error: Error) {
    try {
      let errorJson = error.message;
      const pageUrl = window.location.href;
      const stacktrace = error.stack;
      const { DataStoreStateModel, ...state } = this.store?.snapshot() ?? {};
      const applicationStateJson = state;
      if (error instanceof HttpErrorResponse && error.error && error.error instanceof HttpRequest) {
        let input: { [key: string]: string } = {};
        for (const [key, value] of error.error.body?.entries() ?? []) {
          input[key] = value;
          if (['password', 'password2'].includes(key)) {
            input[key] = '*********';
          }
        }
        errorJson = JSON.stringify({
          error: error.error,
          headers: error.error.headers,
          status: error.status,
          url: error.url,
          statusText: error.statusText,
          body: input,
        });
      } else if (error instanceof AllRotorsError) {
        errorJson = JSON.stringify(error);
      }

      const data = new FormData();
      data.append('error_json', errorJson?.toString());
      data.append('page_url', pageUrl);
      data.append('stacktrace', stacktrace?.toString() ?? '');
      data.append('application_state_json', applicationStateJson ? JSON.stringify(applicationStateJson) : '');
      this.http
        .post<BaseResponse>(`${environment.api_url}/log/error`, data)
        .pipe(
          catchError((err) => {
            return EMPTY;
          })
        )
        .subscribe();

      // console.log('error', JSON.stringify(error));
    } catch (err) {
      console.log('failed to record error');
      console.error(err);
    }
  }
}
