import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, Input } from '@angular/core';
import { IonRefresher } from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { LoadingState } from '../../core/loading.state';
import { AppFeaturesState, PrivacyPoliciesGet } from '../../portal/app-feature.state';
import { GoogleAnalyticsService, LogEventType } from '../../core/google-analytics.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PrivacyPolicyComponent {
  @Select(AppFeaturesState.privacyPolicies) privacyPolicies$: Observable<string | undefined>;
  @Select(LoadingState.isLoading(AppFeaturesState.name)) loading$: Observable<boolean>;

  @Input() inModal: boolean;
  @Input() inIframe: boolean;

  constructor(
    private store: Store,
    public appFeaturesState: AppFeaturesState,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}

  async handleIonScrollStart() {
    await this.googleAnalyticsService.logEvent({ name: LogEventType.SCROLL });
  }

  ionViewWillEnter() {
    this.store.dispatch(new PrivacyPoliciesGet());
  }

  loadContent(event: CustomEvent<IonRefresher>) {
    this.store.dispatch(new PrivacyPoliciesGet()).subscribe(() => {
      const eventRefresher: IonRefresher | null = <IonRefresher | null>event.target;
      eventRefresher?.complete();
    });
  }
}
