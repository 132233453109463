export const sanitizeHtml = (rawHtml: string | undefined) => {
  if (!rawHtml) {
    return undefined;
  }
  const html = rawHtml.replace(/\\"/g, `"`);
  return html;
};

/**
 * supports Canadian and US postal code
 */
export const postalCodePatternRegex = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ \-]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;

/**
 * supports the following
 * 123-456-7890
 * (123) 456-7890
 *  123 456 7890
 *  123.456.7890
 *  +91 (123) 456-7890
 */
// export const phoneNumberPatternRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
export const phoneNumberPatternRegex = /^\(\d{3}\) \d{3}-\d{4}$/;

export const INVALID_FORM_MESSAGE = 'Please fill in the valid and required information';
